/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { portfolioActions, portfolioSelectors } from 'modules/portfolio/portfolioDuck';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { deviceFormat } from 'helpers/utilityFunctions';
import { paramsFromURL, paramsToObject, paramsToString } from 'helpers/paramHelpers';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import _, { isEmpty } from 'lodash';
import WalletForm from './WalletForm/WalletForm';
import WalletsTable from './WalletsTable/WalletsTable';
import ToTop from 'components/ToTop/ToTop';
import Filter from 'components/Filter/Filter';
import Loader from 'components/Loader/Loader';
import Toggle from 'components/Toggle/Toggle';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './Portfolio.scss';

const itemsPerPage = 100;

const resizeActions = (e, setFormat, mounted) => {
  const element = e.currentTarget;
  const newHeight = element.innerHeight;
  const newWidth = element.innerWidth;
  if (mounted) setFormat(deviceFormat(newWidth, newHeight));
};

const Portfolio = props => {
  const [ format, setFormat ] = useState(deviceFormat(window.innerWidth, window.innerHeight));
  const [ filteredData, setFilteredData ] = useState([]);
  const [ modalState, setModalState ] = useState(defaultModal);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const mounted = useRef(true);
  const params = paramsToObject(paramsFromURL());

  // Selectors
  const wallets = useSelector(state => portfolioSelectors.wallets(state)) || [];
  const settings = useSelector(state => appSelectors.settings(state));

  // Actions
  const reorderPortfolio = payload => dispatch(portfolioActions.reorderPortfolio(payload));
  const getSettings = () => dispatch(appActions.getSettings());
  const setSettings = payload => dispatch(appActions.setSettings(payload));
  const getPortfolio = () => dispatch(portfolioActions.getPortfolio());
  const clearPortfolio = () => dispatch(portfolioActions.clearPortfolio());
  const logout = () => dispatch(appActions.logout());

  useEffect(() => {
    getSettings();
    getPortfolio();

    window.addEventListener('resize', e => resizeActions(e, setFormat, mounted.current));

    return () => {
      mounted.current = false;
      window.removeEventListener('resize', e => resizeActions(e, setFormat, mounted.current))
    };
  }, []);

  useEffect(() => {
    if (settings) {
      const { portfolio: portfolioParams } = settings.pages;

      if (portfolioParams !== paramsFromURL()) {
        history.push(`/portfolio${portfolioParams}`);
      };
    };
  }, [settings]);

  useEffect(() => {
    setFilteredData(null);
  }, [settings, wallets]);

  return (
    <Fragment>
      <div id="portfolioBody">
        <div className="portfolioTitle">
          <h2>Portfolio</h2>
        </div>

        <div className="add">
          {!isEmpty(wallets) && (
            <Button
              text="Add a wallet"
              textColor={colors.white}
              icon="add"
              iconColor={colors.white}
              btnStyle="transparent"
              callback={() => openModal(
                setModalState, _.uniqueId('NewForm_'), 'Add Wallet',
                <WalletForm setModalState={newState => setModalState(newState)} />
              )} />
          )}
        </div>

        <div className="portfolioFilter">
          {!isEmpty(wallets) && (
            <Filter
              data={wallets && wallets}
              placeholder="i.e. Symbol / name / address"
              reset={filteredData === null}
              match={["name", "address", "chainType"]}
              callback={newData => setFilteredData(newData)} />
          )}
        </div>

        <div className="portfolioPairToggle">
          <p className="strong">BTC</p> &nbsp;&nbsp;
          {settings && params.pair && (
            <Toggle
              defaultVal={params.pair === 'BTC'}
              callback={newVal => {
                const existingParams = paramsToObject(search);
                const newParams = { ...existingParams, pair: newVal ? 'BTC' : 'USD' };
                const payload = { ...settings };

                payload.pages.portfolio = paramsToString(newParams);
                history.push(`/portfolio${paramsToString(newParams)}`);

                setSettings(payload);
              }} />
          )}
        </div>

        {(wallets && settings && !isEmpty(params)) && (
          <WalletsTable
            privacy={params.privacy === 'true'}
            currentPair={params.pair}
            format={format}
            wallets={wallets}
            filteredData={filteredData}
            itemsPerPage={itemsPerPage}
            reorderPortfolio={reorderPortfolio} />
        )}
      </div>

      {!wallets && (
        <div>
          <Loader text="Loading portfolio data..." />
        </div>
      )}

      {showModal(modalState, setModalState)}

      <ToTop />
    </Fragment>
  );
};

export default Portfolio;