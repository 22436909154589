/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tradingActions, tradingSelectors } from 'modules/trading/tradingDuck';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual, isEmpty } from 'lodash';
import { decrypt } from 'helpers/utilityFunctions';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './TradeSettings.scss';

const defaultApiInfo = {
  passphrase: '',
  secret: '',
  key: ''
};

const ExchangeSettings = props => {
  const { asset, pair, orders } = props;
  const [ apiInfo, setApiInfo ] = useState(defaultApiInfo);
  const dispatch = useDispatch();

  // Selectors
  const integrationData = useSelector(state => tradingSelectors.integrationData(state));

  // Actions
  const getBalances = () => dispatch(tradingActions.getBalances());
  const getOpenOrders = product_id => dispatch(tradingActions.getOpenOrders(product_id));
  const getFilledOrders = product_id => dispatch(tradingActions.getFilledOrders(product_id));
  const getIntegrationData = () => dispatch(tradingActions.getIntegrationData());
  const setIntegrationData = payload => dispatch(tradingActions.setIntegrationData(payload));
  const removeIntegrationData = () => dispatch(tradingActions.removeIntegrationData());
  const clearTradingData = () => dispatch(tradingActions.clearTradingData());

  useEffect(() => {
    getIntegrationData();
    return () => clearTradingData();
  }, []);

  useEffect(() => {
    if (integrationData) {
      const noSensitiveData = { ...integrationData, secret: '', passphrase: '' };

      if (!isEqual(noSensitiveData, apiInfo)) {
        setApiInfo(noSensitiveData);
        getBalances();

        if (asset && pair) {
          const product_id = `${asset}-${pair}`;

          orders === 'Open' && getOpenOrders(product_id);
          orders === 'Filled' && getFilledOrders(product_id);
        }
      }
    }
  }, [integrationData]);

  const changeInfo = () => {
    const { passphrase, secret, key } = apiInfo;

    if (integrationData) {
      if (!isEqual(integrationData, apiInfo) && !isEmpty(passphrase) && !isEmpty(secret) && !isEmpty(key)) {
        const { passphrase: intPass, secret: intSec, key: intKey } = integrationData;

        // If updating existing values, make sure all three are different before pushing change.
        if (intPass !== passphrase && intSec !== secret && intKey !== key) {
          setIntegrationData(apiInfo);
          setApiInfo({ ...apiInfo, secret: '', passphrase: '' });
        }
      } else if (isEmpty(key)) {
        removeIntegrationData();
        setApiInfo(defaultApiInfo);
      }
    } else {
      if (!isEmpty(passphrase) && !isEmpty(secret) && !isEmpty(key)) {
        setIntegrationData(apiInfo);
        setApiInfo({ ...apiInfo, secret: '', passphrase: '' });
      }
    }
  };

  return (
    <Fragment>
      <h4>
        Coinbase Integration

        <Button
          text="Remove"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (integrationData) {
              removeIntegrationData();
              setApiInfo(defaultApiInfo);
            }
          }} />
      </h4>

      <label>
        <p className="labelTitle">Key*</p>

        <input
          type="text"
          value={apiInfo.key}
          onBlur={changeInfo}
          onKeyPress={e => e.key === 'Enter' && changeInfo()}
          onChange={e => {
            const newVal = e.currentTarget.value;
            setApiInfo({ ...apiInfo, key: newVal })
          }} />
      </label>

      <label>
        <p className="labelTitle">Passphrase*</p>

        <input
          type="password"
          value={apiInfo.passphrase}
          placeholder={integrationData ? 'Enter new passphrase to replace.' : ''}
          onBlur={changeInfo}
          onKeyPress={e => e.key === 'Enter' && changeInfo()}
          onChange={e => {
            const newVal = e.currentTarget.value;
            setApiInfo({ ...apiInfo, passphrase: newVal })
          }} />
      </label>

      <label>
        <p className="labelTitle">Secret*</p>

        <input
          type="password"
          value={apiInfo.secret}
          placeholder={integrationData ? 'Enter new secret to replace.' : ''}
          onBlur={changeInfo}
          onKeyPress={e => e.key === 'Enter' && changeInfo()}
          onChange={e => {
            const newVal = e.currentTarget.value;
            setApiInfo({ ...apiInfo, secret: newVal })
          }} />
      </label>

      {integrationData && (
        <p className="note">
          <strong>Note</strong>: All field values must be different from prior
          integration for update to process.
        </p>
      )}

      {!integrationData && (
        <p className="note">
          <strong>Note</strong>: All field values are required for integration to process.
        </p>
      )}

      <p className="note">Values are sent over an SSL encrypted connection and stored as encrypted data.</p>
    </Fragment>
  );
}

export default ExchangeSettings;