import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './DeleteAccountConfirm.scss';

const schema = yup.object().shape({
  confirmation: yup.string().required('Confirmation is required.')
    .oneOf(['DELETE'], 'You must type "DELETE".').trim(),
});

const DeleteAccountConfirm = props => {
  const { deleteAccount } = props;
  const defaults = { confirmation: '' };

  const handleSubmit = (values, setSubmitting) => {
    const { confirmation } = values;

    const payload = { confirmation };
    deleteAccount(payload);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={defaults}
      validationSchema={schema}
      onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}>
        {form => (
          <Form className="deleteAccountForm">
            <div className="deleteAccountConfirm">
              <h2>Please confirm below.</h2>
              <p className="instructions">Type "DELETE" in the field.</p>

              <Field type="text" name="confirmation" placeholder="Confirmation*" />
              <FormFeedback name="confirmation" />

              <Button
                type="submit"
                icon="warning"
                iconColor={colors.red}
                text={`Delete account`}
                textColor={colors.white}
                bgColor={colors.darkGrey2}
                disabled={form.isSubmitting} />
            </div>
          </Form>
        )}
    </Formik>
  );
};

export default DeleteAccountConfirm;