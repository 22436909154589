/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tradingActions, tradingSelectors } from 'modules/trading/tradingDuck';
import Pagination from 'components/Pagination/Pagination';
import { makePageable } from 'helpers/utilityFunctions';
import { isEmpty } from 'lodash';
import FilledOrdersHeader from './FilledOrdersHeader';
import FilledOrderCard from './FilledOrderCard';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './OrdersTable.scss';

const FilledOrders = props => {
  const { format, itemsPerPage, asset, pair, privacy } = props;
  const [ currentPage, setCurrentPage ] = useState(1);
  const dispatch = useDispatch();

  // Actions


  // Selectors
  const filledOrders = useSelector(state => tradingSelectors.filledOrders(state));

  const pageData = makePageable(!isEmpty(filledOrders) && filledOrders, itemsPerPage);

  const buildTableRows = () => {
    if (currentPage > pageData.length && format === 'Desktop') {
      setCurrentPage(pageData.length);
      return null;
    };

    const dataItem = () => {
      if (format === 'Desktop') return pageData[currentPage - 1];
      return filledOrders;
    };

    return dataItem().map((order, index) => {
      return (
        <li key={index}>
          <FilledOrderCard
            pair={pair}
            privacy={privacy}
            order={order} />
        </li>
      );
    });
  };

  return (
    <div className="ordersTable">
      <ul id="filledOrders">
        <li className="strong">
          <FilledOrdersHeader asset={asset} pair={pair} />
        </li>

        {!isEmpty(filledOrders) && buildTableRows()}
        {isEmpty(filledOrders) && <p>No filled orders</p>}
      </ul>

      <div className="filledOrdersPagination">
        {(pageData.length > 1 && format === 'Desktop') && (
          <Pagination
            totalPages={pageData.length}
            currentPage={currentPage}
            changePage={newPage => setCurrentPage(newPage)} />
        )}
      </div>
    </div>
  )
}

export default FilledOrders;