/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { apiActions } from 'modules/api/apiDuck';

export const getPortfolio = type => {
  return apiActions.request({
    path: `portfolio`,
    method: 'GET',
    type,
  });
};

export const reorderPortfolio = (payload, type) => {
  return apiActions.request({
    path: `portfolio/reorder`,
    method: 'POST',
    payload,
    type,
  });
};

export const addToPortfolio = (payload, type, callback) => {
  return apiActions.request({
    path: `portfolio`,
    method: 'POST',
    payload,
    callback,
    type,
  });
};

export const updateWalletById = (payload, type, callback) => {
  return apiActions.request({
    path: `portfolio`,
    method: 'PUT',
    payload,
    callback,
    type,
  });
};

export const deleteWalletById = (payload, type, callback) => {
  return apiActions.request({
    path: `portfolio`,
    method: 'DELETE',
    callback,
    type,
    payload,
  });
};