import React, { Fragment } from 'react';
import VolMASettings from './VolMASettings';
import MovingAverageSettings from './MovingAverageSettings';
import EMARibbonSettings from './EMARibbonSettings';
import IchimokuSettings from './IchimokuSettings';
import BBandsSettings from './BBandsSettings';
import MACDSettings from './MACDSettings';
import RSISettings from './RSISettings';
import StochRSISettings from './StochRSISettings';
import Toggle from 'components/Toggle/Toggle';
import './ChartSettings.scss';
import 'theme/settings.scss';

const ChartSettings = props => {
  const { format, settings, setSettings, subChart } = props;

  const buildChartSettings = () => {
    const { general, indicators } = settings;
    const { useLog } = general;
    const { volActive, volMaActive, movingAveragesActive, emaRibbonActive, bBandsActive, ichimokuActive } = indicators;
    const { volMa, ichimoku, movingAverages, emaRibbon, bBands, macd, rsi, stochRsi } = indicators;

    return (
      <Fragment>
        {format !== 'Mobile' && <h2>Settings</h2>}

        <div className="toggleOptions">
          <div className="volToggle">
            <p className="strong">Volume</p>
            <Toggle
              defaultVal={volActive}
              callback={newVal => {
                const payload = { ...settings };
                payload.indicators.volActive = newVal;
                setSettings(payload);
              }} />
          </div>

          <div className="volMAToggle">
            <p className="strong">Vol. MA</p>
            <Toggle
              defaultVal={volMaActive}
              callback={newVal => {
                const payload = { ...settings };

                if (!volActive && newVal) {
                  payload.indicators.volActive = true;
                }

                payload.indicators.volMaActive = newVal;
                setSettings(payload);
              }} />
          </div>

          <div className="logToggle">
            <p className="strong">Use Log.</p>
            <Toggle
              defaultVal={useLog}
              callback={newVal => {
                const payload = { ...settings };
                payload.general.useLog = newVal;
                setSettings(payload);
              }} />
          </div>
        </div>

        {volMaActive && (
          <VolMASettings
            volMa={volMa}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}
        {movingAveragesActive && (
          <MovingAverageSettings
            movingAverages={movingAverages}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}
        {emaRibbonActive && (
          <EMARibbonSettings
            emaRibbon={emaRibbon}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}
        {ichimokuActive && (
          <IchimokuSettings
            ichimoku={ichimoku}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}
        {bBandsActive && (
          <BBandsSettings
            bBands={bBands}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}

        {subChart === 'MACD' && (
          <MACDSettings
            macd={macd}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}
        {subChart === 'RSI' && (
          <RSISettings
            rsi={rsi}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}
        {subChart === 'Stoch. RSI' && (
          <StochRSISettings
            stochRsi={stochRsi}
            settings={settings}
            setSettings={newVal => setSettings(newVal)} />
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {settings && buildChartSettings()}
    </Fragment>
  )
}

export default ChartSettings;