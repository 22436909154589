import React from 'react';
import { Field, getIn } from 'formik';
import { isEmpty } from 'lodash';
import './FormFeedback.scss';

const FormFeedback = props => {
  const { name } = props;

  if (!name) return null;

  return(
    <Field name={name}>
      {({ form }) => {
        const error = getIn(form.errors, name);
        const touched = getIn(form.touched, name);
        const message = typeof(error) === 'string' && error;

        if (touched && error) {
          return (
            <div className="formFeedback">
              {!isEmpty(message) && message}
            </div>
          );
        } else {
          return null;
        };
      }}
    </Field>
  );
}
export default FormFeedback;