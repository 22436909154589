/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { chartsActions, chartsSelectors } from 'modules/charts/chartsDuck';
import { tradingActions } from 'modules/trading/tradingDuck';
import { portfolioActions } from 'modules/portfolio/portfolioDuck';
import { apiActions, apiSelectors } from 'modules/api/apiDuck';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Offline, Online } from "react-detect-offline";
import { useFlag } from "flags";
import moment from 'moment';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import MainLoader from './MainLoader/MainLoader';
import { CSSTransition } from 'react-transition-group';
import CCCWebsocketContainer from 'components/Websocket/CCCWebsocketContainer';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import Login from 'scenes/login/Login';
import CreateAccount from 'scenes/login/CreateAccount';
import ResetPassword from 'scenes/login/ResetPassword';
import Account from 'scenes/account/Account';
import Brand from 'components/Brand/Brand';
import Nav from 'components/Nav/Nav';
import UserNav from './UserNav/UserNav';
import Charts from 'scenes/charts/Charts';
import Trading from 'scenes/trading/Trading';
import Portfolio from 'scenes/portfolio/Portfolio';
import News from 'scenes/news/News';
import Marketcap from 'scenes/marketcap/Marketcap';
import NotFound from 'scenes/notFound/NotFound';
import TermsOfService from 'components/AuthForm/CreateAccount/TermsOfService';
import PrivacyPolicy from 'components/AuthForm/CreateAccount/PrivacyPolicy';
import ErrorMessages from 'components/ServerMessages/ErrorMessages';
import SuccessMessages from 'components/ServerMessages/SuccessMessages';
import '@fortawesome/fontawesome-pro/css/all.css';
import './App.scss';

let sessionTimer;

const App = props => {
  const maintenanceFlag = useFlag(["features", "maintenance"]);
  const dispatch = useDispatch();

  // Selectors
  const token = useSelector(state => appSelectors.token(state));
  const user = useSelector(state => appSelectors.user(state));
  const allCoins = useSelector(state => chartsSelectors.allCoins(state));
  const exchangePairs = useSelector(state => chartsSelectors.exchangePairs(state));

  // Actions
  const logout = () => dispatch(appActions.logout());
  const checkToken = token => dispatch(appActions.checkToken(token));
  const setErrorMessages = payload => dispatch(apiActions.setErrorMessages(payload));
  const clearFavorites = () => dispatch(chartsActions.clearFavorites());
  const clearTradingData = () => dispatch(tradingActions.clearTradingData());
  const clearPortfolio = () => dispatch(portfolioActions.clearPortfolio());
  const getAllCoins = () => dispatch(chartsActions.getAllCoins());
  const getExchangePairs = () => dispatch(chartsActions.getExchangePairs());

  const setSessionTimer = () => {
    sessionTimer = setInterval(() => {
      const sessionEnd = moment.unix(user.sessionEnd);
      const now = moment()
      const timeLeft = sessionEnd.diff(now, 'seconds');

      if (timeLeft === 0) {
        setErrorMessages({ errors: [{ msg: 'Your session has expired.' }]});
        clearFavorites();
        clearPortfolio();
        clearTradingData();
        logout();
      }
    }, 1000);
  }

  useEffect(() => {
    const existingToken = JSON.parse(localStorage.getItem('xolioToken'));
    existingToken && checkToken({ token: existingToken });
    // getAllCoins();
    // getExchangePairs();
  }, []);

  useEffect(() => {
    if (user) setSessionTimer();
    return () => clearInterval(sessionTimer);
  }, [user]);

  const loggedIn = !isEmpty(token);
  const userInfo = user;

  const mainApp = () => {
    return (
      <Fragment>
        <section id="body">
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/create-account" component={CreateAccount} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />

            <ProtectedRoute loggedIn={loggedIn} path="/charts" component={Charts} />
            <ProtectedRoute loggedIn={loggedIn} path="/trade" component={Trading} />
            <ProtectedRoute loggedIn={loggedIn} path="/portfolio" component={Portfolio} />
            <ProtectedRoute loggedIn={loggedIn} path="/news" component={News} />
            <ProtectedRoute loggedIn={loggedIn} path="/marketcap" component={Marketcap} />

            <ProtectedRoute loggedIn={loggedIn} userInfo={userInfo}
              authed={loggedIn} logout={logout} path="/account" component={Account} />

            {/* Redirect */}
            <Redirect path='/' to='/charts' />

            {/* 404 -- Unknown Routes */}
            <Route component={NotFound} />
          </Switch>
        </section>
      </Fragment>
    );
  };

  const buildApp = () => {
    return (
      <main id="main">
        <header id="header">
          <Brand />

          <CSSTransition in={loggedIn} timeout={300} classNames="mainNav" unmountOnExit >
            <Nav loggedIn={loggedIn} userInfo={userInfo} />
          </CSSTransition>

          <CSSTransition in={loggedIn} timeout={300} classNames="socketIndicator" unmountOnExit >
            <CCCWebsocketContainer />
          </CSSTransition>

          <CSSTransition in={loggedIn} timeout={300} classNames="nav" unmountOnExit >
            <UserNav />
          </CSSTransition>
        </header>

        {/* {(allCoins && exchangePairs) && mainApp()} */}
        {mainApp()}

        {/* {(!allCoins || !exchangePairs) && (
          <MainLoader allCoins={allCoins} exchangePairs={exchangePairs} />
        )} */}

        <SuccessMessages />
        <ErrorMessages />
      </main>
    );
  };

  const showOfflineMsg = () => {
    return (
      <div className="appOffline">
        <i className="fad fa-ethernet" />
        <h1>Well, shucks!</h1>
        <p>Looks like the connection's gone out...</p>
      </div>
    );
  };

  const showMaintenanceMsg = () => {
    return (
      <div className="appOffline">
        <i className="fad fa-tools" />
        <h1>Be right back...</h1>
        <p>Doing some house cleaning!</p>
      </div>
    );
  };

  return (
    <BrowserRouter>
      {/* {maintenanceFlag ? showMaintenanceMsg() : (
        <Fragment>
          <Online>{buildApp()}</Online>
          <Offline>{showOfflineMsg()}</Offline>
        </Fragment>
      )} */}

      {buildApp()}
    </BrowserRouter>
  )
};

export default App;