/* eslint-disable no-unused-vars */

import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { streamingSelectors } from 'modules/streaming/streamingDuck';
import { isEmpty } from 'lodash';
import { formatWholeNum } from 'helpers/utilityFunctions';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import assetColors from 'helpers/assetColors';
import colors from 'theme/colors.scss';

let totalValue = 0;

const MyValue = props => {
  const { type, asset, pair, balance, address, wallets, percent, showBar } = props;

  // Selectors
  const streamingData = useSelector(state => streamingSelectors.streamingData(state));

  const buildValue = () => {
    if (!isEmpty(streamingData) && asset && pair && balance && wallets) {
      let workingData = streamingData.find(data => data.asset === asset && data.pair === pair);
      let priceVal;

      if (asset === pair) {
        workingData = streamingData.find(data => data.asset === asset);
      }

      if (workingData) {
        priceVal = asset === pair ? balance : workingData.close * balance;

        if (percent) {
          let workingTotal = 0;
          let workingPcts = [];

          wallets.forEach(wallet => {
            const streamData = streamingData.find(item => item.pair === pair && item.asset === wallet.chainType);

            if (wallet.chainType === pair) {
              workingTotal += wallet.balance;
            } else if (streamData) {
              workingTotal += streamData.close * wallet.balance;
            }
          });

          totalValue = workingTotal;

          wallets.forEach(wallet => {
            const streamData = streamingData.find(item => item.pair === pair && item.asset === wallet.chainType);

            if (wallet.chainType === pair) {
              workingPcts.push({
                address: wallet.address,
                type: wallet.chainType,
                pct: (wallet.balance / totalValue * 100).toFixed(2),
              })
            } else if (streamData) {
              workingPcts.push({
                address: wallet.address,
                type: wallet.chainType,
                pct: (wallet.balance * streamData.close / totalValue * 100).toFixed(2),
              })
            }
          });

          workingPcts.forEach(item => {
            if (item.address === address && item.type === asset) {
              priceVal = parseFloat(item.pct);
            }
          });

        } else {
          if (pair === 'BTC') {
            priceVal = parseFloat(priceVal.toFixed(8));
          } else {
            priceVal = parseFloat(priceVal.toFixed(2));
          };
        };

        return (
          <Fragment>
            {priceVal && !percent && (
              <Fragment>
                <i className={pair === 'BTC' ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
              </Fragment>
            )}

            <span className="positionVal">
              {type !== 'delta' && priceVal && formatWholeNum(priceVal.toString())}{percent && ' %'}
            </span>

            {(percent && showBar) && <ProgressBar color={assetColors(asset)} length={priceVal} />}
          </Fragment>
        )};

        return <i className='fad fa-horizontal-rule' />;
    };

    return (
      <Fragment>
        {!percent && (
          <Fragment>
            <i className={pair === 'BTC' ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
          </Fragment>
        )}

        <span className="positionVal">
          {!percent && pair === 'BTC' ? "0.00000000" : "0.00"}
          {percent && ' %'}
        </span>

        {(percent && showBar) && <ProgressBar color={assetColors(asset)} length={0} />}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {streamingData && buildValue()}
    </Fragment>
  );
};

export default MyValue;