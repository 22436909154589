import React, { useState } from 'react';
import './Toggle.scss';

const Toggle = props => {
  const { defaultVal, callback, ...rest } = props;
  const [ isChecked, setIsChecked ] = useState(defaultVal);

  return (
    <React.Fragment>
      <label className="toggle">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={e => {
            const newVal = e.currentTarget.checked;
            setIsChecked(!isChecked);
            callback(newVal);
          }}
          {...rest} />

        <span className="slider round"></span>
      </label>
    </React.Fragment>
  );
};

export default Toggle;