import React, { Fragment, useState } from 'react';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual } from 'lodash';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './ChartSettings.scss';

const MovingAverageSettings = props => {
  const { movingAverages, settings, setSettings } = props;
  const { indicators: { movingAverages: defaultMovingAverages } } = defaultSettings;
  const [ movingAvgVals, setMovingAvgVals ] = useState(movingAverages || defaultMovingAverages);

  const changeVals = (index, newVal, checkVal) => {
    const workingVals = [...movingAvgVals];
    workingVals.splice(index, 1, checkVal ? checkValue(newVal, defaultMovingAverages[index]) : newVal);

    setMovingAvgVals(workingVals);

    if (checkVal && parseInt(movingAvgVals[index]) !== parseInt(movingAverages[index])) {
      const payload = { ...settings };
      payload.indicators.movingAverages = workingVals;
      setSettings(payload);
    }
  };

  return (
    <Fragment>
      <h4>
        Moving Averages

        <Button
          text="Defaults"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (!isEqual(movingAvgVals, defaultMovingAverages)) {
              resetDefaults('movingAverages', settings, setSettings);
              setMovingAvgVals(defaultMovingAverages);
            }
          }} />
      </h4>

      <p className="labelTitle">SMA Lengths</p>
      <div className="maGroup">
        {movingAverages.map((avg, index) => (
          <label key={index}>
            <input
              type="number"
              value={movingAvgVals[index]}
              onBlur={e => changeVals(index, parseInt(e.currentTarget.value), true)}
              onKeyPress={e => e.key === 'Enter' && changeVals(index, parseInt(e.currentTarget.value), true)}
              onChange={e => changeVals(index, parseInt(e.currentTarget.value))} />
          </label>
        ))}
      </div>
    </Fragment>
  );
}

export default MovingAverageSettings;