import React from 'react';
import './MarketcapCard.scss';

const MarketcapHeader = props => {
  return (
    <div className="marketcapCard">
      <div className="symbol"></div>

      <div className="rank">
        <h3>#</h3>
      </div>

      <div className="favorite">
        <i className="fas fa-star" />
      </div>

      <div id="assetName" className="name">
        <h3>Asset</h3>
      </div>

      <div className="marketcap">
        <h3>Market Cap.</h3>
      </div>

      <div className="dominance">
        <h3>Dominance</h3>
      </div>

      <div className="volume">
        <h3>24H Volume</h3>
      </div>

      <div className="supply">
        <h3>Circ. Supply</h3>
      </div>

      <div className="price">
        <h3>Price</h3>
      </div>

      <div className="pctChange">
        <h3>24H Change</h3>
      </div>
    </div>
  );
};

export default MarketcapHeader;