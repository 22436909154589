/* eslint-disable no-unused-vars */

import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portfolioActions, portfolioSelectors } from 'modules/portfolio/portfolioDuck';
import { defaultModal } from 'helpers/modals';
import { Formik, Form, Field } from 'formik';
import { formatWholeNum } from 'helpers/utilityFunctions';
import * as yup from 'yup';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import FieldWithAction from 'components/FieldWithAction/FieldWithAction';
import Button from 'components/Button/Button';
import * as colors from 'theme/colors.scss';
import './WalletForm.scss';

const schema = yup.object().shape({
  name: yup.string().required('Name is required.').trim(),
  tag: yup.string().trim().nullable(),
  address: yup.string().required('Wallet address is required.').trim(),
  chainType: yup.string().required('Asset symbol is required.').trim(),
  balance: yup.number()
  .transform((val, originalVal) => parseFloat(originalVal.replace(/,/g, '')))
  .typeError('Must be a number.').min(0, 'Invalid balance.').required('Balance is required.'),
});

const WalletForm = props => {
  const { wallet, setModalState, isEditing, privacy } = props;
  const dispatch = useDispatch();

  const defaults = {
    name: wallet ? wallet.name : '',
    address: wallet ? wallet.address : '',
    tag: wallet ? (wallet.tag !== null && wallet.tag) || '' : '',
    chainType: wallet ? wallet.chainType : '',
    balance: wallet ? formatWholeNum(wallet.balance) : '',
  };

  // Actions
  const wallets = useSelector(state => portfolioSelectors.wallets(state));
  const updateWalletById = useCallback((address, payload, callback) => dispatch(
    portfolioActions.updateWalletById(address, payload, callback)
  ), [dispatch]);
  const addToPortfolio = useCallback((payload, callback) => dispatch(
    portfolioActions.addToPortfolio(payload, callback)
  ), [dispatch]);

  const handleSubmit = (values, setSubmitting) => {
    const { name, address, chainType, tag, balance } = values;

    const walletInfo = {
      name: name.trim(),
      address: address.trim(),
      chainType: chainType.substr(0, 5).toUpperCase().trim(),
      tag: tag,
      balance: parseFloat(balance.replace(/,/g, '')),
    };

    if (wallet) {
      const updatedWallet = { ...walletInfo, id: wallet.id };
      updateWalletById(updatedWallet, () => {
        setModalState(defaultModal);
      });
    } else {
      addToPortfolio(walletInfo, () => {
        setModalState(defaultModal);
      });
    };

    setSubmitting(false);
  };

  // const validateAddress = address => {
  //   const exists = wallets.find(existingWallet => existingWallet.address === address);
  //   const errorMsg = 'Wallet already exists in portfolio.';

  //   if (exists && !isEditing) return errorMsg;
  //   if (exists && isEditing && wallet.address !== address) return errorMsg;
  // };

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}>
          {form => (
            <Form className="walletForm">
              <ul className="inlineGroup">
                <li>
                  <Field type="text" name="name" placeholder="Wallet name*" />
                  <FormFeedback name="name" />
                </li>

                <li>
                  <Field type="text" name="chainType" placeholder="Asset symbol: i.e. BTC*" />
                  <FormFeedback name="chainType" />
                </li>
              </ul>

              <FieldWithAction
                form={form}
                modal={true}
                name="address"
                placeholder="Wallet address*"
                activeIcon="copy"
                inactiveIcon="copy2"
                highlightField={true}
                type={privacy ? 'password' : 'text'}
                disabled={privacy}
                notificationMsg="Copied to clipboard."
                // validate={() => validateAddress(form.values.address.trim())}
                callback={() => navigator.clipboard.writeText(form.values.address)} />

              <Field
                type={privacy ? 'password' : 'text'}
                name="balance"
                placeholder="Balance*"
                disabled={privacy} />

              <FormFeedback name="balance" />

              <FieldWithAction
                form={form}
                modal={true}
                name="tag"
                placeholder="Tag or memo"
                activeIcon="copy"
                inactiveIcon="copy2"
                highlightField={true}
                type={privacy ? 'password' : 'text'}
                disabled={privacy}
                notificationMsg="Copied to clipboard."
                callback={() => navigator.clipboard.writeText(form.values.tag)} />

              <Button
                type="submit"
                text={wallet ? 'Save wallet' : 'Add wallet'}
                textColor={colors.white}
                icon={wallet ? 'save' : 'add'}
                iconColor={colors.white}
                btnStyle="solid"
                bgColor={colors.darkGrey2}
                disabled={form.isSubmitting} />
            </Form>
          )}
      </Formik>
    </Fragment>
  )
}

export default WalletForm;