/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, Fragment } from 'react';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import { formatWholeNum } from 'helpers/utilityFunctions';
import { timeAgg } from 'helpers/utilityFunctions';
import { isEmpty } from 'lodash';
import WalletForm from '../WalletForm/WalletForm';
import DeleteWalletConfirm from '../DeleteWalletConfirm/DeleteWalletConfirm';
import Price from 'components/Price/Price';
import Button from 'components/Button/Button';
import MyValue from './MyValue';
import PriceDelta from 'components/PriceDelta/PriceDelta';
import * as colors from 'theme/colors.scss';
import './WalletCard.scss';
import Axios from 'axios';

const { REACT_APP_NAME: appName } = process.env;

const WalletCard = props => {
  const { pair, wallet, wallets, privacy, filteredData } = props;
  const [ modalState, setModalState ] = useState(defaultModal);
  const [ assetData, setAssetData ] = useState(null);

  const {
    chainType,
    name,
    address,
    balance,
  } = wallet;

  const gripStyle = {
    visibility: !isEmpty(filteredData) ? 'hidden' : 'visible',
  };

  useEffect(() => {
    const getAssetInfo = async () => {
      try {
        const url = `https://min-api.cryptocompare.com/data/v2/${timeAgg('1D')}&fsym=${chainType}&tsym=${pair}&e=CCCAGG&limit=1&extraParams=${appName}`;
        const res = await Axios.get(url);
        const { Data } = await res.data.Data;
        const recent = Data && { ...Data[Data.length - 1], asset: chainType, pair };

        setAssetData(recent);
        return Data;
      } catch(err) {
        console.log(err);
      }
    }

    getAssetInfo();
  }, [pair, wallets]);

  const rowClick = (e, mode) => {
    e.stopPropagation();

    if (mode === 'remove') {
      return openModal(
        setModalState, address, 'Remove Wallet',
        <DeleteWalletConfirm wallet={wallet} setModalState={newState => setModalState(newState)} />
      );
    };

    return openModal(
      setModalState, address, 'Edit Wallet',
      <WalletForm isEditing={true} privacy={privacy} wallet={wallet} setModalState={newState => setModalState(newState)} />
    );
  };

  const buildPrice = () => {
    if (pair === chainType) {
      return (
        <Fragment>
          <PriceDelta
            open={assetData?.open.toString()}
            asset={chainType}
            pair={pair}
            styles={{ fontWeight: 'normal' }} />

          <i className={'fab fa-btc'} /> {formatWholeNum('1.00000000')}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <PriceDelta
          open={assetData?.open.toString()}
          asset={chainType}
          pair={pair}
          styles={{ fontWeight: 'normal' }} />

        <Price
          type="close"
          asset={chainType}
          pair={pair}
          useSymbol={true} />
      </Fragment>
    );
  };

  const buildCard = () => {
    return (
      <div className="walletCard" onClick={e => rowClick(e)}>
        <div className="chainType strong">
          <span className="symbol strong">{chainType}</span>
        </div>

        <div id="removeWallet" className="remove">
          <Button
            icon='remove'
            iconColor={colors.slate}
            btnStyle='transparent'
            callback={e => rowClick(e, 'remove')} />
        </div>

        <div className="walletName">
          {name}
        </div>

        <div className="balance">
          <Fragment>
            {privacy
              ? '***'
              : balance === 0 && formatWholeNum(chainType === ("BTC" || "ETH") ? "0.00000000" : "0.000000")
            }

            {!privacy && balance !== 0 && formatWholeNum(balance.toString())}
          </Fragment>
        </div>

        <div className="value">
          <Fragment>
            {privacy
              ? '***'
              : <MyValue
                  wallets={wallets}
                  type="close"
                  asset={chainType}
                  pair={pair}
                  balance={balance} />
            }
          </Fragment>
        </div>

        <div className="position">
          <MyValue
            percent={true}
            wallets={wallets}
            type="close"
            asset={chainType}
            pair={pair}
            address={address}
            balance={balance}
            showBar={true} />
        </div>

        <div className="price">
          {buildPrice()}
        </div>

        <i className="fad fa-grip-vertical draggable" style={gripStyle} />
      </div>
    );
  };

  return (
    <Fragment>
      {buildCard()}
      {showModal(modalState, setModalState)}
    </Fragment>
  );
};

export default WalletCard;