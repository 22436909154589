/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import io from 'socket.io-client';
import { signRequest } from 'helpers/utilityFunctions';
import { utility, unpackMsg } from 'helpers/ccc-streamer-utilities';
import { isEmpty, isEqual } from 'lodash';
import * as appStatus from 'scenes/app/appConstants';

const {
  REACT_APP_CB_WS_SANDBOX: exchangeSandboxPath,
  REACT_APP_CB_WS: exchangePath,
} = process.env;

const { reconnecting, connected, disconnected, timeout } = appStatus;
let socket;
let existingSocket;
let tempData = [];
let cbStatusData = null;
let cbUserData = null;

const subs = args => {
  const { asset, pair, source, wallets, nextPath } = args;
  const type = source === 'CCCAGG' ? 5 : 2;
  const subList = [];

  if (nextPath === '/charts' && (asset && pair && source)) {
    if (asset !== 'BTC') {
      subList.push(`${type}~${source}~${asset}~BTC`);
    };
    subList.push(`${type}~${source}~${asset}~USD`);
  };

  if (nextPath === '/portfolio' && !isEmpty(wallets)) {
    wallets.forEach(wallet => {
      const { chainType } = wallet;
      if (chainType !== 'BTC') {
        subList.push(`5~CCCAGG~${chainType}~BTC`);
      };

      subList.push(`5~CCCAGG~${chainType}~USD`);
    });
  };

  if (nextPath === '/trade' && asset && pair) {
    if (asset !== 'BTC') {
      subList.push(`${asset}-BTC`);
    }
    subList.push(`${asset}-USD`);
  }

  return subList;
};

const cbSubs = (type, product_ids, integrationData) => {
  const { passphrase, secret, key } = integrationData;

  let subList = {
    type,
    product_ids,
    channels: ['status', 'user', 'ticker'],
  };

  const needsAuth = subList.channels.find(channel => channel === 'user');
  const timestamp = Date.now() / 1000;
  const endpoint = '/users/self/verify';
  const body = '';
  const method = 'GET';

  if (type === 'subscribe' && needsAuth) {
    subList = {
      ...subList,
      signature: signRequest(timestamp, endpoint, body, method, secret),
      key,
      passphrase,
      timestamp
    }
  }

  return JSON.stringify(subList);
};

const unpack = (res, args) => {
  const { nextPath, asset, streamingData, setStreamingData } = args;
  const { FROMSYMBOL, TOSYMBOL, PRICE, VOLUME24HOURTO } = res;

  // Create an object in data if it doesn't exist.
  const itemExists = tempData.find(item => item.asset === FROMSYMBOL && item.pair === TOSYMBOL);
  !itemExists && tempData.push({ asset: FROMSYMBOL, pair: TOSYMBOL });

  const index = tempData.findIndex(item => item.asset === FROMSYMBOL && item.pair === TOSYMBOL);

  if (nextPath === '/charts' && asset && FROMSYMBOL === asset.toUpperCase()) {
    if ("PRICE" in res) {
      const price = PRICE;
      !isNaN(price) && (tempData[index].close = price);
    };

    if ("VOLUME24HOURTO" in res) {
      const volume = +(VOLUME24HOURTO.toFixed(2));
      !isNaN(volume) && (tempData[index].volume = volume);
    };
  };

  if (nextPath === '/portfolio') {
    if ("PRICE" in res) {
      const price = PRICE;
      !isNaN(price) && (tempData[index].close = price);
    };
  };

  !isEqual(tempData, streamingData) && setStreamingData(tempData);
};

const cbUnpack = (res, args) => {
  const {
    asset,
    orders,
    streamingData,
    setStreamingData,
    streamingStatusData,
    setStreamingStatusData,
    streamingUserData,
    setStreamingUserData,
    getBalances,
    getOpenOrders,
    getFilledOrders,
  } = args;

  const { type } = res;

  if (type === 'ticker') {
    const { product_id } = res;
    const pair = product_id.split('-');
    const FROMSYMBOL = pair[0];
    const TOSYMBOL = pair[1];

    // Create an object in data if it doesn't exist.
    const itemExists = tempData.find(item => item.asset === FROMSYMBOL && item.pair === TOSYMBOL);
    !itemExists && tempData.push({ asset: FROMSYMBOL, pair: TOSYMBOL });

    const index = tempData.findIndex(item => item.asset === FROMSYMBOL && item.pair === TOSYMBOL);

    tempData[index] = { ...tempData[index], ...res };
    !isEqual(tempData, streamingData) && setStreamingData(tempData);
  }

  if (type === 'status') {
    const { currencies, products } = res;
    const currency = currencies.find(currency => currency.id === asset);
    const product = products.filter(product => product.id === `${asset}-USD` || product.id === `${asset}-BTC`);
    cbStatusData = { currency, product };
    !isEqual(cbStatusData, streamingStatusData) && setStreamingStatusData(cbStatusData);
  }

  if (type === 'open' || type === 'done' || type === 'match' || type === 'activate') {
    const { product_id } = res;
    /*
      User response types (ignoring 'received'):
      received (new valid buy/sell orders, immediately filled or not),
      open (open orders, not filled),
      done (filled/canceled orders),
      match (market orders),
      activate (stop loss orders)
    */

    getBalances();
    orders === 'Open' && getOpenOrders(product_id);
    orders === 'Filled' && getFilledOrders(product_id);

    cbUserData = { data: res };
    !isEqual(cbUserData, streamingUserData) && setStreamingUserData(cbUserData);
  }
};

export function connectCBSocket(args) {
    const {
      setConnStatus,
      setStreamingData,
      setStreamingStatusData,
      setStreamingUserData,
      integrationData,
    } = args;

  if (existingSocket) {
    existingSocket.disconnect ? existingSocket.disconnect() : existingSocket.close();
    tempData = [];
    cbStatusData = null;
    cbUserData = null;
    setStreamingData(tempData);
    setStreamingStatusData(cbStatusData);
    setStreamingUserData(cbUserData);

    if (socket.disconnected) {
      socket = new WebSocket(exchangePath);
    }
  } else {
    socket = new WebSocket(exchangePath);
  }

  const product_ids = subs(args);

  socket.addEventListener('open', () => {
    existingSocket = socket;
    // console.log('Connected.');
    socket.send(cbSubs('subscribe', product_ids, integrationData));
    setConnStatus(connected);

    // Get data
    socket.addEventListener('message', res => {
      const data = JSON.parse(res.data);
      data && cbUnpack(data, args);
    });
  });

  socket.addEventListener('close', () => {
    // console.log('Disconnected');
    socket.send(cbSubs('unsubscribe', product_ids, integrationData));
    setConnStatus(disconnected);
  });

  socket.addEventListener('error', res => {
    const error = JSON.parse(res);
    console.log(`Error: ${error.message}`);
    setConnStatus(disconnected);
  });
}

export function connectSocket(args) {
  const { setConnStatus, setStreamingData } = args;

  if (existingSocket) {
    existingSocket.disconnect ? existingSocket.disconnect() : existingSocket.close();
    tempData = [];
    setStreamingData(tempData);
  };

  socket = io('wss://streamer.cryptocompare.com', {transports: ['websocket']});

  socket.on('connect', () => {
    existingSocket = socket;
    // console.log('Connected.');
    socket.emit('SubAdd', {
      subs: subs(args)
    });
    setConnStatus(connected);

    // Get  data string.
    socket.on('m', data => {
      const type = data.substring(0, data.indexOf('~'));
      let res = {};

      if (type === utility.CURRENTAGG || type === utility.CURRENT) {
        res = unpackMsg(data);
        unpack(res, args);
      };
    });
  });

  socket.on('disconnect', () => {
    // console.log(`Disconnected.`);
    socket.emit('SubRemove', {
      subs: subs(args)
    });
    setConnStatus(disconnected);
  });

  socket.on('reconnect', () => {
    // console.log('Reconnecting.');
    socket.emit('SubAdd', {
      subs: subs(args)
    });
    setConnStatus(reconnecting);
  });

  socket.on('connect_error', () => {
    // console.log("Error connecting to server.");
    setConnStatus(disconnected);
  });

  socket.on('connect_timeout', () => {
    // console.log("Connection timed out.");
    setConnStatus(timeout);
  });
};