import React, { Fragment, useState } from 'react';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual } from 'lodash';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './ChartSettings.scss';

const MACDSettings = props => {
  const { macd, settings, setSettings } = props;
  const { indicators: { macd: defaultMacd } } = defaultSettings;
  const [ macdVals, setMacdVals ] = useState(macd || defaultMacd);

  const changeVals = (index, newVal, checkVal) => {
    const workingVals = [...macdVals];
    workingVals.splice(index, 1, checkVal ? checkValue(newVal, defaultMacd[index]) : newVal);

    setMacdVals(workingVals);

    if (checkVal && parseInt(macdVals[index]) !== parseInt(macd[index])) {
      const payload = { ...settings };
      payload.indicators.macd = workingVals;
      setSettings(payload);
    }
  };

  return (
    <Fragment>
      <h4>
        MACD

        <Button
          text="Defaults"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (!isEqual(macdVals, defaultMacd)) {
              resetDefaults('macd', settings, setSettings);
              setMacdVals(defaultMacd);
            }
          }} />
      </h4>

      <div className="macdGroup">
        <label>
          <p className="labelTitle">Fast Length</p>
          <input
            type="number"
            value={macdVals[0]}
            onBlur={e => changeVals(0, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(0, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(0, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Slow Length</p>
          <input
            type="number"
            value={macdVals[1]}
            onBlur={e => changeVals(1, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(1, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(1, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Smoothing</p>
          <input
            type="number"
            value={macdVals[2]}
            onBlur={e => changeVals(2, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(2, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(2, parseInt(e.currentTarget.value))} />
        </label>
      </div>
    </Fragment>
  );
}

export default MACDSettings;