/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from 'd3';
import 'd3-selection-multi';
import { EMA } from 'helpers/utilityFunctions';
import './emaRibbon.scss';

const generateEmaRibbon = (OHLC, visibleRange, emaRibbonLengths, chart, pathGen, emaRibbonState, setEmaRibbonState) => {
  const { active, range } = emaRibbonState;

  if (active) {
    let EMARibbonLine1 = new EMA(OHLC.close, emaRibbonLengths[0]).calc();
    let EMARibbonLine2 = new EMA(OHLC.close, emaRibbonLengths[1]).calc();
    let EMARibbonLine3 = new EMA(OHLC.close, emaRibbonLengths[2]).calc();
    let EMARibbonLine4 = new EMA(OHLC.close, emaRibbonLengths[3]).calc();
    let EMARibbonLine5 = new EMA(OHLC.close, emaRibbonLengths[4]).calc();
    let EMARibbonLine6 = new EMA(OHLC.close, emaRibbonLengths[5]).calc();
    let EMARibbonLine7 = new EMA(OHLC.close, emaRibbonLengths[6]).calc();
    let EMARibbonLine8 = new EMA(OHLC.close, emaRibbonLengths[7]).calc();
    let EMARibbonLineMain = new EMA(OHLC.close, emaRibbonLengths[8]).calc();

    EMARibbonLine1 = EMARibbonLine1.slice(EMARibbonLine1.length - visibleRange);
    EMARibbonLine2 = EMARibbonLine2.slice(EMARibbonLine2.length - visibleRange);
    EMARibbonLine3 = EMARibbonLine3.slice(EMARibbonLine3.length - visibleRange);
    EMARibbonLine4 = EMARibbonLine4.slice(EMARibbonLine4.length - visibleRange);
    EMARibbonLine5 = EMARibbonLine5.slice(EMARibbonLine5.length - visibleRange);
    EMARibbonLine6 = EMARibbonLine6.slice(EMARibbonLine6.length - visibleRange);
    EMARibbonLine7 = EMARibbonLine7.slice(EMARibbonLine7.length - visibleRange);
    EMARibbonLine8 = EMARibbonLine8.slice(EMARibbonLine8.length - visibleRange);
    EMARibbonLineMain = EMARibbonLineMain.slice(EMARibbonLineMain.length - visibleRange);

    const ribbonMinMax = [
      d3.min(EMARibbonLine1), d3.max(EMARibbonLine1),
      d3.min(EMARibbonLine2), d3.max(EMARibbonLine2),
      d3.min(EMARibbonLine3), d3.max(EMARibbonLine3),
      d3.min(EMARibbonLine4), d3.max(EMARibbonLine4),
      d3.min(EMARibbonLine5), d3.max(EMARibbonLine5),
      d3.min(EMARibbonLine6), d3.max(EMARibbonLine6),
      d3.min(EMARibbonLine7), d3.max(EMARibbonLine7),
      d3.min(EMARibbonLine8), d3.max(EMARibbonLine8),
      d3.min(EMARibbonLineMain), d3.max(EMARibbonLineMain),
    ];

    const minMax = [d3.min(ribbonMinMax), d3.max(ribbonMinMax)];

    if (range[0] !== minMax[0] || range[1] !== minMax[1]) {
      setEmaRibbonState({ ...emaRibbonState, range: minMax });
    }

    const EMARibbon1 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine1',
      d: pathGen(EMARibbonLine1)
    })

    const EMARibbon2 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine2',
      d: pathGen(EMARibbonLine2)
    })

    const EMARibbon3 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine3',
      d: pathGen(EMARibbonLine3)
    })

    const EMARibbon4 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine4',
      d: pathGen(EMARibbonLine4)
    })

    const EMARibbon5 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine5',
      d: pathGen(EMARibbonLine5)
    })

    const EMARibbon6 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine6',
      d: pathGen(EMARibbonLine6)
    })

    const EMARibbon7 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine7',
      d: pathGen(EMARibbonLine7)
    })

    const EMARibbon8 = chart.append('path')
    .attrs({
      class: 'EMARibbonLine8',
      d: pathGen(EMARibbonLine8)
    })

    const EMARibbonPrimaryLine = chart.append('path')
    .attrs({
      class: 'EMARibbonLineMain',
      d: pathGen(EMARibbonLineMain)
    })
  } else {
    // Reset range if indicator is off
    if (range.length > 0) setEmaRibbonState({ ...emaRibbonState, range: [] });
  }
};

export default generateEmaRibbon;