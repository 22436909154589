/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import FieldWithAction from 'components/FieldWithAction/FieldWithAction';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import colors from 'theme/colors.scss';
import './Login.scss';

const defaults = {
  userEmail: '',
  userPassword: '',
  trustedDevice: false,
};

const schema = yup.object().shape({
  userEmail: yup.string().email('Invalid email.').required('Email is required.').trim(),
  userPassword: yup.string().required('Password is required.').trim(),
});

const Login = props => {
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const [ loggedIn, setLoggedIn ] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  // Selectors
  const token = useSelector(state => appSelectors.token(state));

  // Actions
  const login = useCallback((userInfo, callback) => dispatch(appActions.login(userInfo, callback)), [dispatch]);

  useEffect(() => {
    !!token && setLoggedIn(true);
  });

  useEffect(() => {
    const route = !isEmpty(state) ? state.from.pathname + state.from.search : '/charts';
    !!token && history.push(route);
  }, [loggedIn]);

  const handleSubmit = (values, setSubmitting) => {
    const { userEmail, userPassword, trustedDevice } = values;

    const userInfo = {
      email: userEmail.toLowerCase(),
      password: userPassword,
      trustedDevice,
    };

    login(userInfo, () => {
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={schema}
      onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}>
        {form => (
          <Form className="authForm">
            <Field type="email" name="userEmail" placeholder="Email*" />
            <FormFeedback name="userEmail" />

            <FieldWithAction
              form={form}
              name="userPassword"
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password*"
              activeIcon={passwordVisible ? "eye" : "eyeClosed"}
              inactiveIcon="eyeDisabled"
              highlightField={true}
              callback={() => setPasswordVisible(!passwordVisible)} />

            <Button
              type="submit"
              text="Log in"
              textColor={colors.white}
              btnStyle="solid"
              disabled={form.isSubmitting} />

            <Checkbox
              name="trustedDevice"
              className="trust"
              label="Trust this device for 30 days." />

            <p className="loginOptions">
              <Link to={'/create-account'}>Create an account</Link>
              &nbsp;or <Link to={'/reset-password'}>Reset your password</Link>.
            </p>
          </Form>
        )}
    </Formik>
  );
};

export default withRouter(Login);