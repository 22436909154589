/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { apiActions } from 'modules/api/apiDuck';

export const setConnStatus = (payload, type) => ({ type, payload });

export const setStreamingData = (payload, type) => ({ type, payload });

export const setStreamingStatusData = (payload, type) => ({ type, payload });

export const setStreamingUserData = (payload, type) => ({ type, payload });