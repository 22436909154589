import React, { Fragment, useState } from 'react';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual } from 'lodash';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './ChartSettings.scss';

const IchimokuSettings = props => {
  const { ichimoku, settings, setSettings } = props;
  const { indicators: { ichimoku: defaultIchimoku } } = defaultSettings;
  const [ ichimokuVals, setIchimokuVals ] = useState(ichimoku || defaultIchimoku);

  const changeVals = (index, newVal, checkVal) => {
    const workingVals = [...ichimokuVals];
    workingVals.splice(index, 1, checkVal ? checkValue(newVal, defaultIchimoku[index]) : newVal);

    setIchimokuVals(workingVals);

    if (checkVal && parseInt(ichimokuVals[index]) !== parseInt(ichimoku[index])) {
      const payload = { ...settings };
      payload.indicators.ichimoku = workingVals;
      setSettings(payload);
    }
  };

  return (
    <Fragment>
      <h4>
        Ichimoku Cloud

        <Button
          text="Defaults"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (!isEqual(ichimokuVals, defaultIchimoku)) {
              resetDefaults('ichimoku', settings, setSettings);
              setIchimokuVals(defaultIchimoku);
            }
          }} />
      </h4>

      <div className="ichimokuGroup">
        <label>
          <p className="labelTitle">Conversion Line <br />(Kenkan sen)</p>
          <input
            type="number"
            value={ichimokuVals[0]}
            onBlur={e => changeVals(0, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(0, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(0, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Base Line <br />(Kijun sen)</p>
          <input
            type="number"
            value={ichimokuVals[1]}
            onBlur={e => changeVals(1, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(1, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(1, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Leading Span A/B <br />(Senkou A/B)</p>
          <input
            type="number"
            value={ichimokuVals[2]}
            onBlur={e => changeVals(2, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(2, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(2, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Lagging Span <br />(Chikou)</p>
          <input
            type="number"
            value={ichimokuVals[3]}
            onBlur={e => changeVals(3, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(3, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(3, parseInt(e.currentTarget.value))} />
        </label>
      </div>
    </Fragment>
  );
}

export default IchimokuSettings;