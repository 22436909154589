import React, { Fragment, useState } from 'react';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual } from 'lodash';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './ChartSettings.scss';

const StochRSISettings = props => {
  const { stochRsi, settings, setSettings } = props;
  const { indicators: { stochRsi: defaultStochRsi } } = defaultSettings;
  const [ stochRsiVals, setStochRsiVals ] = useState(stochRsi || defaultStochRsi);

  const changeVals = (index, newVal, checkVal) => {
    const workingVals = [...stochRsiVals];
    workingVals.splice(index, 1, checkVal ? checkValue(newVal, defaultStochRsi[index]) : newVal);

    setStochRsiVals(workingVals);

    if (checkVal && parseInt(stochRsiVals[index]) !== parseInt(stochRsi[index])) {
      const payload = { ...settings };
      payload.indicators.stochRsi = workingVals;
      setSettings(payload);
    }
  };

  return (
    <Fragment>
      <h4>
        Stochastic RSI

        <Button
          text="Defaults"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (!isEqual(stochRsiVals, defaultStochRsi)) {
              resetDefaults('stochRsi', settings, setSettings);
              setStochRsiVals(defaultStochRsi);
            }
          }} />
      </h4>

      <div className="stochRsiGroup">
        <label>
          <p className="labelTitle">Fast Length</p>
          <input
            type="number"
            value={stochRsiVals[0]}
            onBlur={e => changeVals(0, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(0, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(0, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Slow Length</p>
          <input
            type="number"
            value={stochRsiVals[1]}
            onBlur={e => changeVals(1, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(1, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(1, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">RSI Length</p>
          <input
            type="number"
            value={stochRsiVals[2]}
            onBlur={e => changeVals(2, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(2, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(2, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Stoch. Length</p>
          <input
            type="number"
            value={stochRsiVals[3]}
            onBlur={e => changeVals(3, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(3, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(3, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Overbought</p>
          <input
            type="number"
            value={stochRsiVals[4]}
            onBlur={e => changeVals(4, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(4, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(4, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Oversold</p>
          <input
            type="number"
            value={stochRsiVals[5]}
            onBlur={e => changeVals(5, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(5, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(5, parseInt(e.currentTarget.value))} />
        </label>
      </div>
    </Fragment>
  );
}

export default StochRSISettings;