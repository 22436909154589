/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { stochRSI } from 'helpers/utilityFunctions';
import * as d3 from 'd3';
import 'd3-selection-multi';
import './stochRsiGenerator.scss';

const generateStochRsi = (OHLC, chart, width, rsiLengths, yScale, pathGen, visibleRange) => {
  const marginOffset = 16;
  const stochData = new stochRSI(OHLC, rsiLengths[0], rsiLengths[1], rsiLengths[2]).calc();
  stochData.signal = stochData.signal.slice(stochData.signal.length - visibleRange);
  stochData.slow = stochData.slow.slice(stochData.slow.length - visibleRange);

  const rsiArea = chart.append('rect')
    .attrs({
      class: 'rsiArea',
      x: 0,
      y: yScale(rsiLengths[4]),
      width: width,
      height: yScale(100 - (rsiLengths[4] - rsiLengths[5])),
      transform: `translate(0, ${marginOffset})`
    })

  const overboughtLine = chart.append('line')
    .attrs({
      class: 'line-dashed',
      x1: 0,
      y1: yScale(rsiLengths[4]),
      x2: width,
      y2: yScale(rsiLengths[4]),
      transform: `translate(0, ${marginOffset})`
    })

  const oversoldLine = chart.append('line')
    .attrs({
      class: 'line-dashed',
      x1: 0,
      y1: yScale(rsiLengths[5]),
      x2: width,
      y2: yScale(rsiLengths[5]),
      transform: `translate(0, ${marginOffset})`
    })

  const secondLine = chart.append('path')
    .attrs({
      class: 'stochSecLine',
      d: pathGen(stochData.signal),
      transform: `translate(0, ${marginOffset})`
    })

  const mainLine = chart.append('path')
    .attrs({
      class: 'stochMainLine',
      d: pathGen(stochData.slow),
      transform: `translate(0, ${marginOffset})`
    })
};

export default generateStochRsi;