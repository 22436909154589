/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { paramsToObject } from 'helpers/paramHelpers';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import FieldWithAction from 'components/FieldWithAction/FieldWithAction';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import FieldRequirements from 'components/FieldRequirements/FieldRequirements';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './ResetPassword.scss';

let defaults = {
  userEmail: '',
  newPassword: '',
  confirmNewPassword: ''
};

let schema = yup.object().shape({
  userEmail: yup.string().email('Invalid email.').required('Email is required.').trim()
});

const ResetPassword = props => {
  const { state } = props;
  const [ loggedIn, setLoggedIn ] = useState(false);
  const [ newPassFields, setNewPassFields ] = useState(false);
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const params = paramsToObject(search);

  // Selectors
  const token = useSelector(state => appSelectors.token(state));

  // Actions
  const resetPassword = useCallback((payload, callback) => dispatch(appActions.resetPassword(payload, callback)));

  useEffect(() => {
    !!token && setLoggedIn(true);
  });

  useEffect(() => {
    const route = !isEmpty(state) ? state.from.pathname + state.from.search : '/charts';
    !!token && history.push(route);
  }, [loggedIn]);

  useEffect(() => {
    if (!newPassFields && params.req && params.ref) {
      schema = yup.object().shape({
        newPassword: yup.string().required('New password is required.').trim(),
        confirmNewPassword: yup.string().required('Confirm new password is required.')
          .oneOf([yup.ref('newPassword'), null], 'Passwords must match.').trim(),
      })

      setNewPassFields(true);
    }
  }, [search]);

  const handleSubmit = (values, resetForm, setSubmitting) => {
    const { userEmail, newPassword, confirmNewPassword } = values;

    const userInfo = {};

    if (!newPassFields) {
      userInfo.email = userEmail;
      resetPassword(userInfo, () => resetForm());
    } else {
      userInfo.id = { iv: params.req, encryptedData: params.ref };
      userInfo.password = newPassword;
      userInfo.confirmPassword = confirmNewPassword;
      resetPassword(userInfo, () => resetForm());
    };

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={schema}
      onSubmit={(values, {resetForm, setSubmitting}) => handleSubmit(values, resetForm, setSubmitting)}>
        {form => (
          <Form className="authForm">
            {!newPassFields && (
              <Fragment>
                <Field type="email" name="userEmail" placeholder="Email*" />
                <FormFeedback name="userEmail" />
              </Fragment>
            )}

            {newPassFields && (
              <Fragment>
                <FieldWithAction
                    form={form}
                    name="newPassword"
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="New password"
                    activeIcon={passwordVisible ? "eye" : "eyeClosed"}
                    inactiveIcon="eyeDisabled"
                    highlightField={true}
                    callback={() => setPasswordVisible(!passwordVisible)} />

                <FieldWithAction
                  form={form}
                  name="confirmNewPassword"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Confirm new password"
                  activeIcon={passwordVisible ? "eye" : "eyeClosed"}
                  inactiveIcon="eyeDisabled"
                  highlightField={true}
                  callback={() => setPasswordVisible(!passwordVisible)} />

                <FieldRequirements value={form.values.newPassword} />
              </Fragment>
            )}

            <Button
              type="submit"
              text="Reset password"
              textColor={colors.white}
              btnStyle="solid"
              disabled={form.isSubmitting} />

            <p className="loginOptions">
              <Link to={'/login'}>Log in</Link> or&nbsp;
              <Link to={'/create-account'}>Create an account</Link>.
            </p>
          </Form>
        )}
    </Formik>
  );
};

export default withRouter(ResetPassword);