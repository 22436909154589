/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from 'd3';
import 'd3-selection-multi';
import './candles.scss';

export const generateXAxis = (chart, height, xScale, format, timeframe) => {
  let xAxis;

  const timeInterval = () => {
    if (timeframe === '1m') return d3.timeMinute.every(5);
    if (timeframe === '5m') return d3.timeMinute.every(30);
    if (timeframe === '15m') return d3.timeHour.every(2);
    if (timeframe === '1H') return d3.timeHour.every(6);
    if (timeframe === '3H') return d3.timeHour.every(24);
    if (timeframe === '4H') return d3.timeHour.every(24);
    if (timeframe === '6H') return d3.timeDay.every(2);
    if (timeframe === '12H') return d3.timeDay.every(4);
    if (timeframe === '1D') return d3.timeDay.every(12);
    if (timeframe === '1W') return d3.timeWeek.every(8);
  };

  const getXFormat = () => {
    if (timeframe === '1W') {
      return d3.axisBottom(xScale)
      .tickFormat(date => {
        if (d3.timeYear(date) < date) return d3.timeFormat('%b')(date);
        return d3.timeFormat('%Y')(date);
      })
      .tickSize(4, 0);
    };

    return d3.axisBottom(xScale).tickSize(4, 0);
  };

  if (format === 'Mobile') {
    xAxis = d3.axisBottom(xScale)
      .ticks(timeInterval())
      .tickSize(4, 0);
  } else {
    xAxis = getXFormat();
  };

  const hAxis = chart.append("g")
    .attrs({
      class: 'timeAxis timeAxisDark',
      transform: `translate(0, ${height})`
    })
    .call(xAxis);
};

export const generateYAxis = (chart, decimalFormat, width, yScale, format) => {
  let yAxis;

  if (format === 'Mobile') {
    yAxis = d3.axisRight(yScale)
      .ticks(5)
      .tickSize(4, 0)
      .tickFormat(d3.format(decimalFormat));
  } else {
    yAxis = d3.axisRight(yScale)
      .tickSize(4, 0)
      .tickFormat(d3.format(decimalFormat));
  };

  const vAxis = chart.append("g")
    .attrs({
      class: 'vAxis',
      transform: `translate(${width + 16}, 16)`
    })
    .call(yAxis);
};

const candleShadows = (filter, shadows) => {
  if (shadows) {
    filter.append('feGaussianBlur')
      .attrs({
        in: 'SourceGraphic',
        stdDeviation: 1,
        result: 'blur'
      });

    filter.append('feOffset')
      .attrs({
        in: 'blur',
        dx: 0,
        dy: 1,
        result: 'offsetBlur'
      });

    filter.append('feColorMatrix')
      .attrs({
        in: 'offsetBlur',
        type: 'matrix',
        values: '0.015 0 0 0 0 0 0.015 0 0 0 0 0 0.015 0 0 0 0 0 1 0',
        result: 'matrixOut'
      })
  };
};

const generateCandles = (OHLC, chart, xScale, yScale, timestamps, shadows) => {
  const candleWicks = chart.append('g').selectAll('line')
    .data(OHLC.high)
    .enter()
    .append('line')
    .attrs({
      class: (d, i) => OHLC.close[i] > OHLC.open[i] ? 'candleWickBull' : 'candleWickBear',
      x1: (d, i) => xScale(timestamps[i]),
      y1: (d, i) => ((yScale(OHLC.low[i]) !== Infinity) && yScale(OHLC.low[i])) || null,
      x2: (d, i) => xScale(timestamps[i]),
      y2: d => ((yScale(d) !== Infinity) && yScale(d)) || null
    });

  candleWicks.exit();

  const candleBodies = chart.append('g').selectAll('line')
    .data(OHLC.close)
    .enter()
    .append('line')
    .attrs({
      class: (d, i) => d > OHLC.open[i] ? 'candleBodyBull' : 'candleBodyBear',
      x1: (d, i) => xScale(timestamps[i]),
      y1: (d, i) => yScale(OHLC.open[i]) !== Infinity ? yScale(OHLC.open[i]) : null,
      x2: (d, i) => xScale(timestamps[i]),
      y2: d => yScale(d) !== Infinity ? yScale(d) : null,
      // filter: 'url(#dropShadow)'
    });

  const defs = chart.append('defs')

  const filter = defs.append('filter')
    .attrs({
      id: 'dropShadow',
      height: '130%',
      width: '130%',
      filterUnits: 'userSpaceOnUse'
    });

  candleShadows(filter, shadows);

  const feMerge = filter.append('feMerge');

  feMerge.append('feMergeNode').attrs({ in: 'matrixOut' })
  feMerge.append('feMergeNode').attrs({ in: 'SourceGraphic' })
};

export default generateCandles;