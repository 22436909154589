/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './IndicatorToolbar.scss';

const IndicatorToolbar = props => {
  const {
    settings,
    maState,
    setMaState,
    emaRibbonState,
    setEmaRibbonState,
    ichimokuCloudState,
    setIchimokuCloudState,
    bollingerBandState,
    setBollingerBandState,
  } = props;
  const dispatch = useDispatch();

  // Actions
  const setSettings = payload => dispatch(appActions.setSettings(payload));

  const updateSettings = (keyName, value) => {
    const payload = { ...settings };
    payload.indicators[keyName] = value;
    setSettings(payload);
  };

  return (
    <div className="indicatorToolbar">
      <Button
        icon="movingAverages"
        iconColor={maState.active ? colors.white : colors.white}
        textWeight="600"
        bgColor={maState.active ? colors.grey : colors.black}
        btnStyle="solid"
        callback={() => {
          setMaState({ ...maState, active: !maState.active });
          updateSettings('movingAveragesActive', !maState.active);
        }} />

      <Button
        icon="emaRibbon"
        iconColor={emaRibbonState.active ? colors.white : colors.white}
        textWeight="600"
        bgColor={emaRibbonState.active ? colors.grey : colors.black}
        btnStyle="solid"
        callback={() => {
          setEmaRibbonState({ ...emaRibbonState, active: !emaRibbonState.active });
          updateSettings('emaRibbonActive', !emaRibbonState.active);
        }} />

      <Button
        icon="ichimokuCloud"
        iconColor={ichimokuCloudState.active ? colors.white : colors.white}
        textWeight="600"
        bgColor={ichimokuCloudState.active ? colors.grey : colors.black}
        btnStyle="solid"
        callback={() => {
          setIchimokuCloudState({ ...ichimokuCloudState, active: !ichimokuCloudState.active });
          updateSettings('ichimokuActive', !ichimokuCloudState.active);
        }} />

      <Button
        icon="bollingerBands"
        iconColor={bollingerBandState.active ? colors.white : colors.white}
        textWeight="600"
        bgColor={bollingerBandState.active ? colors.grey : colors.black}
        btnStyle="solid"
        callback={() => {
          setBollingerBandState({ ...bollingerBandState, active: !bollingerBandState.active });
          updateSettings('bBandsActive', !bollingerBandState.active);
        }} />
    </div>
  );
};

export default IndicatorToolbar;