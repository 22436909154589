import React, { Fragment } from 'react';
import AuthForm from 'components/AuthForm/AuthForm';
import './Login.scss';

const Login = props => {
  return (
    <Fragment>
      <div id="loginBody">
        <div className="loginTitle">
          <h2>Log in</h2>
        </div>

        <div className="loginForm">
          <AuthForm />
        </div>
      </div>
    </Fragment>
  )
}

export default Login;