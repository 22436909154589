/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { fromJS } from 'immutable';
import * as api from "./portfolioApi";

// Constants
const REORDER_PORTFOLIO = 'modules/portfolio/REORDER_PORTFOLIO';
const GET_PORTFOLIO = 'modules/portfolio/GET_PORTFOLIO';
const CLEAR_PORTFOLIO = 'modules/portfolio/CLEAR_PORTFOLIO';
const ADD_TO_PORTFOLIO = 'modules/portfolio/ADD_TO_PORTFOLIO';
const UPDATE_WALLET_BY_ID = 'modules/portfolio/UPDATE_WALLET_BY_ID';
const DELETE_WALLET_BY_ID = 'modules/portfolio/DELETE_WALLET_BY_ID';
const STATE_KEY_PORTFOLIO = 'portfolio';

export const portfolioConstants = {
  REORDER_PORTFOLIO,
  GET_PORTFOLIO,
  CLEAR_PORTFOLIO,
  ADD_TO_PORTFOLIO,
  UPDATE_WALLET_BY_ID,
  DELETE_WALLET_BY_ID,
  STATE_KEY_PORTFOLIO,
};

// Reducer
export const reducer = (initialState = {}, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case REORDER_PORTFOLIO:
      const reorderTarget = [STATE_KEY_PORTFOLIO];
      const reorderOriginal = state.getIn(reorderTarget);
      const reorderPortfolio = reorderOriginal.update(() => action.payload);
      const reorderFinal = state.setIn(reorderTarget, reorderPortfolio).toJS();

      return reorderFinal;
    case GET_PORTFOLIO:
      return state.set(STATE_KEY_PORTFOLIO, action.payload).toJS();
    case CLEAR_PORTFOLIO:
      return state.set(STATE_KEY_PORTFOLIO, action.payload).toJS();
    case ADD_TO_PORTFOLIO:
      return state.set(STATE_KEY_PORTFOLIO, action.payload).toJS();
    case UPDATE_WALLET_BY_ID:
      return state.set(STATE_KEY_PORTFOLIO, action.payload).toJS();
    case DELETE_WALLET_BY_ID:
      return state.set(STATE_KEY_PORTFOLIO, action.payload).toJS();

    default:
      return initialState;
  }
}

// Actions
export const portfolioActions = {
  reorderPortfolio: payload => api.reorderPortfolio(payload, REORDER_PORTFOLIO),
  clearPortfolio: () => ({ type: CLEAR_PORTFOLIO, payload: [] }),
  getPortfolio: () => api.getPortfolio(GET_PORTFOLIO),
  addToPortfolio: (payload, callback) => api.addToPortfolio(payload, ADD_TO_PORTFOLIO, callback),
  updateWalletById: (payload, callback) => (
    api.updateWalletById(payload, UPDATE_WALLET_BY_ID, callback)
  ),
  deleteWalletById: (payload, callback) => (
    api.deleteWalletById(payload, DELETE_WALLET_BY_ID, callback)
  ),
};

// Selectors
export const portfolioSelectors = {
  wallets: state => state.modules.portfolio[STATE_KEY_PORTFOLIO],
};

export default reducer;