/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useCallback } from 'react';
import { defaultModal } from 'helpers/modals';
import { useDispatch } from 'react-redux';
import { portfolioActions } from 'modules/portfolio/portfolioDuck';
import Button from 'components/Button/Button';
import * as colors from 'theme/colors.scss';
import './DeleteWalletConfirm.scss';

const DeleteWalletConfirm = props => {
  const { wallet, setModalState } = props;
  const { id, name, address, chainType, tag } = wallet;

  const dispatch = useDispatch();

  // Actions
  const deleteWalletById = useCallback((payload, callback) => dispatch(portfolioActions.deleteWalletById(payload, callback)), [dispatch]);

  return (
    <Fragment>
      <div className="deleteConfirm">
        <h2>{name}</h2>
        <p className="walletInfo address">{address}</p>
        {tag && (
          <p className="walletInfo">
            <strong>{chainType === 'XRP' ? 'Destination Tag': 'Memo'}</strong>: {tag}
          </p>
        )}
        <Button
          icon="warning"
          iconColor={colors.red}
          text={`Remove ${chainType} wallet`}
          textColor={colors.white}
          bgColor={colors.darkGrey2}
          callback={() => deleteWalletById({ id }, setModalState(defaultModal))} />
      </div>
    </Fragment>
  );
};

export default DeleteWalletConfirm;