/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment } from 'react';
import BotSettings from './BotSettings';
import ExchangeSettings from './ExchangeSettings';
import './TradeSettings.scss';
import 'theme/settings.scss';

const TradeSettings = props => {
  const { asset, pair, orders, format } = props;

  return (
    <Fragment>
      {format !== 'Mobile' && <h2>Settings</h2>}

      {/* <BotSettings
        settings={settings}
        setSettings={setSettings} /> */}

      <ExchangeSettings asset={asset} pair={pair} orders={orders} />
    </Fragment>
  );
};

export default TradeSettings;