/* eslint-disable no-unused-vars */

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import makeStore from "./store";
import App from "./scenes/app/App";
import * as serviceWorker from './serviceWorker';
import './index.scss';

// import mirage from './mirage';

process.env.NODE_ENV === 'production' && Sentry.init({
  dsn: "https://326a144c80bc48da8ba2622619b1b75a@sentry.io/1872819"
});

const MyApp = (
  <Provider store={makeStore()}>
    <App />
  </Provider>
);

const target = document.getElementById('root');

// Main App
ReactDOM.render(MyApp, target);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();