import React from 'react';
import { Field } from 'formik';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import './Checkbox.scss';

/* Validate with parent yup schema:
*  const schema = yup.object().shape({
*    [fieldName]: yup.boolean().oneOf([true], 'Required message.')
*  });
*/

const Checkbox = props => {
  const { name, label, className } = props;

  return (
    <label className={`checkContainer ${className}`}>
      {label}

      <Field type="checkbox" name={name} />

      <span className="customCheckbox"></span>
      <FormFeedback name={name} />
    </label>
  );
};

export default Checkbox;