import React, { Fragment, useState } from 'react';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual } from 'lodash';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './ChartSettings.scss';

const EMARibbonSettings = props => {
  const { emaRibbon, settings, setSettings } = props;
  const { indicators: { emaRibbon: defaultEmaRibbon } } = defaultSettings;
  const [ emaVals, setEmaVals ] = useState(emaRibbon || defaultEmaRibbon);

  const changeVals = (index, newVal, checkVal) => {
    const workingVals = [...emaVals];
    workingVals.splice(index, 1, checkVal ? checkValue(newVal, defaultEmaRibbon[index]) : newVal);

    setEmaVals(workingVals);

    if (checkVal && parseInt(emaVals[index]) !== parseInt(emaRibbon[index])) {
      const payload = { ...settings };
      payload.indicators.emaRibbon = workingVals;
      setSettings(payload);
    }
  };

  return (
    <Fragment>
      <h4>
        EMA Ribbon

        <Button
          text="Defaults"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (!isEqual(emaVals, defaultEmaRibbon)) {
              resetDefaults('emaRibbon', settings, setSettings);
              setEmaVals(defaultEmaRibbon);
            }
          }} />
      </h4>

      <p className="labelTitle">EMA Lengths</p>
      <div className="emaGroup">
        {emaRibbon.map((avg, index) => (
          <label key={index}>
            <input
            type="number"
            value={emaVals[index]}
            onBlur={e => changeVals(index, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(index, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(index, parseInt(e.currentTarget.value))} />
          </label>
        ))}
      </div>
    </Fragment>
  );
}

export default EMARibbonSettings;