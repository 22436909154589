/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { fromJS } from 'immutable';
import * as api from "./chartsApi";

// Constants
const GET_ALL_COINS = 'modules/charts/GET_ALL_COINS';
const GET_ASSET_DATA = 'modules/charts/GET_ASSET_DATA';
const GET_EXCHANGE_PAIRS = 'modules/charts/GET_EXCHANGE_PAIRS';
const GET_FAVORITES = 'modules/charts/GET_FAVORITES';
const CLEAR_FAVORITES = 'modules/charts/CLEAR_FAVORITES';
const ADD_TO_FAVORITES = 'modules/charts/ADD_TO_FAVORITES';
const DELETE_FAVORITES_BY_ID = 'modules/charts/DELETE_FAVORITES_BY_ID';
const STATE_KEY_FAVORITES = 'favorites';
const STATE_KEY_ASSET_DATA = 'assetData';
const STATE_KEY_EXCHANGE_PAIRS = 'exchangePairs';
const STATE_KEY_ALL_COINS = 'allCoins';

export const chartConstants = {
  GET_ALL_COINS,
  GET_ASSET_DATA,
  GET_EXCHANGE_PAIRS,
  GET_FAVORITES,
  CLEAR_FAVORITES,
  ADD_TO_FAVORITES,
  DELETE_FAVORITES_BY_ID,
  STATE_KEY_FAVORITES,
  STATE_KEY_ASSET_DATA,
  STATE_KEY_ALL_COINS,
};

// Reducer
export const reducer = (initialState = {}, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case GET_ALL_COINS:
      const coinData = action.payload.Data;
      const coinPayload = Object.entries(coinData).map(([symbol, info]) => ({symbol, info}));
      return state.set(STATE_KEY_ALL_COINS, coinPayload).toJS();
    case GET_ASSET_DATA:
      return state.set(STATE_KEY_ASSET_DATA, action.payload.Data.Data).toJS();
    case GET_EXCHANGE_PAIRS:
      const exchangeData = action.payload.Data.exchanges;
      const exchangePayload = Object.entries(exchangeData).map(([name, {pairs}]) => ({name, pairs}));
      return state.set(STATE_KEY_EXCHANGE_PAIRS, exchangePayload).toJS();
    case GET_FAVORITES:
      return state.set(STATE_KEY_FAVORITES, action.payload).toJS();
    case CLEAR_FAVORITES:
        return state.set(STATE_KEY_FAVORITES, action.payload).toJS();
    case ADD_TO_FAVORITES:
      return state.set(STATE_KEY_FAVORITES, action.payload).toJS();
    case DELETE_FAVORITES_BY_ID:
      return state.set(STATE_KEY_FAVORITES, action.payload).toJS();

    default:
      return initialState;
  }
}

// Actions
export const chartsActions = {
  getAllCoins: () => api.getAllCoins(GET_ALL_COINS),
  getAssetData: (asset, pair, timeframe, source) => api.getAssetData(asset, pair, timeframe, source, GET_ASSET_DATA),
  getExchangePairs: () => api.getExchangePairs(GET_EXCHANGE_PAIRS),
  getFavorites: () => api.getFavorites(GET_FAVORITES),
  clearFavorites: () => ({ type: CLEAR_FAVORITES, payload: [] }),
  addToFavorites: payload => api.addToFavorites(payload, ADD_TO_FAVORITES),
  deleteFavoriteById: payload => api.deleteFavoriteById(payload, DELETE_FAVORITES_BY_ID),
};

// Selectors
export const chartsSelectors = {
  allCoins: state => state.modules.charts[STATE_KEY_ALL_COINS],
  assetData: state => state.modules.charts[STATE_KEY_ASSET_DATA],
  exchangePairs: state => state.modules.charts[STATE_KEY_EXCHANGE_PAIRS],
  favorites: state => state.modules.charts[STATE_KEY_FAVORITES],
};

export default reducer;