/* eslint-disable no-unused-vars */

import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { streamingSelectors } from 'modules/streaming/streamingDuck';
import { isEmpty } from 'lodash';
import { formatWholeNum } from 'helpers/utilityFunctions';
import colors from 'theme/colors.scss';

const PriceDelta = props => {
  const { open, asset, pair, styles } = props;
  const defaultStyle = { opacity: 0.6, ...styles };

  // Selectors
  const streamingData = useSelector(state => streamingSelectors.streamingData(state));

  const buildDelta = () => {
    if (!isEmpty(streamingData) && open && pair) {
      const workingData = streamingData.find(data => data.asset === asset && data.pair === pair);
      const numOpen = parseFloat(open.replace(',', ''));

      if (workingData) {
        const deltaRaw = ((workingData.close || parseFloat(workingData.price)) / numOpen * 100 - 100).toFixed(2);
        const delta = deltaRaw.includes('-') ? deltaRaw.replace('-', '') : deltaRaw;
        const deltaStyle = {
          color: deltaRaw >= 0 ? colors.white : colors.yellow,
        };

        return (
          <span className="delta" style={{ ...defaultStyle, ...deltaStyle }}>
            {(deltaRaw > 0 && '+') || (deltaRaw < 0 && '-')}
            {formatWholeNum(delta)}%
          </span>
        );
      };
    };

    if (asset === pair) {
      return (
        <span className="delta" style={{ ...defaultStyle, color: colors.white }}>
          0.00%
        </span>
      );
    }
  };

  return (
    <span className="priceDelta">
      {streamingData && buildDelta()}
    </span>
  );
};

export default PriceDelta;