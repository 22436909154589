/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import UpdateAccount from './UpdateAccount/UpdateAccount';
import DeleteAccountConfirm from './DeleteAccountConfirm/DeleteAccountConfirm';
import AuthForm from 'components/AuthForm/AuthForm';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './Account.scss';
import moment from 'moment';

const Account = props => {
  const { userInfo, authed, logout } = props;
  const { accountLevel, dateCreated, lastSession } = userInfo;
  const { level, status } = accountLevel;
  const [ modalState, setModalState ] = useState(defaultModal);
  const dispatch = useDispatch();

  // Selectors

  // Actions
  const deleteAccount = payload => dispatch(appActions.deleteAccount(payload));

  const deleteMessage = () => {
    if (level === 2) {
      // If Subscriber
      return (
        <Fragment>
          <p>Unable to delete account: Subscription is active.</p>
          <p>Account will downgrade to "Basic" on expiration.</p>
        </Fragment>
      );
    };

    if (level === 3) {
      // If Founder
      return (
        <Fragment>
          <p>Founder benefits will be lost!</p>
          <p>Account recovery will <strong>not</strong> be possible!</p>
          <p>You will have one final confirmation after clicking below.</p>
        </Fragment>
      );
    };

    if (level > 3) {
      // If Moderator, Admin or Super
      return (
        <Fragment>
          <p>Unable to delete account: You are {level === 5 ? 'an' : 'a'} {status}.</p>
          <p>Request removal/downgrade from another Admin.</p>
        </Fragment>
      );
    };

    // Disabled or Basic account types
    return (
      <Fragment>
        <p>Account recovery will <strong>not</strong> be possible!</p>
        <p>You will have one final confirmation after clicking below.</p>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div id="accountBody">
        <div className="accountTitle">
          <h2>Account</h2>
        </div>

        <div className="accountDetails">
          <UpdateAccount userInfo={userInfo} />
        </div>

        <div className="delete">
          <h2 className="warning">
            <span>WARNING!</span> &nbsp;This is irreversible!
          </h2>

          <div className="disclaimer">
            <p className="strong">The following will be removed from our servers, immediately:</p>
            <ul>
              <li>Chart settings</li>
              <li>Portfolio data</li>
              <li>Application state</li>
              <li>User preferences</li>
              <li>User favorites</li>
              <li>Purchased items</li>
              <li>Purchase history</li>
              <li>Log-in credentials</li>
            </ul>
          </div>

          <div className="deleteAction">
            {deleteMessage()}

            <Button
              icon="delete"
              iconColor={colors.red}
              text={level === 2 || level > 3 ? "Delete currently unavailable" : "I understand, delete my account"}
              textColor={colors.red}
              disabled={level === 2 || level > 3}
              btnStyle="transparent"
              callback={() => openModal(
                setModalState, 'deleteConfirmModal', 'Delete Account',
                <DeleteAccountConfirm deleteAccount={deleteAccount} />
              )} />
          </div>
        </div>
      </div>

      {showModal(modalState, setModalState)}
    </Fragment>
  )
}

export default Account;