import React, { Fragment } from 'react';
import AuthForm from 'components/AuthForm/AuthForm';
import './Login.scss';

const ResetPassword = props => {
  return (
    <Fragment>
      <div id="loginBody">
        <div className="loginTitle">
          <h2>Reset Password</h2>
        </div>

        <div className="loginForm">
          <AuthForm />
        </div>
      </div>
    </Fragment>
  )
}

export default ResetPassword;