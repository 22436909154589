/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { formatWholeNum } from 'helpers/utilityFunctions';
import Price from 'components/Price/Price';
import PriceDelta from 'components/PriceDelta/PriceDelta';
import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './TradeDetails.scss';

const TradeDetails = props => {
  const {
    asset,
    assets,
    pair,
    products,
    source,
    timeframe,
    assetData,
    myParams,
    changeTime,
    changeSource,
    changeAsset,
    timeframes,
    sources,
  } = props;

  const { pathname } = useLocation();

  const filteredProducts = products && products.filter(product => product.quote_currency === pair);
  const current = assetData && assetData[assetData.length - 1];
  const OHLC = assetData && {
    open: formatWholeNum(pair === 'BTC' ? current.open.toFixed(8).toString() : current.open.toString()),
    high: formatWholeNum(pair === 'BTC' ? current.high.toFixed(8).toString() : current.high.toString()),
    low: formatWholeNum(pair === 'BTC' ? current.low.toFixed(8).toString() : current.low.toString()),
    close: formatWholeNum(pair === 'BTC' ? current.close.toFixed(8).toString() : current.close.toString()),
  };

  const [ workingHL, setWorkingHL ] = useState({});

  useEffect(() => {
    if (OHLC) {
      setWorkingHL({
        high: parseFloat(OHLC.high.replace(',', '')),
        low: parseFloat(OHLC.low.replace(',', ''))
      });
    };
  }, [assetData]);

  return (
    <Fragment>
      <div id="tradeDetails">
        <div className="detailsGroup">
          <div className="tradeAsset">
            {myParams.asset}-{myParams.pair}

            <div className="tradePrice">
              {assetData && (
                <Fragment>
                  <Price
                    type="close"
                    asset={myParams.asset}
                    pair={pair}
                    open={OHLC.open}
                    useColor={true}
                    useSymbol={true} />&nbsp;

                  <PriceDelta open={OHLC.open} asset={myParams.asset} pair={pair} />
                </Fragment>
              )}
            </div>
          </div>

          <div className="tradeOHLC">
            {assetData && (
              <div className="ohlcContainer">
                <ul>
                  <li>
                    <p>O&nbsp;<span>{OHLC.open || ' -- '}</span></p>
                    <p>H&nbsp;
                      <Price
                        type="high"
                        basePrice={workingHL && workingHL.high}
                        setNewVal={(type, newVal) => setWorkingHL({...workingHL, [type]: newVal})}
                        asset={myParams.asset}
                        pair={pair} />
                    </p>
                  </li>

                  <li>
                    <p>L&nbsp;
                      <Price
                        type="low"
                        basePrice={workingHL && workingHL.low}
                        setNewVal={(type, newVal) => setWorkingHL({...workingHL, [type]: newVal})}
                        asset={myParams.asset}
                        pair={pair} />
                    </p>
                    <p>C&nbsp;<Price type="close" asset={myParams.asset} pair={pair} /></p>
                  </li>

                </ul>

                <ul>
                  <li>
                    <p>24H Volume:&nbsp;<Price type="volume" asset={myParams.asset} pair={pair} /></p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="additionalOptions">
          <label className="assetSelect" htmlFor="assetSelect">
            <select
              name="assetSelect"
              className="assetList"
              value={asset}
              onChange={e => changeAsset(e.currentTarget.value)}>
                {filteredProducts && filteredProducts.map(product => (
                  <option key={product.id} value={product.base_currency}>
                    {product.base_currency}
                  </option>
                ))}
            </select>

            <i className="fas fa-caret-left customArrow" />
          </label>

          <label className="timeframeSelect" htmlFor="timeframeSelect">
            <select
              name="timeframeSelect"
              className="timeframe"
              value={timeframe}
              onChange={e => changeTime(e.currentTarget.value)}>
              {timeframes.map(time => <option key={time} value={time}>{time}</option>)}
            </select>

            <i className="fas fa-caret-left customArrow" />
          </label>
        </div>
      </div>
    </Fragment>
  );
};

export default TradeDetails;