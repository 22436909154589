/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { apiActions } from 'modules/api/apiDuck';

const {
  REACT_APP_DATA_KEY: dataKey,
  REACT_APP_NAME: appName
} = process.env;

export const getArticles = (callback, type) => {
  return apiActions.request({
    path: `v2/news/?extraParams=${appName}`,
    pathType: 'data',
    apiKey: dataKey,
    method: 'GET',
    type: type,
    callback: callback
  });
};