import React, { Fragment, useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import MarketOrder from './MarketOrder';
import LimitOrder from './LimitOrder';
import StopOrder from './StopOrder';
import './BuySell.scss';

const orderTypes = ['Market', 'Limit', 'Stop'];

const BuySell = props => {
  const { mode, product, balances } = props;
  const [ activeOrderType, setActiveOrderType ] = useState(orderTypes[1])

  return (
    <Fragment>
      <Tabs
        tabs={orderTypes}
        activeTab={activeOrderType}
        onChange={newVal => setActiveOrderType(newVal)} />

      {activeOrderType === orderTypes[0] && (
        <MarketOrder
          mode={mode}
          product={product}
          balances={balances} />
      )}

      {activeOrderType === orderTypes[1] && (
        <LimitOrder
          mode={mode}
          product={product}
          balances={balances} />
      )}

      {activeOrderType === orderTypes[2] && (
        <StopOrder
          mode={mode}
          product={product}
          balances={balances} />
      )}
    </Fragment>
  );
};

export default BuySell;