import React, { Fragment, useState } from 'react';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual } from 'lodash';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './ChartSettings.scss';

const BBandsSettings = props => {
  const { bBands, settings, setSettings } = props;
  const { indicators: { bBands: defaultBBands } } = defaultSettings;
  const [ bBandVals, setBBandVals ] = useState(bBands || defaultBBands);

  const changeVals = (index, newVal, checkVal) => {
    const workingVals = [...bBandVals];
    workingVals.splice(index, 1, checkVal ? checkValue(newVal, defaultBBands[index]) : newVal);

    setBBandVals(workingVals);

    if (checkVal && parseInt(bBandVals[index]) !== parseInt(bBands[index])) {
      const payload = { ...settings };
      payload.indicators.bBands = workingVals;
      setSettings(payload);
    }
  };

  return (
    <Fragment>
      <h4>
        Bollinger Bands

        <Button
          text="Defaults"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (!isEqual(bBandVals, defaultBBands)) {
              resetDefaults('bBands', settings, setSettings);
              setBBandVals(defaultBBands);
            }
          }} />
      </h4>

      <div className="bBandsGroup">
        <label>
          <p className="labelTitle">SMA Length</p>
          <input
            type="number"
            value={bBandVals[0]}
            onBlur={e => changeVals(0, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(0, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(0, parseInt(e.currentTarget.value))} />
        </label>

        <label>
          <p className="labelTitle">Standard Deviation</p>
          <input
            type="number"
            value={bBandVals[1]}
            onBlur={e => changeVals(1, parseInt(e.currentTarget.value), true)}
            onKeyPress={e => e.key === 'Enter' && changeVals(1, parseInt(e.currentTarget.value), true)}
            onChange={e => changeVals(1, parseInt(e.currentTarget.value))} />
        </label>
      </div>
    </Fragment>
  );
}

export default BBandsSettings;