/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { streamingSelectors } from 'modules/streaming/streamingDuck';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { countDecimals } from 'helpers/utilityFunctions';
import IndicatorToolbar from './indicators/IndicatorToolbar';
import generateCandles, { generateXAxis, generateYAxis } from './indicators/candles';
import generateVolume from './indicators/volume';
import generateBollingerBands from './indicators/bollingerBands';
import generateEmaRibbon from './indicators/emaRibbon';
import generateMovingAverages from './indicators/movingAverages';
import generateIchimokuCloud from './indicators/ichimokuCloud';
import * as d3 from 'd3';
import 'd3-selection-multi';
import './CandleChart.scss';

const Candles = props => {
  const { format, data, pair, settings, timeframe } = props;
  const { general, indicators } = settings;
  const { useLog } = general;
  const { volActive, volMaActive, movingAveragesActive, emaRibbonActive, bBandsActive, ichimokuActive } = indicators;
  const { volMa, ichimoku, movingAverages, emaRibbon, bBands } = indicators;
  const [ volumeState, setVolumeState ] = useState({ active: volActive || true, maActive: volMaActive || true });
  const [ bollingerBandState, setBollingerBandState ] = useState({ active: bBandsActive || false, range: [] });
  const [ emaRibbonState, setEmaRibbonState ] = useState({ active: emaRibbonActive || false, range: [] });
  const [ ichimokuCloudState, setIchimokuCloudState ] = useState({ active: ichimokuActive || false, range: [] });
  const [ maState, setMaState ] = useState({ active: movingAveragesActive || false, range: [] });
  const [ shadows, setShadows ] = useState(true);
  const [ chartSize, setChartSize ] = useState({});
  const chartRef = useRef();
  const containerRef = useRef();

  // Selectors
  const streamingData = useSelector(state => streamingSelectors.streamingData(state));

  useEffect(() => {
    if (chartRef.current && containerRef.current) {
      const container = containerRef.current;
      const width = container.clientWidth;
      const height = container.clientHeight;

      setChartSize({ width,  height });
    };
  }, [chartRef, containerRef]);

  useEffect(() => {
    setChartSize({ ...chartSize, height: format === 'Desktop' ? 395 : 240 });
  }, [format]);

  const getOHLC = () => {
    const yAxisWidth = 73 + 14;
    const candleWidth = 12.979591837;
    const clippedWidth = containerRef.current.clientWidth - yAxisWidth;
    const visibleRange = Math.floor(clippedWidth / candleWidth);
    const rawOHLC = {
      time: data.map(dataPoint => moment.unix(dataPoint.time).utc()._d),
      high: data.map(dataPoint => dataPoint.high),
      low: data.map(dataPoint => dataPoint.low),
      open: data.map(dataPoint => dataPoint.open),
      close: data.map(dataPoint => dataPoint.close),
      volume: data.map(dataPoint => dataPoint.volumeto),
    };

    const clippedOHLC = {
      time: data.map(dataPoint => moment.unix(dataPoint.time).utc()._d).slice(data.length - visibleRange),
      high: data.map(dataPoint => dataPoint.high).slice(data.length - visibleRange),
      low: data.map(dataPoint => dataPoint.low).slice(data.length - visibleRange),
      open: data.map(dataPoint => dataPoint.open).slice(data.length - visibleRange),
      close: data.map(dataPoint => dataPoint.close).slice(data.length - visibleRange),
      volume: data.map(dataPoint => dataPoint.volumeto).slice(data.length - visibleRange),
    };

    // Overide last historical HLC values with streaming data.
    if (streamingData) {
      const workingData = streamingData.find(item => item.pair === pair);

      if (workingData) {
        const updateClose = () => {
          const newData = clippedOHLC.close;
          const lastVal = newData[newData.length - 1];
          workingData.close !== lastVal && newData.splice(newData.length - 1, 1, workingData.close);
          clippedOHLC.close = newData;
        };

        updateClose();
      };
    };

    return { rawOHLC, clippedOHLC, visibleRange };
  };

  const minMaxAllIndicators = () => {
    const { active: bollingerBandActive, range: bollingerBandRange } = bollingerBandState;
    const { active: ichimokuCloudActive, range: ichimokuCloudRange } = ichimokuCloudState;
    const { active: emaRibbonActive, range: emaRibbonRange } = emaRibbonState;
    const { active: movingAveragesActive, range: movingAveragesRange } = maState;

    const minMaxAll = [];

    if (bollingerBandActive)
      minMaxAll.push({ min: d3.min(bollingerBandRange), max: d3.max(bollingerBandRange) });

    if (ichimokuCloudActive)
     minMaxAll.push({ min: d3.min(ichimokuCloudRange), max: d3.max(ichimokuCloudRange) });

    if (emaRibbonActive)
      minMaxAll.push({ min: d3.min(emaRibbonRange), max: d3.max(emaRibbonRange) });

    if (movingAveragesActive)
      minMaxAll.push({ min: d3.min(movingAveragesRange), max: d3.max(movingAveragesRange) });

    const min = minMaxAll.map(indicator => indicator.min && indicator.min);
    const max = minMaxAll.map(indicator => indicator.max && indicator.max);

    return { min: d3.min(min), max: d3.max(max) };
  };

  const buildChart = () => {
    const { height: chartHeight } = chartSize;
    const containerWidth = containerRef.current.clientWidth;
    const target = chartRef.current;
    const ohlcVals = getOHLC();
    const { rawOHLC, clippedOHLC: OHLC, visibleRange } = ohlcVals;
    const timestamps = OHLC.time;
    const margin = { top: 16, right: 96, bottom: 20, left: 0 };
    const height = chartHeight - margin.top - margin.bottom - 14;
    const width = containerWidth - margin.left - margin.right;
    const allMinMax = minMaxAllIndicators();
    const minMax = [
      allMinMax.min < d3.min(OHLC.low) ? allMinMax.min : d3.min(OHLC.low),
      allMinMax.max > d3.max(OHLC.high) ? allMinMax.max : d3.max(OHLC.high)
    ];
    const yDomain = [
      minMax[0] < d3.min(OHLC.low) ? minMax[0] : d3.min(OHLC.low),
      minMax[1] > d3.max(OHLC.high) ? minMax[1] : d3.max(OHLC.high)
    ];
    const yRange = [height, 0];
    const decimalFormat = pair === 'BTC' ? ',.8f' : `,.${countDecimals(d3.min(OHLC.low).toString())}f`;

    if (target) target.innerHTML = '';

    const xScale = d3.scaleTime()
      .domain(d3.extent(timestamps, d => d))
      .range([0, width]);

    const yScale = useLog ?
      d3.scaleSymlog()
        .domain(yDomain)
        .range(yRange)
      :
      d3.scaleLinear()
        .domain(yDomain).nice()
        .range(yRange);

    const pathGen = d3.line()
      .defined((d, i) => d)
      .x((d, i) => xScale(timestamps[i]))
      .y(d => yScale(d))
      .curve(d3.curveMonotoneX); // d3.curveMonotoneX or d3.curveLinear

    const chart = d3.select(target).append('svg')
      .attrs({
        class: 'candleChartContainer',
        height: height + margin.top + margin.bottom,
        width: width + margin.left + margin.right,
      })
      .append('g');

    volActive && generateVolume(OHLC, rawOHLC, visibleRange, format, volMaActive, volMa, containerWidth, target, chart, height, width, margin, xScale, timestamps, volumeState);
    generateYAxis(chart, decimalFormat, (target.clientWidth - margin.left - margin.right), yScale, format);

    const candles = chart.append('g')
      .attrs({
        class: 'candleSvg',
        transform: `translate(${target.clientWidth - containerWidth}, 0)`,
      })
      .append('g')
      .attrs({ transform: `translate(${margin.left}, ${margin.top})` })

    generateXAxis(candles, height, xScale, format, timeframe);
    generateBollingerBands(rawOHLC, visibleRange, bBands, candles, xScale, yScale, pathGen, timestamps, bollingerBandState, setBollingerBandState);
    generateIchimokuCloud(rawOHLC, visibleRange, ichimoku, candles, xScale, yScale, pathGen, timestamps, ichimokuCloudState, setIchimokuCloudState);
    generateEmaRibbon(rawOHLC, visibleRange, emaRibbon, candles, pathGen, emaRibbonState, setEmaRibbonState);
    generateMovingAverages(rawOHLC, visibleRange, movingAverages, candles, pathGen, maState, setMaState);
    generateCandles(OHLC, candles, xScale, yScale, timestamps, shadows);
  };

  return (
    <Fragment>
       <IndicatorToolbar
        settings={settings}
        maState={maState}
        setMaState={setMaState}
        emaRibbonState={emaRibbonState}
        setEmaRibbonState={setEmaRibbonState}
        ichimokuCloudState={ichimokuCloudState}
        setIchimokuCloudState={setIchimokuCloudState}
        bollingerBandState={bollingerBandState}
        setBollingerBandState={setBollingerBandState} />

      <div ref={containerRef} className="candleChartContainer" >
        <div ref={chartRef}
          width={chartSize.width}
          height={chartSize.height}
          style={{ overflow: 'hidden' }} />
        {(data && !isEmpty(chartSize)) && buildChart()}
      </div>
    </Fragment>
  );
};

export default Candles;