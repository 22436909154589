/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import FieldRequirements from 'components/FieldRequirements/FieldRequirements';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import FieldWithAction from 'components/FieldWithAction/FieldWithAction';
import TermsOfService from 'components/AuthForm/CreateAccount/TermsOfService';
import PrivacyPolicy from 'components/AuthForm/CreateAccount/PrivacyPolicy';
import colors from 'theme/colors.scss';
import './UpdateAccount.scss';

const schema = yup.object().shape({
  newEmail: yup.string().email('Invalid email.').trim(),
  newPassword: yup.string().trim(),
  confirmNewPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match.').trim(),
});

const UpdateAccount = props => {
  const { userInfo: details } = props;
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const [ modalState, setModalState ] = useState(defaultModal);
  const dispatch = useDispatch();

  // Selectors

  // Actions
  const updateAccount = useCallback((userInfo, callback) =>
    dispatch(appActions.updateAccount(userInfo, callback)), [dispatch]);

  const defaults = {
    newEmail: details.user || '',
    newPassword: '',
    confirmNewPassword: '',
    trustedDevice: false,
  };

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const { newEmail, newPassword, confirmNewPassword, trustedDevice } = values;

    const userInfo = {};

    if (newEmail !== '' && newEmail !== details.user) userInfo.email = newEmail.toLowerCase();
    if (newPassword !== '') userInfo.password = newPassword;
    if (confirmNewPassword !== '') userInfo.confirmPassword = confirmNewPassword;
    if (trustedDevice) userInfo.trustedDevice = trustedDevice;

    if (!isEmpty(userInfo)) {
      updateAccount(userInfo, () => {
        resetForm();
        setSubmitting(false);
      });
    } else {
      setSubmitting(false);
    }
  };

  const validateConfirm = (confirmNewPassword, newPassword) => {
    const errorMsg = 'Confirm new password is required.';
    if (newPassword !== '' && confirmNewPassword === '') return errorMsg;
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={(values, {setSubmitting, resetForm}) => handleSubmit(values, setSubmitting, resetForm)}>
          {form => (
            <Form className="updateAccountForm">
              <div className="updateAccount">
                <div className="memberDetails">
                  <h3>
                    <strong>Account status</strong>: &nbsp;
                    <span>{details.accountLevel.status}</span>
                  </h3>

                  <h3>
                    <strong>Member since</strong>: &nbsp;
                    <span>{moment(details.dateCreated).local().format('ll')}</span>
                  </h3>
                </div>

                <p className="footnote">
                  Update your email, password or both.
                </p>

                <div className="updateFields">
                  <label className="newEmail" htmlFor="newEmail">
                    <Field type="text" name="newEmail" placeholder="New email" />
                    <FormFeedback name="newEmail" />
                  </label>

                  <FieldWithAction
                    form={form}
                    name="newPassword"
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="New password"
                    activeIcon={passwordVisible ? "eye" : "eyeClosed"}
                    inactiveIcon="eyeDisabled"
                    highlightField={true}
                    callback={() => setPasswordVisible(!passwordVisible)} />

                  <FieldWithAction
                    form={form}
                    name="confirmNewPassword"
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Confirm new password"
                    activeIcon={passwordVisible ? "eye" : "eyeClosed"}
                    inactiveIcon="eyeDisabled"
                    highlightField={true}
                    callback={() => setPasswordVisible(!passwordVisible)}
                    validate={() => validateConfirm(
                      form.values.confirmNewPassword.trim(), form.values.newPassword.trim()
                    )} />
                </div>

                <FieldRequirements value={form.values.newPassword} />

                <div className="submitBtn">
                  <Button
                    type="submit"
                    icon="save"
                    iconColor={colors.white}
                    text="Save new credentials"
                    textColor={colors.white}
                    bgColor={colors.darkGrey2}
                    disabled={form.isSubmitting} />
                </div>

                <Checkbox
                  name="trustedDevice"
                  className="trust"
                  label="Trust this device for 30 more days." />

                <p className="legalLinks">
                  <Link to="#" onClick={() => openModal(
                    setModalState, null, 'Terms of Service', <TermsOfService />)}>
                      terms of service
                  </Link>&nbsp;&amp;&nbsp;

                  <Link to="#" onClick={() => openModal(
                    setModalState, null, 'Privacy Policy', <PrivacyPolicy />)}>
                      privacy policy
                  </Link>.
                </p>
              </div>
            </Form>
          )}
      </Formik>

      {showModal(modalState, setModalState)}
    </Fragment>
  );
};

export default UpdateAccount;