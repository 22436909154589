/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState } from 'react';
import { isEmpty } from 'lodash';
import Pagination from 'components/Pagination/Pagination';
import { makePageable } from 'helpers/utilityFunctions';
import Loader from 'components/Loader/Loader';
import AssetDetails from '../AssetDetails/AssetDetails';
import MarketcapCard from 'scenes/marketcap/MarketcapCard/MarketcapCard';
import MarketcapHeader from 'scenes/marketcap/MarketcapCard/MarketcapHeader';
import './MarketcapTable.scss';

const MarketcapTable = props => {
  const {
    currentPair,
    defaultDetails,
    changeDefaultDetails,
    format,
    marketcap,
    favorites,
    filteredData,
    mobilePanel,
    activeDetails,
    setActiveDetails,
    itemsPerPage,
  } = props;

  const [ currentPage, setCurrentPage ] = useState(1);
  const pageData = marketcap && makePageable(!isEmpty(filteredData) ? filteredData : marketcap.Data, itemsPerPage);
  let totalMarketcap = 0;

  marketcap && marketcap.Data.forEach(workingAsset => {
    if (workingAsset.RAW && workingAsset.RAW[currentPair]) {
      const { MKTCAP: workingMktcap } = workingAsset.RAW[currentPair];
      totalMarketcap += workingMktcap;
    };
  });

  const getDetails = asset => {
    if (asset && asset.DISPLAY[currentPair] && asset.RAW[currentPair]) {
      const { MKTCAP: rawMktcap, PRICE, VOLUME24HOURTO, SUPPLY, CHANGEPCT24HOUR } = asset.RAW[currentPair];
      const { Id, Name, FullName, ImageUrl, Algorithm, ProofType, BlockNumber, BlockReward, BlockTime } = asset.CoinInfo;
      const rank = marketcap.Data.indexOf(asset) + 1;

      const details = {
        id: Id,
        name: Name,
        fullName: FullName,
        imageUrl: ImageUrl,
        rank: rank,
        marketCap: rawMktcap,
        price: PRICE,
        volume: VOLUME24HOURTO,
        supply: SUPPLY,
        change: CHANGEPCT24HOUR,
        algorithm: Algorithm,
        proofType: ProofType,
        blockNumber: BlockNumber,
        blockReward: BlockReward,
        blockTime: BlockTime,
        totalMarketcap: totalMarketcap,
        dominance: (rawMktcap / totalMarketcap * 100).toFixed(2),
      };

      return details;
    };
  };

  const buildTableRows = () => {
    if (isEmpty(defaultDetails)) {
      marketcap && changeDefaultDetails(getDetails(marketcap.Data[0]));
      return <Loader text={`Loading ${currentPair} pairs...`} />;
    }

    if (currentPage > pageData.length && format === 'Desktop') {
      setCurrentPage(pageData.length);
      return null;
    };

    const stickyFavs = data => {
      const favsRemoved = data.filter(asset => favorites.indexOf(asset.CoinInfo.Id) === -1);
      const favList = marketcap.Data.filter(asset => favorites.indexOf(asset.CoinInfo.Id) !== -1);
      const favsTop = [...favList, ...favsRemoved];
      const favPageData = makePageable(!isEmpty(filteredData) ? filteredData : favsTop, itemsPerPage);
      const favMobileData = !isEmpty(filteredData) ? filteredData : favsTop;

      return format === "Desktop" ? favPageData[currentPage - 1] : favMobileData;
    };

    const dataItem = stickyFavs(marketcap.Data);

    return dataItem.filter(asset => (asset.RAW && asset.RAW[currentPair]) && asset.DISPLAY[currentPair]).map(asset => {
      const details = getDetails(asset);

      return (
        <li key={details.id}>
          <MarketcapCard
            currentPair={currentPair}
            mobilePanel={mobilePanel}
            activeDetails={activeDetails}
            setActiveDetails={setActiveDetails}
            details={details}
            btcPairs={currentPair === 'BTC'} />
        </li>
      );
    });
  };

  return (
    <Fragment>
      <div className="capDetails">
        <AssetDetails
          details={(!isEmpty(activeDetails) && activeDetails) || (!isEmpty(defaultDetails) && defaultDetails)}
          btcPairs={currentPair === 'BTC'} />
      </div>

      <div className="capTable">
        <ul id="marketcap">
          <li className="strong"><MarketcapHeader /></li>
          {buildTableRows()}
        </ul>

        <div className="capPagination">
          {pageData && (
            (pageData.length > 1 && format === 'Desktop') && (
              <Pagination
                totalPages={pageData.length}
                currentPage={currentPage}
                changePage={newPage => setCurrentPage(newPage)} />
            )
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default MarketcapTable;