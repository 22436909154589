/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import { apiConstants, apiActions } from 'modules/api/apiDuck';

const {
  REACT_APP_CCC_API: dataPath,
} = process.env;

const apiCall = action => {
  if (action.type) return action.type.startsWith(`${apiConstants.API}.`);
};

const apiMiddleware = ({ dispatch }) => next => action => {
  let type;

  if (action.payload && action.payload.type) {
    if (action.payload.type.startsWith('modules')) {
      type = action.payload.type;
    }
  }

  !apiCall(action) && next(action);

  if (type) {
    dispatch(apiActions.startRequest(type));
    fetchData(dispatch, action.payload);
  }
};

const fetchData = (dispatch, { method, path, pathType, apiKey, payload, type, callback }) => {
  const token = JSON.parse(localStorage.getItem('xolioToken'));
  let url = `/.netlify/functions/server/${path}`;

  if (pathType == 'data') {
    url = `${dataPath}/${path}`;
  }

  const options = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  if (token && pathType !== 'data') {
    options.headers['x-auth-token'] = token;
  };

  if (pathType == 'data') {
    options.headers.authorization = `Apikey ${apiKey}`;
  }

  if (payload) {
    if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
      options.body = JSON.stringify(payload);
    }
  };

  fetch(url, options)
    .then(res => res.status < 500 ? res.json() : Promise.reject(Error(res.status)))
    .then(res => {
      if (res.errors) {
        dispatch(apiActions.setErrorMessages(res));
      } else {
        if (res.msg) {
          dispatch(apiActions.setSuccessMessages({ msg: res.msg }));
          delete res.msg;
        }

        dispatch({ type, payload: res });
      }
    })
    .then(() => callback && callback())
    .catch(() => {
      dispatch(
        apiActions.setErrorMessages({
          errors: [{ msg: "A server error has occured." }]
        })
      );
    })
    .finally(() => type && dispatch(apiActions.endRequest(type)))
};

export default apiMiddleware;