import React from 'react';
import Tabs from 'components/Tabs/Tabs';
import './TradeActions.scss';

const TradeActions = props => {
  const { actionTabs, activeActionTab, setActiveActionTab } = props;

  return (
    <div className="actionGroup">
      <Tabs
        tabs={actionTabs}
        activeTab={activeActionTab}
        onChange={newVal => setActiveActionTab(newVal)} />
    </div>
  );
};

export default TradeActions;