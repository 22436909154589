import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null
    };
  };

  static getDerivedStateFromError() {
    return { ...this.state, hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      const eventId = Sentry.captureException(error);
      scope.setExtras(errorInfo);
      this.setState({ hasError: true, eventId });
    });
  };

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <Button
          icon="warning"
          iconColor={colors.white}
          text="Report error"
          textColor={colors.white}
          btnStyle="solid"
          bgColor={colors.blue}
          callback={() => Sentry.showReportDialog({ eventId: this.state.eventId })} />
      );
    };

    //when there's not an error, render children untouched
    return this.props.children;
  };
};

export default ErrorBoundary;