/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { fromJS } from 'immutable';
import * as api from "./newsApi";

// Constants
const GET_ARTICLES = 'modules/news/GET_ARTICLES';
const STATE_KEY_ARTICLES = 'articles';

export const newsConstants = {
  GET_ARTICLES,
  STATE_KEY_ARTICLES,
};

// Reducer
export const reducer = (initialState = {}, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case GET_ARTICLES:
      return state.set(STATE_KEY_ARTICLES, action.payload).toJS();

    default:
      return initialState;
  }
}

// Actions
export const newsActions = {
  getArticles: callback => api.getArticles(callback, GET_ARTICLES),
};

// Selectors
export const newsSelectors = {
  articles: state => state.modules.news[STATE_KEY_ARTICLES],
};

export default reducer;