/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { apiActions } from 'modules/api/apiDuck';
import { timeAgg } from 'helpers/utilityFunctions';

const {
  REACT_APP_DATA_KEY: dataKey,
  REACT_APP_NAME: appName
} = process.env;

export const getAllCoins = type => {
  return apiActions.request({
    path: `all/coinlist`,
    apiKey: dataKey,
    method: 'GET',
    pathType: 'data',
    type: type,
  });
};

export const getAssetData = (asset, pair, timeframe, source, type) => {
  return apiActions.request({
    path: `v2/${timeAgg(timeframe)}&fsym=${asset}&tsym=${pair}&e=${source}&limit=500&extraParams=${appName}`,
    apiKey: dataKey,
    method: 'GET',
    pathType: 'data',
    type: type,
  });
};

export const getExchangePairs = type => {
  return apiActions.request({
    path: `v4/all/exchanges`,
    method: 'GET',
    pathType: 'data',
    type: type,
  });
}

export const getFavorites = type => {
  return apiActions.request({
    path: 'favorites/me',
    method: 'GET',
    type: type,
  });
};

export const addToFavorites = (payload, type) => {
  return apiActions.request({
    path: 'favorites/me',
    method: 'POST',
    type: type,
    payload,
  });
};

export const deleteFavoriteById = (payload, type) => {
  return apiActions.request({
    path: `favorites/me`,
    method: 'DELETE',
    type: type,
    payload,
  });
};