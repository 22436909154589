/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { fromJS } from 'immutable';
import * as api from "./appApi";

const defaultState = {
  token: null,
  username: null,
}

// Constants
const LOG_IN = 'modules/app/LOG_IN';
const LOG_OUT = 'modules/app/LOG_OUT';
const CREATE_ACCOUNT = 'modules/app/CREATE_ACCOUNT';
const DELETE_ACCOUNT = 'modules/app/DELETE_ACCOUNT';
const UPDATE_ACCOUNT = 'modules/app/UPDATE_ACCOUNT';
const RESET_PASSWORD = 'modules/app/RESET_PASSWORD';
const CHECK_TOKEN = 'modules/app/CHECK_TOKEN';
const GET_SETTINGS = 'modules/app/GET_SETTINGS';
const SET_SETTINGS = 'modules/app/SET_SETTINGS';
const STATE_KEY_TOKEN = 'token';
const STATE_KEY_USER_INFO = 'userInfo';
const STATE_KEY_APP_SETTINGS = 'appSettings';

export const appConstants = {
  LOG_IN,
  LOG_OUT,
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT,
  RESET_PASSWORD,
  CHECK_TOKEN,
  GET_SETTINGS,
  SET_SETTINGS,

  STATE_KEY_TOKEN,
  STATE_KEY_USER_INFO,
  STATE_KEY_APP_SETTINGS,
};

// Reducer
export const reducer = (initialState = defaultState, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case LOG_IN:
      localStorage.setItem('xolioToken', JSON.stringify(action.payload.token));
      return state.merge({
        [STATE_KEY_TOKEN]: action.payload.token,
        [STATE_KEY_USER_INFO]: action.payload
      }).toJS();
    case LOG_OUT:
      localStorage.removeItem('xolioToken');
      return state.merge({
        [STATE_KEY_TOKEN]: null,
        [STATE_KEY_USER_INFO]: null,
      }).toJS();
    case CREATE_ACCOUNT:
      localStorage.setItem('xolioToken', JSON.stringify(action.payload.token));
      return state.merge({
        [STATE_KEY_TOKEN]: action.payload.token,
        [STATE_KEY_USER_INFO]: action.payload
      }).toJS();
    case UPDATE_ACCOUNT:
        localStorage.setItem('xolioToken', JSON.stringify(action.payload.token));
        return state.merge({
          [STATE_KEY_TOKEN]: action.payload.token,
          [STATE_KEY_USER_INFO]: action.payload
        }).toJS();
    case RESET_PASSWORD:
      if (action.payload.token) {
        localStorage.setItem('xolioToken', JSON.stringify(action.payload.token));
        return state.merge({
          [STATE_KEY_TOKEN]: action.payload.token,
          [STATE_KEY_USER_INFO]: action.payload
        }).toJS();
      };

      return state.merge({
        [STATE_KEY_TOKEN]: null,
        [STATE_KEY_USER_INFO]: null,
      }).toJS();
    case DELETE_ACCOUNT:
      localStorage.removeItem('xolioToken');
      return state.merge({
        [STATE_KEY_TOKEN]: null,
        [STATE_KEY_USER_INFO]: null,
      }).toJS();
    case CHECK_TOKEN:
      return state.merge({
        [STATE_KEY_TOKEN]: action.payload.token,
        [STATE_KEY_USER_INFO]: action.payload
      }).toJS();
    case GET_SETTINGS:
      return state.set(STATE_KEY_APP_SETTINGS, action.payload).toJS();
    case SET_SETTINGS:
      return state.set(STATE_KEY_APP_SETTINGS, action.payload).toJS();

    default:
      return initialState;
  }
}

// Actions
export const appActions = {
  login: (userInfo, callback) => api.login(userInfo, callback, LOG_IN),
  logout: () => api.logout(LOG_OUT),
  createAccount: (userInfo, callback) => api.createAccount(userInfo, callback, CREATE_ACCOUNT),
  deleteAccount: payload => api.deleteAccount(payload, DELETE_ACCOUNT),
  updateAccount: (payload, callback) => api.updateAccount(payload, callback, UPDATE_ACCOUNT),
  resetPassword: (payload, callback) => api.resetPassword(payload, callback, RESET_PASSWORD),
  checkToken: token => api.checkToken(token, CHECK_TOKEN),
  getSettings: () => api.getSettings(GET_SETTINGS),
  setSettings: payload => api.setSettings(payload, SET_SETTINGS),
};

// Selectors
export const appSelectors = {
  token: state => state.modules.app[STATE_KEY_TOKEN],
  user: state => state.modules.app[STATE_KEY_USER_INFO],
  settings: state => state.modules.app[STATE_KEY_APP_SETTINGS],
};

export default reducer;