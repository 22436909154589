/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { streamingSelectors } from 'modules/streaming/streamingDuck';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { formatWholeNum } from 'helpers/utilityFunctions';
import { isEmpty } from 'lodash';
import { paramsFromURL, paramsToObject, paramsToString } from 'helpers/paramHelpers';
import Button from 'components/Button/Button';
import Chart from 'chart.js';
import assetColors from 'helpers/assetColors';
import colors from 'theme/colors.scss';
import './PieChart.scss';

let pieChart;
let totalValue = 0;

const PieChart = props => {
  const { currentPair, wallets, privacy } = props;
  const [ ctx, setCtx ] = useState(null);
  const history = useHistory();
  const { search } = useLocation();
  const containerRef = useRef();
  const chartRef = useRef();
  const dispatch = useDispatch();
  const params = paramsToObject(paramsFromURL());

  // Selectors
  const streamingData = useSelector(state => streamingSelectors.streamingData(state));
  const settings = useSelector(state => appSelectors.settings(state));

  // Actions
  const setSettings = payload => dispatch(appActions.setSettings(payload));

  const spotPrice = asset => {
    if (streamingData) {
      const workingData = streamingData.find(data => data.asset === asset && data.pair === currentPair);

      if (asset === currentPair) return 1;

      if (workingData) {
        let priceVal = workingData.close;
        if (currentPair === 'BTC') priceVal = parseFloat(priceVal?.toFixed(8));
        return priceVal;
      };
    };

    return 0;
  };

  const total = () => {
    if (streamingData) {
      let workingTotal = 0;

      wallets.forEach(wallet => {
        const streamData = streamingData.find(item => item.pair === currentPair && item.asset === wallet.chainType);

        if (wallet.chainType === currentPair) {
          workingTotal += wallet.balance;
        } else if (streamData) {
          workingTotal += streamData.close * wallet.balance;
        }
      });

      totalValue = workingTotal;

      if (currentPair === 'BTC') {
        totalValue = parseFloat(totalValue.toFixed(8));
      } else {
        totalValue = parseFloat(totalValue.toFixed(2));
      };

      return formatWholeNum(totalValue.toString());
    };

    return '--';
  };

  const setWalletData = walletValues => {
    let noValsFound = true;

    walletValues.forEach(val => {
      if (val > 0) noValsFound = false;
    });

    if (isEmpty(wallets) || isEmpty(walletValues)|| noValsFound) {
      return [-1];
    }

    return walletValues;
  };

  const setBorderWidth = walletValues => {
    let noValsFound = true;

    walletValues.forEach(val => {
      if (val > 0) noValsFound = false;
    });

    if (isEmpty(wallets) || isEmpty(walletValues) || noValsFound) {
      return 0;
    }

    return 3;
  };

  const setBackgroundColor = walletValues => {
    const colorSchema = wallets.map(wallet => assetColors(wallet.chainType));
    let noValsFound = true;

    walletValues.forEach(val => {
      if (val > 0) noValsFound = false;
    });

    if (isEmpty(wallets) || isEmpty(walletValues) || noValsFound) {
      return [colors.darkerGrey2];
    }

    return colorSchema;
  }

  const walletValues = wallets.map(wallet => wallet.balance * spotPrice(wallet.chainType));
  const data = {
    labels: isEmpty(wallets) ? ['No wallets'] : wallets.map(wallet => `${wallet.name} (${wallet.chainType})`),
    datasets: [{
      borderColor: colors.darkerGrey,
      borderWidth: setBorderWidth(walletValues),
      backgroundColor: setBackgroundColor(walletValues),
      data: setWalletData(walletValues),
    }],
  };

  useEffect(() => {
    const container = containerRef.current;
    const canvas = chartRef.current;
    canvas.width = container.clientHeight;
    canvas.height = container.clientHeight;
    setCtx(canvas);
  }, []);

  const buildChart = () => {
    const options = {
      animation: false,
      responsive: true,
      title: { display: false},
      cutoutPercentage: 92.5,
      tooltips: {
        enabled: false,
        callbacks: {
          label: (tooltipItem, data) => {
            let dataLabel = data.labels[tooltipItem.index];
            const value = data.datasets[0].data[0] < 0 ?
              '' :
              `: ${currentPair === 'BTC' ? '₿' : '$'} ${parseFloat(
                  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                  .toLocaleString(undefined, {
                    minimumFractionDigits: currentPair === 'BTC' ? 8 : 2
                })}`;

            if (Chart.helpers.isArray(dataLabel)) {
              dataLabel = dataLabel.slice();
              dataLabel[0] += value;
            } else {
              dataLabel += value;
            };

            return dataLabel;
          }
        }
      },
      legend: {
        display: false, position: "bottom",
      },
      hover: {
        onHover: function(e) {
           const point = this.getElementAtEvent(e);
           if (point.length) e.target.style.cursor = 'default';
           else e.target.style.cursor = 'default';
        }
      },
    };

    if (pieChart) pieChart.destroy();

    pieChart = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: options
    });
  };

  return (
    <Fragment>
      <div className="totalValue">
        <p>
          <span className="value">
            {privacy
              ? '***'
              : <Fragment>
                  <i className={currentPair === 'BTC' ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
                  {total()}
                </Fragment>
            }
          </span>
        </p>

        <p className="subText">Total value</p>

        {settings && (
          <Button
            icon={params.privacy === 'false' ? 'eye' : 'eyeClosed'}
            iconColor={params.privacy === 'true' ? colors.lighterGrey : colors.grey}
            btnStyle="transparent"
            callback={() => {
              const existingParams = paramsToObject(search);
              const isPrivate = existingParams.privacy === "true";
              const newParams = { ...existingParams, privacy: !isPrivate };
              const payload = { ...settings };

              payload.pages.portfolio = paramsToString(newParams);
              history.push(`/portfolio${paramsToString(newParams)}`);

              setSettings(payload);
            }} />
          )}
      </div>

      <div ref={containerRef} className="pieChart">
        {ctx && buildChart()}
        <canvas ref={chartRef} />
      </div>
    </Fragment>
  );
};

export default PieChart;