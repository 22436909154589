import * as colors from 'theme/colors.scss';

const assetColors = asset => {
  switch(asset) {
    case 'BTC': return colors.yellow;
    case 'ETH': return colors.purple;
    case 'XRP': return colors.blue;
    case 'USDT': return colors.green;
    case 'LTC': return colors.grey;
    case 'BCH': return colors.orange;
    case 'EOS': return colors.black;
    case 'XLM': return colors.brown;
    case 'ADA': return colors.cyan;
    case 'DOT': return colors.magenta;
    case 'SGB': return colors.pink;
    case 'BNB': return '#e4b144';
    case 'BSV': return '#da9500';
    case 'TRX': return '#c76446';
    case 'XMR': return '#c54924';
    case 'LINK': return '#2f2db9';
    case 'LEO': return colors.black;
    case 'HT': return '#2d75b9';
    case 'NEO': return '#2db96c';
    case 'ATOM': return colors.black;
    case 'XTZ': return '#2d8db9';
    case 'IOTA': return colors.black;
    case 'MKR': return '#63bdbd';
    case 'DASH': return '#3a82d3';
    case 'ETC': return '#5db46b';
    case 'ONT': return '#5195d4';
    case 'USDC': return '#1771d8';
    case 'CRO': return '#9ca6c4';
    case 'XEM': return '#55aacc';
    case 'BAT': return '#e24e4e';
    case 'VET': return '#af95c0';
    case 'DOGE': return '#c0aa95';
    case 'ZEC': return '#bda062';
    case 'DCR': return '#46ceac';
    case 'PAX': return '#bfe27e';
    case 'QTUM': return '#4fcbdb';
    case 'HEDG': return '#99d875';
    case 'ZRX': return colors.black;
    case 'TUSD': return '#7e88e2';
    case 'HOT': return '#4facd8';
    case 'CENNZ': return '#1d89d1';
    case 'VSYS': return colors.orange;
    case 'BTG': return '#eccf6e';
    case 'OMG': return '#80c2e7';
    case 'RVN': return '#e79880';
    case 'ZB': return '#e47c5c';
    case 'NANO': return '#33a2e2';
    case 'ABBC': return '#ca743b';
    case 'REP': return '#b89e8d';
    case 'ALGO': return colors.black;
    case 'SNX': return colors.black;
    case 'BTM': return colors.black;
    case 'KMD': return '#22d8d8';
    case 'LUNA': return '#d1c95e';
    case 'DAI': return '#d1ad5e';
    case 'KCS': return colors.slate;
    case 'EKT': return '#8cbe93';
    case 'DX': return '#da821f';
    case 'SLV': return colors.black;
    case 'BTT': return colors.grey;
    case 'BCD': return colors.lightGrey;
    case 'LSK': return '#c7b2ca';
    case 'DGB': return '#558dc2';
    case 'SC': return '#63e0bb';
    case 'ICX': return '#55c2a1';
    case 'XVG': return '#36b3d3';
    case 'STEEM': return '#467cbb';
    case 'ZIL': return '#17d2d8';

    default:
      return colors.darkGrey;
  };
};

export default assetColors;