import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appActions } from 'modules/app/appDuck';
import { chartsActions } from 'modules/charts/chartsDuck';
import { tradingActions } from 'modules/trading/tradingDuck';
import { portfolioActions } from 'modules/portfolio/portfolioDuck';
import colors from 'theme/colors.scss';
import 'components/Nav/Nav.scss';

const UserNav = props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // Actions
  const clearFavorites = () => dispatch(chartsActions.clearFavorites());
  const clearTradingData = () => dispatch(tradingActions.clearTradingData());
  const clearPortfolio = () => dispatch(portfolioActions.clearPortfolio());
  const logout = () => dispatch(appActions.logout());

  return (
    <div className="nav">
      <i className={`${location.pathname === '/account' ? 'fas' : 'fal'} fa-user navIcon`}
        style={{ color: location.pathname === '/account' ? colors.lightGrey : colors.grey }}
        onClick={() => location.pathname !== '/account' && history.push('/account')} />

      <i className="fal fa-sign-out navIcon" style={{ color: colors.grey }} onClick={() => {
        clearFavorites();
        clearPortfolio();
        clearTradingData();
        logout();
      }} />
    </div>
  );
};

export default UserNav;