import { actionCreator } from 'helpers/actionHelpers'
import { fromJS } from 'immutable';

// Constants
const API = 'API';
const START_REQUEST = 'START_REQUEST';
const END_REQUEST = 'END_REQUEST';
const ERROR_MESSAGES = 'ERROR_MESSAGES';
const SUCCESS_MESSAGES = 'SUCCESS_MESSAGES';
const STATE_KEY_ERROR_MESSAGES = 'errorMessages';
const STATE_KEY_SUCCESS_MESSAGES = 'successMessages';

export const apiConstants = {
  API,
  START_REQUEST,
  END_REQUEST,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  STATE_KEY_ERROR_MESSAGES,
  STATE_KEY_SUCCESS_MESSAGES,
};

// Reducer
export const reducer = (initialState = {}, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case START_REQUEST:
      break;
    case END_REQUEST:
      break;
    case ERROR_MESSAGES:
      return state.set(STATE_KEY_ERROR_MESSAGES, action.payload).toJS();
    case SUCCESS_MESSAGES:
      return state.set(STATE_KEY_SUCCESS_MESSAGES, action.payload).toJS();

    default:
      return initialState;
  }

  return initialState;
};

// Actions
export const apiActions = {
  startRequest: actionCreator(START_REQUEST),
  endRequest: actionCreator(END_REQUEST),
  setErrorMessages: payload => ({ type: ERROR_MESSAGES, payload }),
  setSuccessMessages: payload => ({ type: SUCCESS_MESSAGES, payload }),

  request: options => ({
    type: `${API}.${options.type}`,
    payload: options
  }),
};

// Selectors
export const apiSelectors = {
  errorMessages: state => state.modules.api[STATE_KEY_ERROR_MESSAGES],
  successMessages: state => state.modules.api[STATE_KEY_SUCCESS_MESSAGES],
};

export default reducer;