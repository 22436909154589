import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apiActions, apiSelectors } from 'modules/api/apiDuck';
import MessageCard from './MessageCard';
import './ServerMessages.scss';

const SuccessMessages = props => {
  const dispatch = useDispatch();

  // Actions/Selectors
  const successMessages = useSelector(state => apiSelectors.successMessages(state));
  const setSuccessMessages = payload => dispatch(apiActions.setSuccessMessages(payload));

  const removeMessage = () => {
    setSuccessMessages(null);
  };

  const buildMessages = () => {
    if (successMessages) {
      return (
        <MessageCard
          type="success"
          message={successMessages.msg}
          onClose={removeMessage} />
      );
    }
  };

  return (
    <div className="apiMessagesContainer">
      {buildMessages()}
    </div>
  );
};

export default SuccessMessages;