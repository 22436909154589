import React, { Fragment } from 'react';
import './WalletCard.scss';

const WalletsHeader = props => {
  return (
    <Fragment>
      <div className="walletCard walletHeader">
        <div className="chainType"></div>

        <div className="remove">
          <i className="fad fa-trash-alt" />
        </div>

        <div id="walletName" className="walletName">
          <h3>Wallet Name</h3>
        </div>

        <div className="balance">
          <h3>Balance</h3>
        </div>

        <div className="value">
          <h3>Value</h3>
        </div>

        <div className="position">
          <h3>Allocation</h3>
        </div>

        <div className="price">
          <h3>
            <i className="fal fa-heart-rate" />&nbsp;
            Asset Price
          </h3>
        </div>

        <div className="draggable"></div>
      </div>
    </Fragment>
  );
};

export default WalletsHeader;