import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { apiActions, apiSelectors } from 'modules/api/apiDuck';
import { isEmpty } from 'lodash';
import MessageCard from './MessageCard';
import './ServerMessages.scss';

const ErrorMessages = props => {
  const dispatch = useDispatch();

  // Actions/Selectors
  const errorMessages = useSelector(state => apiSelectors.errorMessages(state));
  const setErrorMessages = payload => dispatch(apiActions.setErrorMessages(payload));

  const removeMessage = id => {
    const workingList = Object.assign({ ...errorMessages });
    workingList.errors.splice(id, 1);
    setErrorMessages(workingList);
  };

  const buildMessages = () => {
    if (errorMessages && !isEmpty(errorMessages.errors)) {
      return errorMessages.errors.map((error, index) => {
        return (
          <MessageCard
            key={index}
            type="error"
            index={index}
            message={error.msg}
            onClose={removeMessage} />
        );
      });
    }
  };

  return (
    <div className="apiMessagesContainer">
      {buildMessages()}
    </div>
  );
};

export default ErrorMessages;