import React, { Fragment } from 'react';
import './OrderCard.scss';

const FilledOrdersHeader = props => {
  const { asset, pair } = props;

  return (
    <Fragment>
      <div className="orderCard">
        <div id="type" className="type">
          <h3>Type</h3>
        </div>

        <div className="size">
          <h3>Size ({asset})</h3>
        </div>

        <div className="price">
          <h3>Price ({pair})</h3>
        </div>

        <div className="fee">
          <h3>Fee ({pair})</h3>
        </div>

        <div className="time">
          <h3>Time</h3>
        </div>
      </div>
    </Fragment>
  );
};

export default FilledOrdersHeader;