import React, { Fragment } from 'react';
import './ProgressBar.scss';

const ProgressBar = props => {
  const { color, width, length } = props;

  const progressStyle = { width: width || '100%' };

  const lengthStyle = {
    width: `calc(100% * ${length / 100})`,
    backgroundColor: color,
  }

  return (
    <Fragment>
      <div className="progressBar" style={progressStyle}>
        <div className="progressLength" style={lengthStyle}></div>
      </div>
    </Fragment>
  );
};

export default ProgressBar;