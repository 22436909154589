/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from 'd3';
import 'd3-selection-multi';
import { bollingerBands } from 'helpers/utilityFunctions';
import './bollingerBands.scss';

const generateBollingerBands = (
  OHLC,
  visibleRange,
  bollingerLengths,
  chart,
  xScale,
  yScale,
  pathGen,
  timestamps,
  bollingerBandState,
  setBollingerBandState
  ) => {
  const { active, range } = bollingerBandState;

  if (active) {
    const bollingerData = new bollingerBands(OHLC.close, bollingerLengths[0], bollingerLengths[1]).calc();

    bollingerData.upper = bollingerData.upper.slice(bollingerData.upper.length - visibleRange);
    bollingerData.middle = bollingerData.middle.slice(bollingerData.middle.length - visibleRange);
    bollingerData.lower = bollingerData.lower.slice(bollingerData.lower.length - visibleRange);

    const upperBand = bollingerData.upper;
    const middleBand = bollingerData.middle;
    const lowerBand = bollingerData.lower;

    const areaGen = d3.area()
      .defined(d => d)
      .x((d, i) => xScale(timestamps[i]))
      .y0((d, i) => yScale(lowerBand[i]))
      .y1((d, i) => yScale(upperBand[i]))
      .curve(d3.curveMonotoneX) // options are: d3.curveMonotoneX or d3.curveLinear

    const minMax = [d3.min(lowerBand), d3.max(upperBand)];

    if (range[0] !== minMax[0] || range[1] !== minMax[1]) {
      setBollingerBandState({ ...bollingerBandState, range: minMax });
    }

    const bollingerArea = chart.append('path')
      .attrs({
        class: 'bollingerArea',
        d: areaGen(lowerBand, upperBand)
      })

    const bollingerLineTop = chart.append('path')
      .attrs({
        class: 'upperBand',
        d: pathGen(upperBand)
      })

    const bollingerLineMiddle = chart.append('path')
      .attrs({
        class: 'middleBand',
        d: pathGen(middleBand)
      })

    const bollingerLineBottom = chart.append('path')
      .attrs({
        class: 'lowerBand',
        d: pathGen(lowerBand)
      })
  } else {
    // Reset range if indicator is off
    if (range.length > 0) setBollingerBandState({ ...bollingerBandState, range: [] });
  };
};

export default generateBollingerBands;