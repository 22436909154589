/* eslint-disable no-unused-vars */

import React, { Fragment } from 'react';
import { startCase } from 'lodash';
import { formatWholeNum } from 'helpers/utilityFunctions';
import moment from 'moment';
import colors from 'theme/colors.scss';
import './OrderCard.scss';

const FilledOrderCard = props => {
  const { pair, order, privacy } = props;
  const {
    side: type,
    size,
    price,
    fee,
    created_at,
  } = order;

  const date = moment(created_at).fromNow();

  const typeStyle = {
    color: type === 'buy' ? colors.white : colors.yellow,
  }

  const formatVal = val => {
    let finalVal = val.toFixed(2);

    if (val < 1 && pair === 'USD') {
      finalVal = val.toFixed(4);
    }

    if (pair !== 'USD') {
      finalVal = val.toFixed(8);
    }

    return formatWholeNum(finalVal);
  };

  return (
    <Fragment>
      <div className="orderCard">
        <div id="type" className="type" style={typeStyle}>
          {startCase(type)}
        </div>

        <div className="size">
          {formatWholeNum(parseFloat(size).toFixed(8))}
        </div>

        <div className="price">
          {formatVal(parseFloat(price))}
        </div>

        <div className="fee">
          {formatVal(parseFloat(fee))}
        </div>

        <div className="time">
          {date}
        </div>
      </div>
    </Fragment>
  );
};

export default FilledOrderCard;