/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { chartsActions, chartsSelectors } from 'modules/charts/chartsDuck';
import { isEmpty } from 'lodash';
import * as colors from 'theme/colors.scss';
import './AssetDetails.scss';
import Button from 'components/Button/Button';

const AssetDetails = props => {
  const { btcPairs } = props;
  const dispatch = useDispatch();

  const {
    id,
    totalMarketcap,
    change,
    fullName,
    imageUrl,
    marketCap,
    name,
    price,
    rank,
    supply,
    volume,
    dominance,
  } = props.details;

  // Selectors
  const favorites = useSelector(state => chartsSelectors.favorites(state));

  // Actions
  const addToFavorites = payload => dispatch(chartsActions.addToFavorites(payload));
  const deleteFavoriteById = payload => dispatch(chartsActions.deleteFavoriteById(payload));

  const changeStyle = { color: change < 0 ? colors.yellow : colors.white };

  const toggleFav = () => {
    if (favorites) {
      const index = favorites.indexOf(id);
      if (index === -1 || isEmpty(favorites)) addToFavorites({ id });
      if (index !== -1) deleteFavoriteById({ id });
    };
  };

  const getDelta = () => {
    if (name === 'BTC' && btcPairs) {
      return '0.00%';
    }

    if (change) {
      return `${change > 0 ? '+' : ''}${change.toFixed(2).toLocaleString(undefined, {maximumFractionDigits: 2})}%`;
    }

    return '0.00%';
  };

  return (
    <Fragment>
      <div className="assetDetails">
        <div className="assetImage">
          <div className="img">
            {imageUrl && <img src={`https://cryptocompare.com${imageUrl}`} alt={name} />}
          </div>
        </div>

        <div className="mainDetails">
          <h1>
            {fullName} &nbsp;

            <span className="symbol darkGrey strong">
              {name}
            </span>
          </h1>

          <h2 style={changeStyle}>
            {price && <i className={btcPairs ? 'fab fa-btc' : 'fad fa-dollar-sign'} />}&nbsp;
            {(price && (btcPairs ? price.toFixed(8) : price.toLocaleString()))}&nbsp;
            <span className="detailsDelta">
              {getDelta()}
            </span>
          </h2>
        </div>

        <div className="generalDetails">
          <p>
            <span className="strong lightGrey">Rank #</span>: &nbsp;
            {rank ? rank : '--'}
          </p>

          <p>
            <span className="strong lightGrey">Market Cap</span>: &nbsp;
            <i className={btcPairs ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
            {marketCap ? marketCap.toLocaleString(undefined, {maximumFractionDigits: 2}) : '--'}
          </p>

          <p>
            <span className="strong lightGrey">Total Market Cap</span>: &nbsp;
            <i className={btcPairs ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
            {totalMarketcap ? totalMarketcap.toLocaleString(undefined, {maximumFractionDigits: 2}) : '--'}
          </p>

          <p>
            <span className="strong lightGrey">Dominance</span>: &nbsp;
            {dominance ? `${dominance}%` : '--'}
          </p>

          <p>
            <span className="strong lightGrey">24H Volume</span>: &nbsp;
            <i className={btcPairs ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
            {volume ? volume.toLocaleString(undefined, {maximumFractionDigits: 2}) : '--'}
          </p>

          <p>
            <span className="strong lightGrey">Circulating Supply</span>: &nbsp;
            <span className="strong">{name}</span>&nbsp;
            {supply ? supply.toLocaleString(undefined, {maximumFractionDigits: 2}) : '--'}&nbsp;
          </p>

          <div className="watchContainer">
            {favorites && (
              <Button
                icon={favorites.indexOf(id) !== -1 ? "starSolid" : "star"}
                iconColor={favorites.indexOf(id) !== -1 ? colors.yellow : colors.grey}
                text={favorites.indexOf(id) !== -1 ? "Remove from Favorites" : "Add to Favorites"}
                textColor={colors.lightGrey}
                btnStyle={marketCap ? 'solid' : 'ghost'}
                bgColor={colors.darkGrey2}
                disabled={!marketCap}
                callback={toggleFav} />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AssetDetails;