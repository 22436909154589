import React from 'react';
import { isEmpty, uniqueId } from 'lodash';
import './Tabs.scss';
import colors from 'theme/colors.scss';

const Tabs = props => {
  const { tabs, activeTab, onChange } = props;

  const buildTabs = () => {
    return tabs.map(label => {
      const tabStyle = {
        color: label === activeTab ? colors.white : colors.white,
        border: `0.0625rem solid ${colors.darkerGrey}`,
        backgroundColor: label === activeTab ? colors.grey : colors.black,
      };

      return (
        <li key={uniqueId('Tab_')} onClick={() => onChange(label)} style={tabStyle}>
          <span className="tabLabel">{label}</span>
        </li>
      );
    });
  };

  return (
    <React.Fragment>
      <ul className="tabbedHeaders">
        {!isEmpty(tabs) && buildTabs()}
      </ul>
    </React.Fragment>
  )
}

export default Tabs;