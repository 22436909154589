import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import CreateAccount from './CreateAccount/CreateAccount';
import './AuthForm.scss';

const AuthForm = props => {
  const match = useRouteMatch();

  const loadForm = () => {
    const url = match.url;

    if (url === '/create-account') return <CreateAccount />;
    if (url === '/reset-password') return <ResetPassword />;
    if (url === '/login') return <Login />;
  }

  return (
    <div id='authContainer'>
      {loadForm()}

      <p>
        <i className="fas fa-info-circle slate info"></i>
        <strong>Do more with an account</strong>: manage favorites,
        build your portfolio and keep preferences saved.
      </p>
    </div>
  )
}

export default AuthForm;