import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";

const Modal = props => {
  const { onClose, title, render, id, footer, footerContent } = props;
  const target = document.querySelector("#root");
  const modalWin = useRef();

  useEffect(() => {
    const content = modalWin.current;

    if (content) {
      content.style.setProperty("opacity", 1);
      content.style.setProperty("transform", "translateY(2.25rem)");
    }
  }, [modalWin]);

  const closeModal = () => {
    const content = modalWin.current;

    if (content) {
      content.style.setProperty("opacity", 0);
      content.style.setProperty("transform", "translateY(0)");
    }

    if (onClose) setTimeout(() => onClose(), 300);
  };

  const buildFooter = () => {
    if (footer) {
      return (
        <div className="modalFooter">
          <p>{footerContent ? footerContent : ""}</p>
        </div>
      );
    }
  };

  const buildModal = () => {
    return (
      <div id={id} className="modal">
        <div className="modalBG" onClick={() => closeModal()}></div>

        <div ref={modalWin} className="modalWin">
          {/* modalHeader onClick is custom for Xolio.io -- normally removed. */}
          <div className="modalHeader" onClick={() => closeModal()}>
            <p className="modalTitle">{title ? title : ""}</p>

            <div className="closeModalIcon" onClick={() => closeModal()}>
              <i className="fad fa-times-circle"></i>
            </div>
          </div>

          <div className="modalBody">{render}</div>

          {footer && buildFooter()}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(buildModal(), target)}
    </React.Fragment>
  );
};

export default Modal;
