import React, { Fragment, useState } from 'react';
import Button from 'components/Button/Button';
import defaultSettings from 'helpers/defaultSettings';
import { isEqual } from 'lodash';
import { checkValue, resetDefaults } from 'helpers/settingsHelpers';
import colors from 'theme/colors.scss';
import './ChartSettings.scss';

const VolMASettings = props => {
  const { volMa, settings, setSettings } = props;
  const { indicators: { volMa: defaultVolMa } } = defaultSettings;
  const [ volumeVal, setVolumeVal ] = useState(volMa || defaultVolMa);

  const changeVal = (newVal, checkVal) => {
    const workingVal = checkVal ? checkValue(newVal, defaultVolMa) : newVal;

    setVolumeVal(workingVal);

    if (checkVal && parseInt(workingVal) !== parseInt(volMa)) {
      const payload = { ...settings };
      payload.indicators.volMa = workingVal;
      setSettings(payload);
    }
  };

  return (
    <Fragment>
      <h4>
        Volume Moving Average

        <Button
          text="Default"
          textColor={colors.offWhite}
          btnStyle="ghost"
          callback={() => {
            if (!isEqual(volumeVal, defaultVolMa)) {
              resetDefaults('volMa', settings, setSettings);
              setVolumeVal(defaultVolMa);
            }
          }} />
      </h4>

      <label>
        <p className="labelTitle">SMA Length</p>
        <input
          type="number"
          value={volumeVal}
          onBlur={e => changeVal(parseInt(e.currentTarget.value), true)}
          onKeyPress={e => e.key === 'Enter' && changeVal(parseInt(e.currentTarget.value), true)}
          onChange={e => changeVal(parseInt(e.currentTarget.value))} />
      </label>
    </Fragment>
  );
}

export default VolMASettings;