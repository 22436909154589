/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tradingActions, tradingSelectors } from 'modules/trading/tradingDuck';
import Pagination from 'components/Pagination/Pagination';
import { makePageable } from 'helpers/utilityFunctions';
import { isEmpty } from 'lodash';
import OpenOrdersHeader from './OpenOrdersHeader';
import OpenOrderCard from './OpenOrderCard';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './OrdersTable.scss';

const OpenOrders = props => {
  const { format, itemsPerPage, asset, pair, privacy } = props;
  const [ currentPage, setCurrentPage ] = useState(1);
  const dispatch = useDispatch();

  // Actions
  const cancelOrderById = (id, product_id) => dispatch(tradingActions.cancelOrderById(id, product_id));
  const cancelAllOrders = product_id => dispatch(tradingActions.cancelAllOrders(product_id));

  // Selectors
  const openOrders = useSelector(state => tradingSelectors.openOrders(state));

  const pageData = makePageable(!isEmpty(openOrders) && openOrders, itemsPerPage);

  const buildTableRows = () => {
    if (currentPage > pageData.length && format === 'Desktop') {
      setCurrentPage(pageData.length);
      return null;
    };

    const dataItem = () => {
      if (format === 'Desktop') return pageData[currentPage - 1];
      return openOrders;
    };

    return dataItem().map((order, index) => {
      return (
        <li key={index}>
          <OpenOrderCard
            pair={pair}
            privacy={privacy}
            order={order}
            cancelOrderById={(id, product_id) => cancelOrderById(id, product_id)} />
        </li>
      );
    });
  };

  return (
    <div className="ordersTable">
      <ul id="openOrders">
        <li className="strong">
          <OpenOrdersHeader
            disabled={isEmpty(openOrders)}
            asset={asset}
            pair={pair}
            cancelAllOrders={product_id => cancelAllOrders(product_id)} />
        </li>

        {!isEmpty(openOrders) && buildTableRows()}
        {isEmpty(openOrders) && <p>No open orders</p>}
      </ul>

      <div className="openOrdersPagination">
        {(pageData.length > 1 && format === 'Desktop') && (
          <Pagination
            totalPages={pageData.length}
            currentPage={currentPage}
            changePage={newPage => setCurrentPage(newPage)} />
        )}
      </div>
    </div>
  )
}

export default OpenOrders;