/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { chartsActions, chartsSelectors } from 'modules/charts/chartsDuck';
import { paramsFromURL, paramsToString, paramsToObject } from 'helpers/paramHelpers';
import { deviceFormat } from 'helpers/utilityFunctions';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import ChartSettings from './ChartSettings/ChartSettings';
import CandleChart from './CandleChart/CandleChart';
import MACD from './MACD/MACD';
import RSI from './RSI/RSI';
import StochRSI from './StochRSI/StochRSI';
import ChartDetails from './ChartDetails/ChartDetails';
import Tabs from 'components/Tabs/Tabs';
import Toggle from 'components/Toggle/Toggle';
import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './Charts.scss';

// Note: Update 'timeLeft' & 'timeAgg' in utilityFunctions if adding/removing time periods.
// Also update 'timeInterval' in candles.js to control mobile tick spacing for new time frame.
const timeframes = ['1m', '5m', '1H', '3H', '4H', '6H', '1D', '1W']; // Hiding '15m', '30m' and '12H' -- data response is not complete.
const defaultSources = ['CCCAGG', 'BitTrex', 'Coinbase', 'Gemini', 'Kraken'];
const subCharts = ['MACD', 'RSI', 'Stoch. RSI'];

const resizeActions = (e, setDimensions, mounted) => {
  const element = e.currentTarget;
  const newHeight = element.innerHeight;
  const newWidth = element.innerWidth;
  if (mounted) setDimensions({ width: newWidth, height: newHeight });
};

const defaultParams = search => {
  if (search !== '') return paramsToObject(search);
  return paramsToObject('?asset=BTC&pair=USD&timeframe=4H&source=CCCAGG&subChart=MACD');
};

const coinExists = (query, allCoins) => {
  return allCoins && allCoins.filter(coin => (
    coin.info.CoinName.toUpperCase() === query || coin.symbol === query
  ))[0]
};

const Charts = props => {
  const { search } = useLocation();
  const [ dimensions, setDimensions ] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [ sources, setSources] = useState(defaultSources);
  const [ firstDataLoad, setFirstDataLoad ] = useState(true);
  const [ showSettings, setShowSettings ] = useState(false);
  const [ modalState, setModalState ] = useState(defaultModal);
  const [ myParams, setMyParams ] = useState(defaultParams(search));
  const { asset, pair, timeframe, source, subChart } = myParams;
  const dispatch = useDispatch();
  const history = useHistory();
  const mounted = useRef(true);
  const settingsPanel = useRef();
  const params = paramsToObject(paramsFromURL());

  // Selectors
  const allCoins = useSelector(state => chartsSelectors.allCoins(state));
  const assetData = useSelector(state => chartsSelectors.assetData(state));
  const exchangePairs = useSelector(state => chartsSelectors.exchangePairs(state));
  const settings = useSelector(state => appSelectors.settings(state));

  // Actions
  const getAssetData = (asset, pair, timeframe, source) => dispatch(chartsActions.getAssetData(asset, pair, timeframe, source));
  const setSettings = payload => dispatch(appActions.setSettings(payload));
  const getSettings = () => dispatch(appActions.getSettings());

  // TODO: Optimise re-renders caused by these initial calls.
  useEffect(() => {
    getSettings();

    window.addEventListener('resize', e => resizeActions(e, setDimensions, mounted.current));

    return () => {
      mounted.current = false;
      window.removeEventListener('resize', e => resizeActions(e, setDimensions, mounted.current))
    };
  }, []);

  useEffect(() => {
    if (settings) {
      const { charts: chartParams } = settings.pages;
      const saved = paramsToObject(chartParams);

      setMyParams(saved);
      history.push(`/charts${chartParams}`);

      if (firstDataLoad) {
        getAssetData(saved.asset, saved.pair, saved.timeframe, saved.source);
        setFirstDataLoad(false);
      };
    };
  }, [settings]);

  useEffect(() => {
    if (exchangePairs) {
      const hasAsset = exchangePairs.filter(exchange => {
        return (
          defaultSources.includes(exchange.name) &&
          exchange.name !== defaultSources[0] &&
          exchange.pairs[asset] &&
          exchange.pairs[asset].tsyms[pair]
        ) && exchange;
      }).map(exchange => exchange.name);

      setSources([defaultSources[0], ...hasAsset]);
    };
  }, [asset, pair]);

  const changeParam = (param, newVal) => {
    const newParams = { ...myParams, [param]: newVal };
    const assetCheck = coinExists(newVal, allCoins);

    if (assetCheck) {
      if (assetCheck.info.CoinName.toUpperCase() === newVal) newParams.asset = assetCheck.symbol;
      if (newParams.asset === newParams.pair) newParams.asset = 'ETH';
    };

    setMyParams(newParams);
    history.push(`/charts${paramsToString(newParams)}`);

    if (param !== 'subChart' && (
        (`${newParams.asset}${newParams.pair}` !== `${asset}${pair}`) ||
        (param === 'timeframe' || param === 'source'))
      ) {
      getAssetData(newParams.asset, newParams.pair, newParams.timeframe, newParams.source);
    };

    if (settings) {
      const payload = { ...settings };
      payload.pages.charts = paramsToString(newParams);
      setSettings(payload);
    }
  };

  useEffect(() => {
    if (!sources.includes(myParams.source)) {
      changeParam('source', sources[0]);
    };
  }, [sources]);

  const handleLoading = () => {
    return (
      <div className="errorHandling">
        {coinExists(asset, allCoins) && sources.includes(myParams.source) && (
          <Loader text="Loading chart data..." />
        )}

        {(coinExists(asset, allCoins) === undefined) && (
          <p>Asset not found.</p>
        )}

        {(coinExists(asset, allCoins) && !sources.includes(myParams.source)) && (
          <Loader text="Changing data source..." />
        )}
      </div>
    );
  }

  const toggleSettingsPanel = () => {
    const panel = settingsPanel.current;

    if (panel) {
      if (!showSettings) {
        panel.style.setProperty("display", "block");
        setTimeout(() => {
          panel.style.setProperty("opacity", 1);
          panel.style.setProperty("transform", "translateX(-0.5rem)");
        }, 200);
      } else {
        panel.style.setProperty("opacity", 0);
        panel.style.setProperty("transform", "translateX(0)");
        setTimeout(() => panel.style.setProperty("display", "none"), 575);
      }
    }

    setShowSettings(!showSettings)
  };

  const buildSubChart = () => {
    if (subChart === 'MACD') {
      return (
        <MACD
          data={assetData}
          format={deviceFormat(dimensions.width, dimensions.height)}
          settings={settings}
          pair={pair}
          timeframe={timeframe} />
      );
    };

    if (assetData && subChart === 'RSI') {
      return (
        <RSI
        data={assetData}
        format={deviceFormat(dimensions.width, dimensions.height)}
        settings={settings}
        pair={pair}
        timeframe={timeframe} />
      );
    };

    if (assetData && subChart === 'Stoch. RSI') {
      return (
        <StochRSI
        data={assetData}
        format={deviceFormat(dimensions.width, dimensions.height)}
        settings={settings}
        pair={pair}
        timeframe={timeframe} />
      );
    };
  };

  return (
    <Fragment>
      <div id="chartsBody">
        <div className="chartsTitle">
          <h2>Charts</h2>
        </div>

        <div className="chartsPairToggle">
          <p className="strong">BTC</p> &nbsp;&nbsp;
          {settings && params.pair && (
            <Toggle
              disabled={asset === 'BTC'}
              defaultVal={params.pair === 'BTC'}
              callback={newVal => changeParam('pair', newVal ? 'BTC' : 'USD')} />
          )}
        </div>

        <div className="chartsSettings">
          <Button
            icon={showSettings ? 'settingsActive' : 'settingsInactive'}
            iconColor={showSettings ? colors.white : colors.grey}
            btnStyle="transparent"
            callback={() => {
              const format = deviceFormat(dimensions.width, dimensions.height);

              if (format === 'Desktop') {
                toggleSettingsPanel();
              } else {
                openModal(setModalState, null, 'Settings',
                <div className="mobileSettingsContainer">
                  <ChartSettings
                    format={format}
                    settings={settings}
                    setSettings={newVal => setSettings(newVal)}
                    subChart={subChart} />
                </div>
                );
              }
            }} />
        </div>

        <div className="chart">
          <Fragment>
            <ChartDetails
              source={source}
              sources={sources}
              timeframe={timeframe}
              timeframes={timeframes}
              pair={pair}
              assetData={assetData}
              myParams={myParams}
              changeAsset={newAsset => changeParam('asset', newAsset.toUpperCase())}
              changeTime={newTime => changeParam('timeframe', newTime)}
              changeSource={newSource => changeParam('source', newSource)} />

            <div id="candleChart">
              {!assetData && handleLoading()}
              {assetData && (
                <CandleChart
                  data={assetData}
                  format={deviceFormat(dimensions.width, dimensions.height)}
                  settings={settings}
                  pair={pair}
                  timeframe={timeframe} />
              )}
            </div>

            <div className="subCharts">
              <Tabs
                tabs={subCharts}
                activeTab={subChart}
                onChange={newChart => changeParam('subChart', newChart)} />

              {assetData && buildSubChart()}
            </div>
          </Fragment>
        </div>

        <div ref={settingsPanel} className="settingsContainer">
          <ChartSettings
            settings={settings}
            setSettings={newVal => setSettings(newVal)}
            subChart={subChart} />
        </div>
      </div>

      {showModal(modalState, setModalState)}
    </Fragment>
  );
};

export default Charts;