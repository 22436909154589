import { connect } from 'react-redux';
import CCCWebsocket from './CCCWebsocket';
import { portfolioSelectors } from 'modules/portfolio/portfolioDuck';
import { streamingActions, streamingSelectors } from 'modules/streaming/streamingDuck';
import { appSelectors } from 'modules/app/appDuck';
import { tradingActions, tradingSelectors } from 'modules/trading/tradingDuck';

const mapStateToProps = state => {
  return {
    wallets: portfolioSelectors.wallets(state),
    settings: appSelectors.settings(state),
    connStatus: streamingSelectors.connStatus(state),
    streamingData: streamingSelectors.streamingData(state),
    streamingStatusData: streamingSelectors.streamingStatusData(state),
    streamingUserData: streamingSelectors.streamingUserData(state),
    integrationData: tradingSelectors.integrationData(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setConnStatus: payload => dispatch(streamingActions.setConnStatus(payload)),
    setStreamingData: payload => dispatch(streamingActions.setStreamingData(payload)),
    setStreamingStatusData: payload => dispatch(streamingActions.setStreamingStatusData(payload)),
    setStreamingUserData: payload => dispatch(streamingActions.setStreamingUserData(payload)),
    getBalances: () => dispatch(tradingActions.getBalances()),
    getOpenOrders: product_id => dispatch(tradingActions.getOpenOrders(product_id)),
    getFilledOrders: product_id => dispatch(tradingActions.getFilledOrders(product_id)),
  };
};

const CCCWebsocketContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CCCWebsocket);

export default CCCWebsocketContainer;