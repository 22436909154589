/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, memo } from 'react';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'lodash';
import { paramsToObject } from 'helpers/paramHelpers';
import { connectSocket, connectCBSocket } from 'helpers/socketHelpers';
import * as appStatus from 'scenes/app/appConstants';
import colors from 'theme/colors.scss';
import './CCCWebsocket.scss';

let socketDataLoaded = false;

const connectionIndicatorStyle = setServiceStatus => {
  return {
    color: setServiceStatus('icon'),
    fontSize: '0.55rem',
  };
};

const CCCWebsocket = props => {
  const { connStatus } = props;
  const { connecting, reconnecting, connected, disconnected, timeout } = appStatus;

  const setServiceStatus = type => {
    if (type === 'icon') {
      if (connStatus === connecting || connStatus === reconnecting) { return colors.yellow};
      if (connStatus === connected) { return colors.green};
      if (connStatus === disconnected || connStatus === timeout) { return colors.red};
    };

    if (type === 'text') {
      if (connStatus === connecting) return 'Connecting...';
      if (connStatus === reconnecting) return 'Reconnecting...';
      if (connStatus === connected) return 'All services OK.';
      if (connStatus === disconnected) return 'Unable to connect to services, please try again later.';
      if (connStatus === timeout) return 'Attempted to reach services, but the connection timed out.';
    };
  };

  return <i className="fas fa-circle socketIndicator" style={connectionIndicatorStyle(setServiceStatus)} />;
};

const cacheComponent = (prevProps, nextProps) => {
  if (prevProps.location && nextProps.location) {
    const { pathname: prevPath, search: prevSearch } = prevProps.location;
    const { pathname: nextPath, search: nextSearch } = nextProps.location;
    const prevParams = paramsToObject(prevSearch);
    const nextParams = paramsToObject(nextSearch);
    const { asset, pair, source, orders } = nextParams;

    const {
      wallets,
      integrationData,
      setConnStatus,
      streamingData,
      setStreamingData,
      streamingStatusData,
      setStreamingStatusData,
      streamingUserData,
      setStreamingUserData,
      getBalances,
      getOpenOrders,
      getFilledOrders,
    } = nextProps;

    const chartsReady = asset && pair && source;
    const tradeReady = asset && pair && source && integrationData;
    const portfolioReady = pair && wallets;
    const pageChange = prevPath !== nextPath;
    const paramChange = prevParams.asset !== nextParams.asset || prevParams.source !== nextParams.source;

    const args = {
      asset,
      pair,
      source,
      orders,
      wallets,
      nextPath,
      setConnStatus,
      streamingData,
      setStreamingData,
      streamingStatusData,
      setStreamingStatusData,
      streamingUserData,
      setStreamingUserData,
      integrationData,
      getBalances,
      getOpenOrders,
      getFilledOrders,
    };

    let walletsChange;

    if (prevProps.wallets && portfolioReady) walletsChange = !isEqual(prevProps.wallets.length, wallets.length);
    if ((pageChange || paramChange || walletsChange) && socketDataLoaded) socketDataLoaded = false;

    if (chartsReady && !socketDataLoaded && nextPath === '/charts') {
      socketDataLoaded = true;
      connectSocket(args);
    };

    if (portfolioReady && !socketDataLoaded && nextPath === '/portfolio') {
      socketDataLoaded = true;
      connectSocket(args);
    };

    if (tradeReady && !socketDataLoaded && nextPath === '/trade') {
      socketDataLoaded = true;
      connectCBSocket(args);
    }
  };

  return false;
};

export default withRouter(memo(CCCWebsocket, cacheComponent));