/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { chartsActions, chartsSelectors } from 'modules/charts/chartsDuck';
import { tradingActions, tradingSelectors } from 'modules/trading/tradingDuck';
import { streamingSelectors } from 'modules/streaming/streamingDuck';
import { paramsFromURL, paramsToString, paramsToObject } from 'helpers/paramHelpers';
import { deviceFormat, formatWholeNum } from 'helpers/utilityFunctions';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import { isEmpty } from 'lodash';
import BotIndicator from 'components/BotIndicator/BotIndicator';
import TradeForms from './TradeForms/TradeForms';
import TradeDetails from './TradeDetails/TradeDetails';
import TradeSettings from './TradeSettings/TradeSettings';
import TradeActions from './TradeActions/TradeActions';
import OpenOrders from './Orders/OpenOrders';
import FilledOrders from './Orders/FilledOrders';
import Toggle from 'components/Toggle/Toggle';
import Tabs from 'components/Tabs/Tabs';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './Trading.scss';

const itemsPerPage = 20;
const timeframes = ['1m', '5m', '1H', '3H', '4H', '6H', '1D', '1W']; // Hiding '15m' and '12H' -- data response is not complete.
const defaultSources = ['Coinbase'];
const orderTabs = ['Open', 'Filled'];
const actionTabs = ['Buy', 'Sell']; // TODO: Add Deposit and Withdraw
const assets = [{
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  {
    name: 'Ripple',
    symbol: 'XRP',
  },
];

const defaultParams = search => {
  if (search !== '') return paramsToObject(search);
  return paramsToObject('?asset=BTC&pair=USD&timeframe=4H&source=CCCAGG&subChart=MACD');
};

const resizeActions = (e, setDimensions, mounted) => {
  const element = e.currentTarget;
  const newHeight = element.innerHeight;
  const newWidth = element.innerWidth;
  if (mounted) setDimensions({ width: newWidth, height: newHeight });
};

const Trading = props => {
  const { search } = useLocation();
  const [ dimensions, setDimensions ] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [ privacy, setPrivacy ] = useState(false);
  const [ sources, setSources] = useState(defaultSources);
  const [ firstDataLoad, setFirstDataLoad ] = useState(true);
  const [ showSettings, setShowSettings ] = useState(false);
  const [ activeOrderTab, setActiveOrderTab ] = useState(orderTabs[0]);
  const [ activeActionTab, setActiveActionTab ] = useState(actionTabs[0]);
  const [ modalState, setModalState ] = useState(defaultModal);
  const [ myParams, setMyParams ] = useState(defaultParams(search));
  const { asset, pair, timeframe, source, orders } = myParams;
  const dispatch = useDispatch();
  const history = useHistory();
  const mounted = useRef(true);
  const settingsPanel = useRef();
  const params = paramsToObject(paramsFromURL());

  // Selectors
  const settings = useSelector(state => appSelectors.settings(state));
  const assetData = useSelector(state => chartsSelectors.assetData(state));
  const balances = useSelector(state => tradingSelectors.balances(state));
  const products = useSelector(state => tradingSelectors.products(state));

  // Actions
  const getAssetData = (asset, pair, timeframe, source) => dispatch(chartsActions.getAssetData(asset, pair, timeframe, source));
  const setSettings = payload => dispatch(appActions.setSettings(payload));
  const getSettings = () => dispatch(appActions.getSettings());
  const getBalances = () => dispatch(tradingActions.getBalances());
  const getOpenOrders = product_id => dispatch(tradingActions.getOpenOrders(product_id));
  const getFilledOrders = product_id => dispatch(tradingActions.getFilledOrders(product_id));
  const getProducts = () => dispatch(tradingActions.getProducts());

  useEffect(() => {
    getSettings();
    getProducts();

    window.addEventListener('resize', e => resizeActions(e, setDimensions, mounted.current));

    return () => {
      mounted.current = false;
      window.removeEventListener('resize', e => resizeActions(e, setDimensions, mounted.current));
    };
  }, []);

  useEffect(() => {
    if (settings) {
      const { trade: tradeParams } = settings.pages;
      const saved = paramsToObject(tradeParams);

      setMyParams(saved);
      history.push(`/trade${tradeParams}`);

      if (firstDataLoad) {
        getAssetData(saved.asset, saved.pair, saved.timeframe, saved.source);
        setFirstDataLoad(false);
      };
    };
  }, [settings]);

  const changeParam = (param, newVal) => {
    const newParams = { ...myParams, [param]: newVal };

    setMyParams(newParams);
    history.push(`/trade${paramsToString(newParams)}`);

    if ((`${newParams.asset}${newParams.pair}` !== `${asset}${pair}`) ||
        (param === 'timeframe' || param === 'source')) {
      getAssetData(newParams.asset, newParams.pair, newParams.timeframe, newParams.source);
    }

    if (param === 'asset' || param === 'pair' || param === 'orders') {
      const product_id = `${newParams.asset}-${newParams.pair}`;

      if (param === 'asset' || param === 'pair') {
        getBalances();
      }

      newParams.orders === 'Open' && getOpenOrders(product_id);
      newParams.orders === 'Filled' && getFilledOrders(product_id);
    }

    if (settings) {
      const payload = { ...settings };
      payload.pages.trade = paramsToString(newParams);
      setSettings(payload);
    }
  };

  const toggleSettingsPanel = () => {
    const panel = settingsPanel.current;

    if (panel) {
      if (!showSettings) {
        panel.style.setProperty("display", "block");
        setTimeout(() => {
          panel.style.setProperty("opacity", 1);
          panel.style.setProperty("transform", "translateX(-0.5rem)");
        }, 200);
      } else {
        panel.style.setProperty("opacity", 0);
        panel.style.setProperty("transform", "translateX(0)");
        setTimeout(() => panel.style.setProperty("display", "none"), 575);
      }
    }

    setShowSettings(!showSettings)
  };

  const balance = symbol => {
    const product = balances.find(product => product.currency === symbol.toUpperCase());

    if (product) {
      const workingBalance = parseFloat(product.available).toFixed(symbol === 'USD' ? 2 : 8);
      const workingHold = parseFloat(product.hold).toFixed(symbol === 'USD' ? 2 : 8);
      const data = {
        available: formatWholeNum(workingBalance.toString()),
        hold: formatWholeNum(workingHold.toString()),
      };

      return data;
    }

    return 'N/A';
  }

  return (
    <Fragment>
      <div id="tradingBody">
        <div className="tradingTitle">
          <h2>Trade</h2>
        </div>

        <div className="pairToggle">
          <p className="strong">BTC</p> &nbsp;&nbsp;
          {settings && params.pair && (
            <Toggle
              disabled={asset === 'BTC'}
              defaultVal={params.pair === 'BTC'}
              callback={newVal => changeParam('pair', newVal ? 'BTC' : 'USD')} />
          )}
        </div>

        <div className="tradingSettings">
          <Button
            icon={showSettings ? 'settingsActive' : 'settingsInactive'}
            iconColor={showSettings ? colors.white : colors.grey}
            btnStyle="transparent"
            callback={() => {
              const format = deviceFormat(dimensions.width, dimensions.height);

              if (format === 'Desktop') {
                toggleSettingsPanel();
              } else {
                openModal(setModalState, null, 'Settings',
                  <div ref={settingsPanel} className="mobileSettingsContainer">
                    <TradeSettings
                      format={format}
                      settings={settings}
                      setSettings={newVal => setSettings(newVal)} />
                  </div>
                );
              }
            }} />
        </div>

        <div className="main">
          <TradeDetails
            source={source}
            sources={sources}
            timeframe={timeframe}
            timeframes={timeframes}
            asset={asset}
            assets={assets}
            pair={pair}
            products={products}
            assetData={assetData}
            myParams={myParams}
            changeAsset={newAsset => changeParam('asset', newAsset.toUpperCase())}
            changeTime={newTime => changeParam('timeframe', newTime)}
            changeSource={newSource => changeParam('source', newSource)} />

          {/* <div className="analysis"> */}
            {/* arrow-up, arrow-right, arrow-down */}
            {/* <BotIndicator icon="fas fa-arrow-right" title="Trend" color={colors.grey} /> */}

            {/* thumbs-up, thumbs-down */}
            {/* <BotIndicator icon="fas fa-thumbs-down" title="Volume" color={colors.yellow} /> */}

            {/* slowest, slow, average, fast, fastest */}
            {/* <BotIndicator icon="fas fa-tachometer-alt-average" title="Value" color={colors.grey} /> */}

            {/* angry, frown, meh, smile-beam, grin-stars */}
            {/* <BotIndicator icon="fas fa-meh" title="Mood" color={colors.grey} /> */}
          {/* </div> */}

          <div className="tradeActions">
            <div className="balancesGroup">
              <div className="balances">
                <p className="walletVal">
                  <span style={{ fontWeight: 'bold' }}>{pair}</span> &nbsp;
                  {balances && balance(pair).available}
                </p>

                <p className="walletVal">
                  <span style={{ fontWeight: 'bold' }}>{asset}</span> &nbsp;
                  {balances && balance(asset).available}
                </p>
              </div>

              <TradeActions
                actionTabs={actionTabs}
                activeActionTab={activeActionTab}
                setActiveActionTab={newVal => setActiveActionTab(newVal)} />

              {products && balances && (
                <TradeForms
                  mode={activeActionTab}
                  product={products.filter(product => (
                    product.base_currency === asset && product.quote_currency === pair
                  ))[0]}
                  balances={balances.filter(product => (
                    product.currency === asset || product.currency === pair
                  ))} />
              )}
            </div>
          </div>

          <p className="note">
            <i className="fas fa-exclamation-triangle yellow" /> &nbsp;
            Your funds are on <Link to={`//pro.coinbase.com`} target="_blank">Coinbase Pro</Link> for active trading.
            Take profits often and secure unused assets on a <Link to="//ledger.com" target="_blank">cold storage wallet</Link>.
          </p>

          <div className="orders">
            <Tabs
              tabs={orderTabs}
              activeTab={orders}
              onChange={newVal => changeParam('orders', newVal)} />

            {orders === 'Open' && (
              <OpenOrders
                asset={asset}
                pair={pair}
                privacy={privacy}
                format={deviceFormat(dimensions.width, dimensions.height)}
                itemsPerPage={itemsPerPage} />
            )}

            {orders === 'Filled' && (
              <FilledOrders
                asset={asset}
                pair={pair}
                privacy={privacy}
                format={deviceFormat(dimensions.width, dimensions.height)}
                itemsPerPage={itemsPerPage} />
            )}
          </div>
        </div>

        <div ref={settingsPanel} className="settingsContainer">
          <TradeSettings asset={asset} pair={pair} orders={orders} />
        </div>
      </div>

      {showModal(modalState, setModalState)}
    </Fragment>
  );
};

export default Trading;