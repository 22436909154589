import { apiActions } from "modules/api/apiDuck";

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

export const login = (payload, callback, type) => {
  return apiActions.request({
    path: 'auth',
    method: 'POST',
    type,
    payload,
    callback,
  });
}

export const createAccount = (payload, callback, type) => {
  return apiActions.request({
    path: 'users',
    method: 'POST',
    type,
    payload,
    callback,
  });
}

export const updateAccount = (payload, callback, type) => {
  return apiActions.request({
    path: 'users',
    method: 'PUT',
    type,
    payload,
    callback,
  });
}

export const deleteAccount = (payload, type) => {
  return apiActions.request({
    path: 'users',
    method: 'DELETE',
    type,
    payload,
  });
}

export const resetPassword = (payload, callback, type) => {
  return apiActions.request({
    path: 'emails/reset',
    method: 'POST',
    type,
    payload,
    callback,
  })
}

export const logout = type => ({ type });

export const checkToken = (payload, type) => {
  return apiActions.request({
    path: 'users/me',
    method: 'POST',
    type,
    payload,
  });
}

export const getSettings = type => {
  return apiActions.request({
    path: 'settings',
    method: 'GET',
    type,
  });
}

export const setSettings = (payload, type) => {
  return apiActions.request({
    path: 'settings',
    method: 'PUT',
    payload,
    type,
  });
}