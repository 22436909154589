import React from 'react';
import Button from 'components/Button/Button';
import * as colors from 'theme/colors.scss';
import './Pagination.scss';

const Pagination = props => {
  const { totalPages, currentPage, changePage } = props;

  const buildPagination = () => {
    const pageButtons = [];

    for (let i = 1; i <= totalPages; i++) {
      pageButtons.push(
        <Button
          key={i}
          icon="circle"
          iconColor={i === currentPage ? colors.lightGrey : colors.darkGrey}
          btnStyle="transparent"
          callback={() => currentPage !== i && changePage(i)} />
      );
    };

    return pageButtons;
  };

  return (
    <div className="pagination">
      <Button
          icon="back"
          iconColor={colors.grey}
          btnStyle="transparent"
          disabled={currentPage === 1}
          callback={() => currentPage !== 1 && changePage(currentPage - 1)} />

      <span className="pages">
        {buildPagination()}
      </span>

      <Button
          icon="next"
          iconColor={colors.grey}
          btnStyle="transparent"
          disabled={currentPage === totalPages}
          callback={() => currentPage !== totalPages && changePage(currentPage + 1)} />
    </div>
  );
}

export default Pagination;