import React from 'react';
import BuySell from './BuySell/BuySell';
import './TradeForms.scss';

const TradeForms = props => {
  const { mode, product, balances } = props;

  return (
    <div className="tradeForms">
      {mode === 'Deposit' && null}

      {(mode === 'Buy' || mode === 'Sell') && (
        <BuySell mode={mode} product={product} balances={balances} />
      )}

      {mode === 'Withdraw' && null}
    </div>
  );
};

export default TradeForms;