import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { chartsActions, chartsSelectors } from 'modules/charts/chartsDuck';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import { isEmpty } from 'lodash';
import { abbreviateNum } from 'helpers/utilityFunctions';
import AssetDetails from '../AssetDetails/AssetDetails';
import * as colors from 'theme/colors.scss';
import './MarketcapCard.scss';

const RowCard = props => {
  const { btcPairs, details, currentPair, activeDetails, setActiveDetails, mobilePanel } = props;
  const [ modalState, setModalState ] = useState(defaultModal);
  const dispatch = useDispatch();

  const {
    id,
    imageUrl,
    rank,
    name,
    fullName,
    marketCap,
    price,
    volume,
    supply,
    change,
    dominance,
  } = details;

  // Selectors
  const favorites = useSelector(state => chartsSelectors.favorites(state));

  // Actions
  const addToFavorites = payload => dispatch(chartsActions.addToFavorites(payload));
  const deleteFavoriteById = payload => dispatch(chartsActions.deleteFavoriteById(payload));

  const toggleFav = () => {
    if (favorites) {
      const index = favorites.indexOf(id);
      if (index === -1 || isEmpty(favorites)) addToFavorites({ id });
      if (index !== -1) deleteFavoriteById({ id });
    };
  };

  const rowClick = (e, mode) => {
    e.stopPropagation();

    if (mode === 'favourite') return toggleFav();

    activeDetails.name !== name && setActiveDetails(details);
    return mobilePanel && openModal(
      setModalState, id, '',
      <AssetDetails details={details} btcPairs={currentPair === 'BTC'} />
    );
  };

  const buildCard = () => {
    const changeStyle = {
      color: (name !== currentPair && change < 0) ? colors.yellow : colors.white,
      fontWeight: 'bold'
    };
    const favoriteStyles = {
      color: favorites && favorites.indexOf(id) !== -1 ? colors.yellow : colors.lightGrey
    };
    const getChange = () => {
      if (name !== currentPair) {
        return `${change > 0 ? '+' : ''}${
          change !== null ?
          `${change.toFixed(2).toLocaleString(undefined, {maximumFractionDigits: 2})}%` :
          '--'
        }`;
      }

      return '0.00%';
    };

    return (
      <div className="marketcapCard" onClick={e => rowClick(e)}>
        <div className="symbol strong">
          <span className="name strong">{name}</span>
        </div>

        <div className="rank strong">
          {rank}
        </div>

        <div className="favorite" style={favoriteStyles} onClick={e => rowClick(e, 'favourite')}>
          {favorites && favorites.indexOf(id) !== -1 ?
            <i id="favoriteIcon" className="fas fa-star" /> :
            <i id="favoriteIcon" className="far fa-star" />
          }
        </div>

        <div id="assetName" className="name strong">
          <img src={`https://cryptocompare.com${imageUrl}`} alt={name} />
          <span className="fullName">{fullName}</span>
        </div>

        <div className="marketcap">
          <i className={btcPairs ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
          {abbreviateNum(marketCap.toFixed(2))}
        </div>

        <div className="dominance">
          {dominance}%
        </div>

        <div className="volume">
          <i className={btcPairs ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
          {abbreviateNum(volume.toFixed(2))}
        </div>

        <div className="supply">
          <span className="name strong">{name}</span>
          {abbreviateNum(supply.toFixed(2))}
        </div>

        <div className="price" style={changeStyle}>
          <i className={btcPairs ? 'fab fa-btc' : 'fad fa-dollar-sign'} />&nbsp;
          {btcPairs ? price.toFixed(8) : price.toLocaleString()}
        </div>

        <div className="pctChange strong" style={changeStyle}>
          {getChange()}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {buildCard()}
      {showModal(modalState, setModalState)}
    </Fragment>
  );
}

export default RowCard;