/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { fromJS } from 'immutable';
import * as api from './streamingApi';

// Constants
const SET_STREAMING_DATA = 'modules/streaming/SET_STREAMING_DATA';
const SET_STREAMING_STATUS_DATA = 'modules/streaming/SET_STREAMING_STATUS_DATA';
const SET_STREAMING_USER_DATA = 'modules/streaming/SET_STREAMING_USER_DATA';
const SET_CONNECTION_STATUS = 'modules/streaming/SET_CONNECTION_STATUS';
const STATE_KEY_STREAMING_DATA = 'streamingData';
const STATE_KEY_STREAMING_STATUS_DATA = 'streamingStatusData';
const STATE_KEY_STREAMING_USER_DATA = 'streamingUserData';
const STATE_KEY_CONNECTION_STATUS = 'connStatus';

// Reducer
export const reducer = (initialState = {}, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case SET_CONNECTION_STATUS:
      return state.set(STATE_KEY_CONNECTION_STATUS, action.payload).toJS();
    case SET_STREAMING_DATA:
      return state.set(STATE_KEY_STREAMING_DATA, action.payload).toJS();
    case SET_STREAMING_STATUS_DATA:
      return state.set(STATE_KEY_STREAMING_STATUS_DATA, action.payload).toJS();
    case SET_STREAMING_USER_DATA:
      return state.set(STATE_KEY_STREAMING_USER_DATA, action.payload).toJS();

    default:
      return initialState;
  };
};

// Actions
export const streamingActions = {
  setConnStatus: payload => api.setConnStatus(payload, SET_CONNECTION_STATUS),
  setStreamingData: payload => api.setStreamingData(payload, SET_STREAMING_DATA),
  setStreamingStatusData: payload => api.setStreamingStatusData(payload, SET_STREAMING_STATUS_DATA),
  setStreamingUserData: payload => api.setStreamingUserData(payload, SET_STREAMING_USER_DATA),
};

// Selectors
export const streamingSelectors = {
  connStatus: state => state.modules.streaming[STATE_KEY_CONNECTION_STATUS],
  streamingData: state => state.modules.streaming[STATE_KEY_STREAMING_DATA],
  streamingStatusData: state => state.modules.streaming[STATE_KEY_STREAMING_STATUS_DATA],
  streamingUserData: state => state.modules.streaming[STATE_KEY_STREAMING_USER_DATA],
};

export default reducer;