/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from 'd3';
import 'd3-selection-multi';
import { ichimokuCloud } from 'helpers/utilityFunctions';
import './ichimokuCloud.scss';

const generateIchimokuCloud = (
    OHLC,
    visibleRange,
    ichimokuPeriods,
    chart,
    xScale,
    yScale,
    pathGen,
    timestamps,
    ichimokuCloudState,
    setIchimokuCloudState
  ) => {
  const { active, range } = ichimokuCloudState;

  if (active) {
    const ichimokuData = new ichimokuCloud(OHLC, ichimokuPeriods).calc();
    let conversionLine = ichimokuData.conversionLine; // Tenkan
    let baseLine = ichimokuData.baseLine; // Kijun
    let leadingSpanA = ichimokuData.leadingSpanA; // Senkou A
    let leadingSpanB = ichimokuData.leadingSpanB; // Senkou B
    let laggingSpan = ichimokuData.laggingSpan; // Chikou

    conversionLine = conversionLine.slice(
      conversionLine.length - visibleRange);

    baseLine = baseLine.slice(
      baseLine.length - visibleRange);

    leadingSpanA = leadingSpanA.slice(
      leadingSpanA.length - visibleRange - ichimokuPeriods[3],
      leadingSpanA.length - ichimokuPeriods[3]);

    leadingSpanB = leadingSpanB.slice(
      leadingSpanB.length - visibleRange - ichimokuPeriods[3],
      leadingSpanB.length - ichimokuPeriods[3]);

    laggingSpan = laggingSpan.slice(
      laggingSpan.length - visibleRange);

    const ichimokuMinMax = [
      d3.min(conversionLine), d3.max(conversionLine),
      d3.min(baseLine), d3.max(baseLine),
      d3.min(leadingSpanA), d3.max(leadingSpanA),
      d3.min(leadingSpanB), d3.max(leadingSpanB),
      d3.min(laggingSpan), d3.max(laggingSpan)
    ];

    const minMax = [d3.min(ichimokuMinMax), d3.max(ichimokuMinMax)];

    if (range[0] !== minMax[0] || range[1] !== minMax[1]) {
      setIchimokuCloudState({ ...ichimokuCloudState, range: minMax });
    }

    const areaGenBull = d3.area()
      .defined((d, i) => leadingSpanA[i] > leadingSpanB[i])
      .x((d, i) => xScale(timestamps[i]))
      .y0((d, i) => yScale(leadingSpanA[i]))
      .y1((d, i) => yScale(leadingSpanB[i]))
      .curve(d3.curveMonotoneX) // options are: d3.curveMonotoneX or d3.curveLinear

    const areaGenBear = d3.area()
    .defined((d, i) => leadingSpanA[i] < leadingSpanB[i])
    .x((d, i) => xScale(timestamps[i]))
    .y0((d, i) => yScale(leadingSpanA[i]))
    .y1((d, i) => yScale(leadingSpanB[i]))
    .curve(d3.curveMonotoneX) // options are: d3.curveMonotoneX or d3.curveLinear

    const lagging = chart.append('path')
      .attrs({
        class: 'laggingSpan',
        d: pathGen(laggingSpan)
      })

    const cloudAreaBull = chart.append('path')
      .attrs({
        class: 'bullishArea',
        d: areaGenBull(leadingSpanA, leadingSpanB)
      })

    const cloudAreaBear = chart.append('path')
    .attrs({
      class: 'bearishArea',
      d: areaGenBear(leadingSpanA, leadingSpanB)
    })

    const conversion = chart.append('path')
      .attrs({
        class: 'conversionLine',
        d: pathGen(conversionLine)
      })

    const base = chart.append('path')
    .attrs({
      class: 'baseLine',
      d: pathGen(baseLine)
    })

    const spanA = chart.append('path')
      .attrs({
        class: 'leadingSpanA',
        d: pathGen(leadingSpanA)
      })

    const spanB = chart.append('path')
    .attrs({
      class: 'leadingSpanB',
      d: pathGen(leadingSpanB)
    })
  } else {
    // Reset range if indicator is off
    if (range.length > 0) setIchimokuCloudState({ ...ichimokuCloudState, range: [] });
  };
};

export default generateIchimokuCloud;