/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import colors from 'theme/colors.scss';
import './ServerMessages.scss';

const msgHeight = 80;
let autoclose;

const MessageCard = props => {
  const { type, index, message, onClose } = props;
  const [ visible, setVisible ] = useState(false);

  const setAutoClose = () => {
    autoclose = setTimeout(() => {
      setVisible(false);
      onClose && onClose(index);
    }, 3000);
  };

  const clearAutoClose = () => clearTimeout(autoclose);

  useEffect(() => {
    setVisible(true);
  }, []);

  useEffect(() => {
    if (visible) setAutoClose();
    return () => clearAutoClose();
  }, [visible]);

  const iconColor = { color: type === 'error' ? colors.yellow : colors.green };

  const typeStyle = {
    top: index ? index * msgHeight : 16,
    borderLeft: `0.25rem solid ${type === 'error' ? colors.yellow : colors.green}`,
  };

  const closeMessage = id => {
    clearAutoClose();
    setVisible(false);
    onClose && onClose(id);
  };

  const getIcon = () => {
    if (type === 'error') return 'fal fa-exclamation-triangle';
    return 'fal fa-check-circle';
  }

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames="serverMessage"
      unmountOnExit
      {...props}>
        <div style={typeStyle} className="serverMessage">
          <i className={`${getIcon()} typeIcon`} style={iconColor} />
            <span>{message}</span>
          <i className="fad fa-times-circle closeIcon" onClick={() => closeMessage(index)} />
        </div>
    </CSSTransition>
  );
};

export default MessageCard;