import defaultSettings from 'helpers/defaultSettings';

export const checkValue = (newVal, defaultVal, minimum, maximum) => {
  const min = minimum || 1;
  const max = maximum || 200;
  let vettedVal = isNaN(newVal) ? defaultVal : newVal;

  if (newVal < min || newVal > max) {
    vettedVal = defaultVal;
  }

  return vettedVal;
};

export const resetDefaults = (indicator, settings, setSettings) => {
  const { indicators } = defaultSettings;
  const payload = { ...settings };
  payload.indicators[indicator] = indicators[indicator];

  setSettings(payload);
};