/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { apiActions } from 'modules/api/apiDuck';

export const placeOrder = (payload, callback, type) => {
  return apiActions.request({
    path: 'exchange/orders',
    method: 'POST',
    type,
    payload,
    callback,
  })
};

export const deposit = (payload, type) => {
  return apiActions.request({
    path: 'exchange/deposits',
    method: 'POST',
    type,
    payload,
  })
};

export const withdraw = (payload, type) => {
  return apiActions.request({
    path: 'exchange/withdrawals',
    method: 'POST',
    type,
    payload,
  })
};

export const getPaymentMethods = type => {
  return apiActions.request({
    path: 'exchange/payment-methods',
    method: 'GET',
    type,
  })
};

export const getCoinbaseAccounts = type => {
  return apiActions.request({
    path: 'exchange/coinbase-accounts',
    method: 'GET',
    type,
  })
};

export const getProducts = type => {
  return apiActions.request({
    path: 'exchange/products',
    method: 'GET',
    type,
  });
};

export const getBalances = type => {
  return apiActions.request({
    path: 'exchange/balances',
    method: 'GET',
    type,
  });
};

export const getOpenOrders = (product_id, type) => {
  return apiActions.request({
    path: `exchange/open-orders?product_id=${product_id}`,
    method: 'GET',
    type,
  });
};

export const cancelOrderById = (id, product_id, type) => {
  return apiActions.request({
    path: `exchange/open-orders/${id}?product_id=${product_id}`,
    method: 'DELETE',
    type,
  })
}

export const cancelAllOrders = (product_id, type) => {
  return apiActions.request({
    path: `exchange/open-orders?product_id=${product_id}`,
    method: 'DELETE',
    type,
  })
}

export const getFilledOrders = (product_id, type) => {
  return apiActions.request({
    path: `exchange/filled-orders?product_id=${product_id}`,
    method: 'GET',
    type,
  });
};

export const getIntegrationData = type => {
  return apiActions.request({
    path: 'exchange/integration',
    method: 'GET',
    type,
  })
}

export const setIntegrationData = (payload, type) => {
  return apiActions.request({
    path: 'exchange/integration',
    method: 'POST',
    type,
    payload,
  })
}

export const removeIntegrationData = type => {
  return apiActions.request({
    path: 'exchange/integration',
    method: 'DELETE',
    type,
  })
}