import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = props => {
  const { component: Component, loggedIn, ...rest } = props;

  const redirectCheck = componentProps => {
    if (loggedIn) {
      return <Component {...componentProps} {...rest} />;
    } else {
      return (
        <Redirect to={{
          pathname: '/login',
          state: { from: componentProps.location },
        }} />
    )};
  };

  return (
    <Route
      render={componentProps => redirectCheck(componentProps)}
      {...rest} />
  );
};

export default ProtectedRoute;