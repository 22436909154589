/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import colors from 'theme/colors.scss';
import './Nav.scss';

const Nav = props => {
  const { userInfo } = props;
  const history = useHistory();
  const location = useLocation();

  const menu = [
    { route: '/charts', icon: `${location.pathname === '/charts' ? 'fas' : 'fal'} fa-analytics` },
    { route: '/portfolio', icon: `${location.pathname === '/portfolio' ? 'fas' : 'fal'} fa-chart-pie` },
    { route: '/marketcap', icon: `${location.pathname === '/marketcap' ? 'fas' : 'fal'} fa-list` },
    { route: '/news', icon: `${location.pathname === '/news' ? 'fas' : 'fal'} fa-rss` },
  ];

  // Only show trading section to admins
  if (userInfo && userInfo.accountLevel.level === 5) {
    const trading = { route: '/trade', icon: `${location.pathname === '/trade' ? 'fas' : 'fal'} fa-handshake` };
    menu.splice(1, 0, trading);
  }

  const changeRoute = item => {
    if (location.pathname !== item.route) {
      history.push(`${item.route}`);
    };
  };

  const buildMainNav = () => {
    return menu.map(item => {
      const activePage = location.pathname === item.route;
      const navStyle = {
        color: activePage ? colors.lightGrey : colors.grey,
      };

      return (
        <i key={item.route}
          className={item.icon + ' navIcon'}
          style={navStyle}
          onClick={() => changeRoute(item)} />
      );
    });
  };

  return (
    <Fragment>
      <div className="mainNav nav">
        {buildMainNav()}
      </div>
    </Fragment>
  );
};

export default Nav;