/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */

import * as d3 from 'd3';
import * as crypto from 'crypto';

/* ============= Encryption Functions ============= */
const {
  REACT_APP_DECRYPTION_SECRET: decryptionKey,
  REACT_APP_ENCRYPTION_ALGORITHM: algorithm,
} = process.env;

export const encrypt = data => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(algorithm, Buffer.from(decryptionKey), iv);
  let encrypted = cipher.update(JSON.stringify(data));

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  const finalData = {
    iv: iv.toString('hex'),
    encryptedData: encrypted.toString('hex'),
  };

  return finalData;
};

export const decrypt = data => {
  const ivString = Buffer.from(data.iv, 'hex');
  const encryptedData = Buffer.from(data.encryptedData, 'hex');
  const decipher = crypto.createDecipheriv(algorithm, Buffer.from(decryptionKey), ivString);
  let decrypted = decipher.update(encryptedData);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  const finalData = JSON.parse(decrypted);

  return finalData;
};

export const signRequest = (timestamp, path, body, method, secret) => {
  const time = timestamp;
  const requestPath = path;
  const payload = body
  const reqType = method;
  const what = time + reqType + requestPath + payload;
  const encryptedKey = Buffer(secret, 'base64');
  const hmac = crypto.createHmac('sha256', encryptedKey);

  return hmac.update(what).digest('base64');
}

/* ============= General Functions ============= */

export const abbreviateNum = numStr => {
  // Note: input arg must be a string.
  const chunk = 3;

  if (numStr.length / chunk <= 3) return `${(numStr / 1000).toFixed(2)} K`  // K
  if (numStr.length / chunk <= 4) return `${(numStr / 1000000).toFixed(2)} M`  // M
  if (numStr.length / chunk <= 5) return `${(numStr / 1000000000).toFixed(2)} B` // B
  if (numStr.length / chunk <= 6) return `${(numStr / 1000000000000).toFixed(2)} T` // T

  return numStr;
};

export const formatWholeNum = str => {
  // Function formats the whole number portion of a string
  // while keeping the decimal count intact.
  let string = str;

  if (typeof string === 'number') string = str.toString();

  if (string !== '0' && string.includes('.')) {
    const split = string.split('.');
    const whole = parseInt(split[0]).toLocaleString();
    const combined = `${whole}.${split[1]}`;

    return combined;
  }

  return parseInt(string).toLocaleString();
};

export const stringToArray = string => {
  const prep = string.replace(/[\\W_,-]/g, "");
  const words = prep.split(' ');

  return words;
}

export const deviceFormat = (width, height) => {
  const landscape = width >= 900 && height > 550;
  const portrait = width >= 550 && height > 900;
  const userDesktop = width >= 550 && height >= 768;

  if (landscape || portrait || userDesktop) return 'Desktop';
  return 'Mobile';
};

export const makePageable = (rawData, pageLimit) => {
  const data = rawData;
  const totalData = data.length;
  const pageData = [];

  for(let i = 0; i < totalData; i += pageLimit) {
    const newPage = data.slice(i, i + pageLimit);
    pageData.push(newPage);
  };

  return pageData;
};

export const trimText = (body, limit, characterTrim) => {
  let trimText = body.substr(0, limit || 115);
  trimText = trimText.substr(0, Math.min(trimText.length, trimText.lastIndexOf(characterTrim ? '' : ' ')));

  return trimText;
};

export const getBaseUrl = url => {
  const splitUrl = url.split('//');
  const protocol = splitUrl[0];
  const baseUrl = splitUrl[1].split('/')[0];
  const site = `${protocol}//${baseUrl}`;

  return site;
};

export const rotate = button => {
  const element = button.current.firstChild.firstChild;
  const spinClass = 'fa-spin';

  element.classList.add(spinClass);
  setTimeout(() => element.classList.remove(spinClass), 1000);
}

export const countDecimals = value => {
  if (value) {
    const valIsStandard = value.includes('.');
    const valIsScientific = value.includes('1e-');

    if (valIsStandard) {
      if (Math.floor(value) === value) return 0;
      return value.toString().split('.')[1].length || 0;
    }

    if (valIsScientific) {
      return parseInt(value.split('1e-')[1]) || 0;
    }
  }

  return 0;
}

export const truncateNum = (num, index) => {
  const valIsGood = num.includes('.');

  if (valIsGood) {
    const split = num.split('.');
    const int = split[0];
    const floatVal = split[1].slice(0, index);
    const truncatedVal = `${int}.${floatVal}`;

    return truncatedVal;
  };

  return num;
}

/* ============= Time Functions ============= */
export const timeAgg = timeframe => {
  if (timeframe === '1m') return 'histominute?aggregate=1';
  if (timeframe === '5m') return 'histominute?aggregate=5';
  if (timeframe === '15m') return 'histominute?aggregate=15';
  if (timeframe === '30m') return 'histominute?aggregate=30';
  if (timeframe === '1H') return 'histohour?aggregate=1';
  if (timeframe === '3H') return 'histohour?aggregate=3';
  if (timeframe === '4H') return 'histohour?aggregate=4';
  if (timeframe === '6H') return 'histohour?aggregate=6';
  if (timeframe === '12H') return 'histohour?aggregate=12';
  if (timeframe === '1D') return 'histoday?aggregate=1';
  if (timeframe == '1W') return 'histoday?aggregate=7';
};

export const timeLeft = timeframe => {
  const date = new Date();
  const day = date.getUTCDay();
  const hour = date.getUTCHours();
  const min = date.getUTCMinutes();
  const sec = date.getUTCSeconds();
  let targetDate;

  if (timeframe === '1m') targetDate = `0:${59 - sec}`;
  if (timeframe === '1H') targetDate = `${59 - min}:${59 - sec}`;
  if (timeframe === '1D') targetDate = `${23 - hour}:${59 - min}:${59 - sec}`;
  if (timeframe === '1W') targetDate = `${day === 0 ? day : 7 - day}:${23 - hour}:${59 - min}:${59 - sec}`;

  if (timeframe === '5m') {
    const periods = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
    const thisPeriod = periods.filter(period => min < period)[0] - 1;
    targetDate = `${thisPeriod - min}:${59 - sec}`;
  };

  if (timeframe === '15m') {
    const periods = [15, 30, 45, 60];
    const thisPeriod = periods.filter(period => min < period)[0] - 1;
    targetDate = `${thisPeriod - min}:${59 - sec}`;
  };

  if (timeframe === '30m') {
    const periods = [30, 60];
    const thisPeriod = periods.filter(period => min < period)[0] - 1;
    targetDate = `${thisPeriod - min}:${59 - sec}`;
  };

  if (timeframe === '3H') {
    const periods = [3, 6, 9, 12, 18, 21, 24];
    const thisPeriod = periods.filter(period => hour < period)[0] - 1;
    targetDate = `${thisPeriod - hour}:${59 - min}:${59 - sec}`;
  };

  if (timeframe === '4H') {
    const periods = [4, 8, 12, 16, 20, 24];
    const thisPeriod = periods.filter(period => hour < period)[0] - 1;
    targetDate = `${thisPeriod - hour}:${59 - min}:${59 - sec}`;
  };

  if (timeframe === '6H') {
    const periods = [6, 12, 18, 24];
    const thisPeriod = periods.filter(period => hour < period)[0] - 1;
    targetDate = `${thisPeriod - hour}:${59 - min}:${59 - sec}`;
  };

  if (timeframe === '12H') {
    const periods = [12, 24];
    const thisPeriod = periods.filter(period => hour < period)[0] - 1;
    targetDate = `${thisPeriod - hour}:${59 - min}:${59 - sec}`;
  };

  const parts = targetDate.toString().split(':');
  const newParts = [];

  parts.forEach(part => part < 10 ? newParts.push(`0${part}`) : newParts.push(part));

  const cleanDate = newParts.join(':');
  return cleanDate;
};

/* ============= Indicator Functions ============= */

export class SMA {
  constructor(data, period) {
    this.data = data;
    this.period = period;
  }

  calc() {
    const SMA = [];

    this.data.forEach((dataPoint, index) => {
      // Calc only if there is enough data.
      if (index >= this.period) {
        const range = this.data.slice(index - this.period + 1, index + 1);
        let total = 0;

        range.forEach(val => total += val);

        const avg = total / range.length;

        SMA.push(avg);
      } else {
        SMA.push(null);
      }
    });

    return SMA;
  }
}

export class EMA {
  constructor(data, period) {
    this.data = data;
    this.period = period;
  }

  calc() {
    const multiplier = 2 / (this.period + 1);
    const EMA = [];

    this.data.forEach((dataPoint, index) => {
      // Calc only if there is enough data.
      if (index >= this.period) {
        const range = this.data.slice(index - this.period + 1, index + 1);

        if (index === this.period) {
          let total = 0;
          range.forEach(val => total += val);
          const firstVal = total / this.period;

          EMA.push(firstVal);
        } else {
          const emaVal = (dataPoint - EMA[index - 1]) * multiplier + EMA[index - 1];

          EMA.push(emaVal);
        }

      } else {
        EMA.push(null);
      }
    });

    return EMA;
  }
}

export class standardDeviation {
  constructor(data) {
    this.data = data;
  }

  findMean(data) {
    let workingVal = 0;
    let emptyVals = 0;

    data.forEach(dataPoint => dataPoint !== null ? workingVal += dataPoint : emptyVals += 1);
    const mean = workingVal / (data.length - emptyVals);
    return mean;
  }

  sqData(data, mean) {
    const workingVals = [];

    data.forEach(dataPoint => {
      if (dataPoint === null) {
        workingVals.push(null);
      } else {
        const result = dataPoint - mean;
        workingVals.push(result * result);
      }
    })

    return workingVals;
  }

  calc() {
    const mean = this.findMean(this.data);
    const sqData = this.sqData(this.data, mean);
    const sqDifferenceMean = this.findMean(sqData);
    const deviation = Math.sqrt(sqDifferenceMean);

    return deviation;
  }
}

export class bollingerBands {
  constructor(data, period, deviationMultiplier) {
    this.data = data;
    this.period = period;
    this.deviationMultiplier = deviationMultiplier;
  }

  calc() {
    const bands = {
      upper: [],
      middle: new SMA(this.data, this.period).calc(),
      lower: []
    };

    bands.middle.forEach((dataPoint, index) => {
      // Calc only if there is enough data.
      if (index >= this.period) {
        const range = this.data.slice(index - this.period + 1, index + 1);
        const bollingerDeviation = new standardDeviation(range).calc() * this.deviationMultiplier;

        bands.upper.push(dataPoint + bollingerDeviation);
        bands.lower.push(dataPoint - bollingerDeviation);
      } else {
        bands.upper.push(null);
        bands.lower.push(null);
      }
    });

    return bands;
  }
}

export class RSI {
  constructor(close, period) {
    this.close = close;
    this.period = period;
  }

  getGainLoss() {
    const gains = [0];
    const losses = [0];

    this.close.forEach((currentClose, index) => {
      if (index > 0) {
        const prevClose = this.close[index - 1];
        const change = currentClose - prevClose;

        if (change >= 0) {
          gains.push(change);
          losses.push(0);
        } else {
          gains.push(0);
          losses.push(change * -1);
        }

      }
    });

    const gainsLosses = {
      gains: gains,
      losses: losses
    }

    return gainsLosses;
  }

  getUpDownAverages(gainLoss) {
    const rawGains = gainLoss.gains;
    const rawLosses = gainLoss.losses;
    const avgGain = [];
    const avgLoss = [];

    rawGains.forEach((val, index) => {
      // Only begin if there is enough data.
      if (index >= this.period) {
        const gainRange = rawGains.slice(index - this.period, index);
        const lossRange = rawLosses.slice(index - this.period, index);

        // First avg value as a simple average
        if (index === this.period) {
          let tempGain = 0;
          let tempLoss = 0;
          let firstAvgGain = 0;
          let firstAvgLoss = 0;

          gainRange.forEach(gain => tempGain += gain);
          lossRange.forEach(loss => tempLoss += loss);

          firstAvgGain = tempGain / gainRange.length;
          firstAvgLoss = tempLoss / lossRange.length;

          avgGain.push(firstAvgGain);
          avgLoss.push(firstAvgLoss);
        } else {
          // Remaining avg values: ((prevAvg * (period - 1) + currentRawGainOrLossVal) / period);
          const gain = ((avgGain[index - 1] * (this.period - 1) + val) / this.period);
          const loss = ((avgLoss[index - 1] * (this.period - 1) + rawLosses[index]) / this.period);

          avgGain.push(gain);
          avgLoss.push(loss);
        }

      } else {
        avgGain.push(null);
        avgLoss.push(null);
      }
    });

    const avg = {
      gain: avgGain,
      loss: avgLoss
    }

    return avg;
  }

  findRS(avg) {
    const rs = [];

    avg.gain.forEach((dataPoint, index) => {
      if (dataPoint !== null) {
        rs.push(dataPoint / avg.loss[index]);
      } else {
        rs.push(null);
      }
    });

    return rs;
  }

  findRSI(rs) {
    const rsi = [];

    rs.forEach(dataPoint => {
      rsi.push(100 - 100 / (1 + dataPoint));
    });

    return rsi;
  }

  calc() {
    const gainLoss = this.getGainLoss();
    const avg = this.getUpDownAverages(gainLoss);
    const rs = this.findRS(avg);
    const rsi = this.findRSI(rs);

    return rsi;
  }
}

export class stochRSI {
  constructor(OHLC, kSmooth, dSmooth, rsiPeriod) {
    this.OHLC = OHLC;
    this.kSmooth = kSmooth;
    this.dSmooth = dSmooth;
    this.rsiPeriod = rsiPeriod;
  }

  getStochVals() {
    const closeLowDeltaVals = [];
    const highLowDeltaVals = [];
    const stochVals = {
      fast: [],
      slow: [],
      signal: []
    };

    this.OHLC.close.forEach((close, index) => {
      // Calc fast stochastic (%K) if there is enough data.
      if (index >= this.rsiPeriod) {
        const highRange = this.OHLC.high.slice(index - this.rsiPeriod, index);
        const lowRange = this.OHLC.low.slice(index - this.rsiPeriod, index);
        const highestHigh = d3.max(highRange);
        const lowestLow = d3.min(lowRange);
        const closeLowDiff = close - lowestLow;
        const highLowDiff = highestHigh - lowestLow;
        const fastStoch = (closeLowDiff / highLowDiff) * 100;

        stochVals.fast.push(fastStoch);
        closeLowDeltaVals.push(closeLowDiff);
        highLowDeltaVals.push(highLowDiff);

        // Calc slow stochastic (%D) if there is enough data.
        if (index >= this.rsiPeriod + this.kSmooth) {
          const closeLowRange = closeLowDeltaVals.slice(index - this.kSmooth, index);
          const deltaRange = highLowDeltaVals.slice(index - this.kSmooth, index);
          let closeLowDeltaSum = 0;
          let highLowDeltaSum = 0;

          closeLowRange.forEach(rangeVal => closeLowDeltaSum += rangeVal);
          deltaRange.forEach(rangeVal => highLowDeltaSum += rangeVal);

          let slowStoch = (closeLowDeltaSum / highLowDeltaSum) * 100;

          if (slowStoch > 100) {
            slowStoch = 100;
          } else if (slowStoch < 0) {
            slowStoch = 0;
          }

          stochVals.slow.push(slowStoch);
        } else {
          stochVals.slow.push(null);
        }

        // Calc signal line from slow stochastic (%D) if there is enough data.
        if (index >= this.rsiPeriod + this.kSmooth + this.dSmooth) {
          const slowRange = stochVals.slow.slice(index - this.dSmooth, index);
          let slowSum = 0;

          slowRange.forEach(rangeVal => slowSum += rangeVal);

          const signalLine = slowSum / slowRange.length;

          stochVals.signal.push(signalLine);
        } else {
          stochVals.signal.push(null);
        }

      } else {
        closeLowDeltaVals.push(null);
        highLowDeltaVals.push(null);
        stochVals.fast.push(null);
        stochVals.slow.push(null);
        stochVals.signal.push(null);
      }
    });

    return stochVals;
  }

  calc() {
    const stochRSI = this.getStochVals();

    return stochRSI;
  }
}

export class ichimokuCloud {
  constructor(OHLC, periods) {
    this.OHLC = OHLC;
    this.conversionPeriod = periods[0];
    this.basePeriod = periods[1];
    this.leadingSpanBPeriod = periods[2];
    this.laggingPeriod = periods[3];
  }

  conversionData() {
    const conversionVals = [];

    this.OHLC.close.forEach((close, index) => {
      // Calc only if there is enough data.
      if (index >= this.conversionPeriod) {
        const highRange = this.OHLC.high.slice(index - this.conversionPeriod + 1, index + 1);
        const lowRange = this.OHLC.low.slice(index - this.conversionPeriod + 1, index + 1);
        const highestHigh = d3.max(highRange);
        const lowestLow = d3.min(lowRange);
        const conversionVal = (highestHigh + lowestLow) / 2;

        conversionVals.push(conversionVal);
      } else {
        conversionVals.push(null);
      }
    });

    return conversionVals;
  }

  baseLineData() {
    const baseLineVals = [];

    this.OHLC.close.forEach((close, index) => {
      // Calc only if there is enough data.
      if (index >= this.basePeriod) {
        const highRange = this.OHLC.high.slice(index - this.basePeriod + 1, index + 1);
        const lowRange = this.OHLC.low.slice(index - this.basePeriod + 1, index + 1);
        const highestHigh = d3.max(highRange);
        const lowestLow = d3.min(lowRange);
        const baseVal = (highestHigh + lowestLow) / 2;

        baseLineVals.push(baseVal);
      } else {
        baseLineVals.push(null);
      }
    });

    return baseLineVals;
  }

  leadingSpanAData(conversionData, baseLineData) {
    const leadingSpanAVals = [];

    baseLineData.forEach((baseLineData, index) => {
      if (index >= this.basePeriod) {
        const leadingSpanAVal = (conversionData[index] + baseLineData) / 2;

        leadingSpanAVals.push(leadingSpanAVal);
      } else {
        leadingSpanAVals.push(null);
      }
    });

    return leadingSpanAVals;
  }

  leadingSpanBData() {
    const leadingSpanBVals = [];

    this.OHLC.close.forEach((close, index) => {
      // Calc only if there is enough data.
      if (index >= this.leadingSpanBPeriod) {
        const highRange = this.OHLC.high.slice(index - this.leadingSpanBPeriod, index);
        const lowRange = this.OHLC.low.slice(index - this.leadingSpanBPeriod, index);
        const highestHigh = d3.max(highRange);
        const lowestLow = d3.min(lowRange);
        const leadingSpanBVal = (highestHigh + lowestLow) / 2;

        leadingSpanBVals.push(leadingSpanBVal);
      } else {
        leadingSpanBVals.push(null);
      }
    });

    return leadingSpanBVals;
  }

  laggingSpanData() {
    const laggingSpanVals = this.OHLC.close.map(close => close);

    // Add n days to end of array.
    for (let i = 0; i < this.laggingPeriod; i++) {
      laggingSpanVals.push(null);
    }

    // Remove n days from beginning of array.
    const newSpanVals = laggingSpanVals.slice(this.laggingPeriod, laggingSpanVals.length);

    return newSpanVals;
  }

  calc() {
    const conversionLine = this.conversionData(); // Tenkan-sen
    const baseLine = this.baseLineData(); // Kijun-sen
    const leadingSpanA = this.leadingSpanAData(conversionLine, baseLine); // Senkou A
    const leadingSpanB = this.leadingSpanBData(); // Senkou B
    const laggingSpan = this.laggingSpanData(); // Chikou

    // Displace values n days forward based on laggingPeriod
    // Lagging span array already displaced minus n days in it's method.
    for (let i = 0; i < this.laggingPeriod; i++) {
      leadingSpanA.unshift(null);
    }

    for (let i = 0; i < this.laggingPeriod; i++) {
      leadingSpanB.unshift(null);
    }

    const cloudData = {
      conversionLine: conversionLine,
      baseLine: baseLine,
      leadingSpanA: leadingSpanA,
      leadingSpanB: leadingSpanB,
      laggingSpan: laggingSpan
    }

    return cloudData;
  }
}