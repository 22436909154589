/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { marketcapActions, marketcapSelectors } from 'modules/marketcap/marketcapDuck';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { chartsActions, chartsSelectors } from 'modules/charts/chartsDuck';
import { isEmpty } from 'lodash';
import { deviceFormat } from 'helpers/utilityFunctions';
import { paramsFromURL, paramsToObject, paramsToString } from 'helpers/paramHelpers';
import ToTop from 'components/ToTop/ToTop';
import Filter from 'components/Filter/Filter';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Toggle from 'components/Toggle/Toggle';
import MarketcapTable from './MarketcapTable/MarketcapTable';
import * as colors from 'theme/colors.scss';
import './Marketcap.scss';

const limit = 100;
const desktopPanelBreakpoint = 1440;
const itemsPerPage = 100;

const resizeActions = (e, setFormat, setMobilePanel, mounted) => {
  const element = e.currentTarget;
  const newHeight = element.innerHeight;
  const newWidth = element.innerWidth;

  if (mounted) {
    setFormat(deviceFormat(newWidth, newHeight));
    setMobilePanel(newWidth <= desktopPanelBreakpoint);
  }
};

const scrollActions = (e, setMobilePanel, mounted) => {
  const posY = window.pageYOffset;
  const modalDetails = window.innerWidth <= desktopPanelBreakpoint;

  if (!modalDetails && mounted) {
    if (posY >= 220) setMobilePanel(true);
    if (posY < 220) setMobilePanel(false);
  };
};

const Marketcap = props => {
  const [ format, setFormat ] = useState(deviceFormat(window.innerWidth, window.innerHeight));
  const [ activeDetails, setActiveDetails ] = useState({});
  const [ mobilePanel, setMobilePanel ] = useState(window.innerWidth <= desktopPanelBreakpoint);
  const [ filteredData, setFilteredData ] = useState([]);
  const [ defaultDetails, setDefaultDetails ] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const params = paramsToObject(paramsFromURL());

  // Selectors
  const marketcap = useSelector(state => marketcapSelectors.marketcap(state));
  const settings = useSelector(state => appSelectors.settings(state));
  const favorites = useSelector(state => chartsSelectors.favorites(state));

  // Actions
  const getMarketcap = useCallback((limit, pair, callback) => dispatch(marketcapActions.getMarketcap(limit, pair, callback)), [dispatch]);
  const getSettings = () => dispatch(appActions.getSettings());
  const getFavorites = () => dispatch(chartsActions.getFavorites());
  const clearFavorites = () => dispatch(chartsActions.clearFavorites());
  const setSettings = payload => dispatch(appActions.setSettings(payload));
  const logout = () => dispatch(appActions.logout());

  // TODO: Optimise all renders being performed by getters/setters.
  useEffect(() => {
    getSettings();
    getFavorites();

    window.addEventListener('scroll', e => scrollActions(e, setMobilePanel, mounted.current));
    window.addEventListener('resize', e => resizeActions(e, setFormat, setMobilePanel, mounted.current));

    return () => {
      mounted.current = false;
      window.removeEventListener('scroll', e => scrollActions(e, setMobilePanel, mounted.current));
      window.removeEventListener('resize', e => resizeActions(e, setFormat, setMobilePanel, mounted.current));
    };
  }, []);

  useEffect(() => {
    if (settings) {
      const { market: marketParams } = settings.pages;

      params.pair && getMarketcap(limit, params.pair);
      setActiveDetails({});
      setDefaultDetails({});
      setFilteredData(null);

      if (marketParams !== paramsFromURL()) {
        history.push(`/marketcap${marketParams}`);
      };
    };
  }, [settings]);

  return (
    <Fragment>
      <div id="marketcapBody">
        <div className="capTitle">
          <h2>Market</h2>
        </div>

        <div className="capFilter">
          <Filter
            data={marketcap && marketcap.Data}
            placeholder="i.e. Bitcoin or BTC"
            reset={filteredData === null}
            match={["CoinInfo.FullName", "CoinInfo.Name"]}
            callback={newData => setFilteredData(newData)} />
        </div>

        <div className="capPairToggle">
          <p className="strong">BTC</p> &nbsp;&nbsp;
            {settings && params.pair && (
              <Toggle
              defaultVal={params.pair === 'BTC'}
              callback={newVal => {
                if (newVal) history.push('/marketcap?pair=BTC');
                if (!newVal) history.push('/marketcap?pair=USD');
                const payload = { ...settings };
                payload.pages.market = paramsFromURL();
                setSettings(payload);
              }} />
            )}
        </div>

        <MarketcapTable
          defaultDetails={defaultDetails}
          changeDefaultDetails={newDetails => setDefaultDetails(newDetails)}
          currentPair={params.pair}
          format={format}
          favorites={favorites}
          marketcap={marketcap}
          filteredData={filteredData}
          mobilePanel={mobilePanel}
          activeDetails={activeDetails}
          setActiveDetails={newDetails => setActiveDetails(newDetails)}
          itemsPerPage={itemsPerPage} />

        <ToTop />
      </div>
    </Fragment>
  );
}

export default Marketcap;