/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from 'd3';
import 'd3-selection-multi';
import './macdGenerator.scss';

const generateMACD = (chart, histogram, signalLine, macdLine, xScale, yScale, pathGen, timestamps) => {
  const histogramLines = chart.append('g').selectAll('line')
    .data(histogram)
    .enter()
    .append('line')
    .attrs({
      // class: 'histogram',
      class: (d, i) => d > 0 ? 'histogram histogramBull' : 'histogram histogramBear',
      x1: (d, i) => xScale(timestamps[i]),
      y1: (d, i) => yScale(0),
      x2: (d, i) => xScale(timestamps[i]),
      y2: d => yScale(d)
    })

  const secondaryLine = chart.append('path')
    .attrs({
      class: 'signalLine',
      d: pathGen(signalLine)
    })

  const primaryLine = chart.append('path')
    .attrs({
      class: 'macdLine',
      d: pathGen(macdLine)
    })
};

export default generateMACD;