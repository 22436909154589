/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { countDecimals, EMA } from 'helpers/utilityFunctions';
import { generateXAxis, generateYAxis } from '../CandleChart/indicators/candles';
import generateMACD from './macdGenerator';
import * as d3 from 'd3';
import 'd3-selection-multi';
import './MACD.scss';

const MACD = props => {
  const { format, data, pair, settings, timeframe } = props;
  const { indicators } = settings;
  const { macd: macdLengths } = indicators;
  const [ chartSize, setChartSize ] = useState({});
  const chartRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (chartRef.current && containerRef.current) {
      const container = containerRef.current;
      const width = container.clientWidth;
      const height = container.clientHeight;

      setChartSize({ width,  height });
    };
  }, [chartRef, containerRef]);

  useEffect(() => {
    setChartSize({ ...chartSize, height: format === 'Desktop' ? 280 : 240 });
  }, [format]);

  const getOHLC = () => {
    const yAxisWidth = 73 + 14;
    const candleWidth = 12.979591837;
    const clippedWidth = containerRef.current.clientWidth - yAxisWidth;
    const visibleRange = Math.floor(clippedWidth / candleWidth);
    const rawOHLC = {
      time: data.map(dataPoint => moment.unix(dataPoint.time).utc()._d),
      high: data.map(dataPoint => dataPoint.high),
      low: data.map(dataPoint => dataPoint.low),
      open: data.map(dataPoint => dataPoint.open),
      close: data.map(dataPoint => dataPoint.close),
      volume: data.map(dataPoint => dataPoint.volumeto),
    };


    const clippedOHLC = {
      time: data.map(dataPoint => moment.unix(dataPoint.time).utc()._d).slice(data.length - visibleRange),
      high: data.map(dataPoint => dataPoint.high).slice(data.length - visibleRange),
      low: data.map(dataPoint => dataPoint.low).slice(data.length - visibleRange),
      open: data.map(dataPoint => dataPoint.open).slice(data.length - visibleRange),
      close: data.map(dataPoint => dataPoint.close).slice(data.length - visibleRange),
      volume: data.map(dataPoint => dataPoint.volumeto).slice(data.length - visibleRange),
    };

    return { rawOHLC, clippedOHLC, visibleRange };
  };

  const buildChart = () => {
    const { height: chartHeight } = chartSize;
    const containerWidth = containerRef.current.clientWidth;
    const target = chartRef.current;
    const ohlcVals = getOHLC();
    const { rawOHLC, clippedOHLC: OHLC, visibleRange } = ohlcVals;
    const timestamps = OHLC.time;
    const margin = { top: 16, right: 96, bottom: 20, left: 0 };
    const height = chartHeight - margin.top - margin.bottom - 14;
    const width = containerWidth - margin.left - margin.right;
    const decimalFormat = pair === 'BTC' ? ',.8f' : `,.${countDecimals(d3.min(OHLC.low).toString())}f`;

    let histogram = [];
    let firstEMA = new EMA(rawOHLC.close, macdLengths[0]).calc();
    let secondEMA = new EMA(rawOHLC.close, macdLengths[1]).calc();
    let macdLine = [];

    secondEMA.forEach((dataPoint, index) => dataPoint === null ? macdLine.push(null) : macdLine.push(firstEMA[index] - dataPoint));

    let signalLine = new EMA(macdLine, macdLengths[2]).calc();
    signalLine.forEach((dataPoint, index) => histogram.push(macdLine[index] - dataPoint));

    firstEMA = firstEMA.slice(firstEMA.length - visibleRange);
    secondEMA = secondEMA.slice(secondEMA.length - visibleRange);
    signalLine = signalLine.slice(signalLine.length - visibleRange);
    macdLine = macdLine.slice(macdLine.length - visibleRange);
    histogram = histogram.slice(histogram.length - visibleRange);

    const minVals = [d3.min(signalLine), d3.min(histogram), d3.min(macdLine)];
    const maxVals = [d3.max(signalLine), d3.max(histogram), d3.max(macdLine)];
    const yDomain = [d3.min(minVals), d3.max(maxVals)]
    const yRange = [height, 0];

    if (target) target.innerHTML = '';

    const xScale = d3.scaleTime()
      .domain(d3.extent(timestamps, d => d))
      .range([0, width]);

    const yScale = d3.scaleLinear()
        .domain(yDomain).nice()
        .range(yRange);

    const pathGen = d3.line()
      .defined((d, i) => d)
      .x((d, i) => xScale(timestamps[i]))
      .y(d => yScale(d))
      .curve(d3.curveMonotoneX); // d3.curveMonotoneX or d3.curveLinear

    const chart = d3.select(target).append('svg')
      .attrs({
        class: 'macdContainer',
        height: height + margin.top + margin.bottom,
        width: width + margin.left + margin.right,
      })
      .append('g');

    generateYAxis(chart, decimalFormat, (target.clientWidth - margin.left - margin.right), yScale, format);

    const macd = chart.append('g')
      .attrs({
        class: 'macdSvg',
        transform: `translate(${target.clientWidth - containerWidth}, 0)`,
      })
      .append('g')
      .attrs({ transform: `translate(${margin.left}, ${margin.top})` })

    generateXAxis(macd, height, xScale, format, timeframe);
    generateMACD(macd, histogram, signalLine, macdLine, xScale, yScale, pathGen, timestamps, decimalFormat);
  };

  return (
    <Fragment>
      <div ref={containerRef} className="macdContainer" >
        <div ref={chartRef}
          width={chartSize.width}
          height={chartSize.height}
          style={{ overflow: 'hidden' }} />
        {(data && !isEmpty(chartSize)) && buildChart()}
      </div>
    </Fragment>
  );
};

export default MACD;