/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { streamingSelectors } from 'modules/streaming/streamingDuck';
import { tradingActions } from 'modules/trading/tradingDuck';
import { countDecimals } from 'helpers/utilityFunctions';
import { Formik, Form, Field } from 'formik';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import * as yup from 'yup';
import { formatWholeNum } from 'helpers/utilityFunctions';

let reset;

const LimitOrder = props => {
  const { mode, product, balances } = props;
  const dispatch = useDispatch();
  const amountRef = useRef();

  // Selectors
  const streamingData = useSelector(state => streamingSelectors.streamingData(state));

  // Actions
  const placeOrder = (payload, callback) => dispatch(tradingActions.placeOrder(payload, callback));

  useEffect(() => {
    reset && reset();
  }, [mode]);

  const {
    id: product_id,
    quote_increment,
    quote_currency,
    base_increment ,
    base_currency,
    base_min_size,
    base_max_size,
    min_market_funds,
    max_market_funds,
  } = product;

  const defaults = { amount: '', limitPrice: '' };
  const assetBalance = parseFloat(balances.find(product => product.currency === base_currency).balance);
  const pairBalance = parseFloat(balances.find(product => product.currency === quote_currency).balance);

  const schema = yup.object().shape({
    amount: yup.number()
      .required('Amount is required.')
      .test('No fractions divided against increment', `Can't exceed ${
        countDecimals(parseFloat(base_increment).toString())} decimals.`,
          val => {
            const limit = parseFloat(base_increment);
            const value = val / parseFloat(limit);
            // TODO: 0.009 breaks this test... look into this.
            return countDecimals(value.toString()) === 0;
          })
      .test('More than', `Must be at least ${
        formatWholeNum(base_min_size) + ' ' + base_currency}`,
          val => {
            const min = base_min_size;
            return val >= min;
          })
      .test('Less than', `Can't exceed ${
        formatWholeNum(base_max_size) + ' ' + base_currency}`,
          val => {
            const max = base_max_size;
            return val <= max;
          })
      .test('Available funds', 'Insufficient funds.', val => {
        if (mode === 'Sell') {
          return val <= assetBalance;
        }

        return true;
      }),

    limitPrice: yup.number()
      .required('Limit price is required.')
      .test('No fractions divided against increment', `Can't exceed ${
        countDecimals(parseFloat(quote_increment).toString())} decimals.`,
          val => {
            const limit = parseFloat(quote_increment);
            const value = val / parseFloat(limit);
            // TODO: 0.009 breaks this test... look into this.
            return countDecimals(value.toString()) === 0;
          })
      .test('More than', `Must be at least ${
        formatWholeNum(min_market_funds) + ' ' + quote_currency}`,
          val => {
            const min = min_market_funds;
            return val >= min;
        })
      .test('Less/Greater than spot price', `Must be ${mode === 'Buy' ? 'less' : 'greater'} than current price.`,
          val => {
            if (streamingData) {
              const product = streamingData.find(product => (
                product.product_id === `${base_currency}-${quote_currency}`
              ));

              const limit = parseFloat(product.price);
              return mode === 'Buy' ? val < limit : val > limit;
            }

            return true;
        })
      .max(max_market_funds, `Can't exceed ${formatWholeNum(max_market_funds) + ' ' + quote_currency}.`)
  });

  const handleSubmit = (values, resetForm, setSubmitting) => {
    const { amount, limitPrice } = values;

    const payload = {
      type: 'limit',
      side: mode.toLowerCase(),
      price: parseFloat(limitPrice),
      size: parseFloat(amount),
      product_id,
    };

    placeOrder(payload, () => resetForm());
    setSubmitting(false);
  };

  const limitValidation = limitPrice => {
    const amount = parseFloat(amountRef.current.value);
    const buyError = 'Insufficient funds for total order.';
    const sellError = `Total order exceeds ${formatWholeNum(max_market_funds)} ${quote_currency}.`;

    if (limitPrice && amount) {
      if (mode === 'Buy') {
        const hasFunds = limitPrice * amount <= pairBalance;
        return !hasFunds && buyError;
      }

      if (mode === 'Sell') {
        const underMax = limitPrice * amount <= max_market_funds;
        return !underMax && sellError;
      }
    }

    return true;
  };

  return (
    <Formik
      initialValues={defaults}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values, {resetForm, setSubmitting}) => (
        handleSubmit(values, resetForm, setSubmitting)
      )}>
        {form => {
          !reset && (reset = form.resetForm);

          return (
            <Form className="orderForm">
              <label>
                <p>Amount  ({base_currency})*</p>
                <Field type="number" name="amount">
                  {({form, field}) => {
                    return (
                      <input
                        ref={amountRef}
                        placeholder={`${mode === 'Buy' ? 'Buy' : 'Sell'} this much ${base_currency}...`}
                        {...field} />
                    );
                  }}
                </Field>

                <FormFeedback name="amount" />
              </label>

              <label>
                <p>Limit price ({quote_currency})*</p>
                <Field
                  type="number"
                  name="limitPrice"
                  validate={val => limitValidation(val)}
                  placeholder={`While at or ${mode === 'Buy' ? 'below' : 'above'} this price...`} />

                <FormFeedback name="limitPrice" />
              </label>

              <Button
                type="submit"
                text={`Place ${mode.toLowerCase()} order`}
                textColor={mode === 'Buy' ? colors.darkerGrey2 : colors.darkYellow}
                bgColor={mode === 'Buy' ? colors.offWhite : colors.pastelYellow}
                btnStyle="solid"
                disabled={form.isSubmitting} />
            </Form>
          );
        }}
    </Formik>
  );
};

export default LimitOrder;