/* eslint-disable no-unused-vars */

import React, { Fragment } from 'react';
import { startCase } from 'lodash';
import { formatWholeNum } from 'helpers/utilityFunctions';
import moment from 'moment';
import colors from 'theme/colors.scss';
import './OrderCard.scss';

const OpenOrderCard = props => {
  const { pair, order, privacy, cancelOrderById } = props;
  const {
    id,
    product_id,
    side: type,
    size,
    price,
    fill_fees,
    filled_size,
    status,
    created_at,
    stop,
    stop_price,
  } = order;

  const date = moment(created_at).fromNow();

  const typeStyle = {
    color: type === 'buy' ? colors.white : colors.yellow,
  }

  const rowClick = (e, mode) => {
    e.stopPropagation();

    if (mode === 'cancel') {
      cancelOrderById(id, product_id);
    }
  };

  const formatVal = val => {
    let finalVal = val.toFixed(2);

    if (val < 1 && pair === 'USD') {
      finalVal = val.toFixed(4);
    }

    if (pair !== 'USD') {
      finalVal = val.toFixed(8);
    }

    return formatWholeNum(finalVal);
  };

  const formatType = () => {
    if (stop) {
      const stopDetails = `Stop ${startCase(stop)} @ ${formatVal(parseFloat(stop_price))}`;
      return stopDetails;
    }

    return startCase(type);
  }

  return (
    <Fragment>
      <div className="orderCard openOrderCard" onClick={e => rowClick(e)}>
        <div className="cancel">
          <i className="fad fa-times-circle"
            onClick={e => rowClick(e, 'cancel')} />
        </div>

        <div id="type" className="type" style={typeStyle}>
          {formatType()}
        </div>

        <div className="size">
          {formatWholeNum(parseFloat(size).toFixed(8))}
        </div>

        <div className="filled">
          {formatVal(parseFloat(filled_size))}
        </div>

        <div className="price">
          {formatVal(parseFloat(price))}
        </div>

        <div className="fee">
          {formatVal(parseFloat(fill_fees))}
        </div>

        <div className="status">
          {startCase(status)}
        </div>
      </div>
    </Fragment>
  );
};

export default OpenOrderCard;