/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import * as yup from 'yup';
import Button from 'components/Button/Button';
import * as colors from 'theme/colors.scss';
import './Search.scss';

const defaults = { userSearch: '' };

const schema = yup.object().shape({
  userSearch: yup.string().trim(),
});

const Search = props => {
  const { placeholder, callback } = props;

  const buttonStyle = searchValue => {
    return {
      opacity: searchValue !== '' ? 1 : 0,
    }
  };

  const handleSubmit = (values, resetForm, setSubmitting) => {
    const { userSearch: search } = values;

    if (search.trim() !== '') {
      callback && callback(search.trim());
    };

    resetForm();
    setSubmitting(false);
  };

  return (
    <div className="search">
      <Formik
        enableReinitialize={true}
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={(values, {resetForm, setSubmitting}) => handleSubmit(values, resetForm, setSubmitting)}>
          {form => (
            <Form className="searchForm">
              <label htmlFor="userSearch">
                <i className="fal fa-search" />
                <Field
                  type="text"
                  name="userSearch"
                  placeholder={placeholder} />

                <div className="btnContainer" style={buttonStyle(form.values.userSearch)}>
                  <Button
                    type="submit"
                    text="Submit"
                    textColor={colors.lightGrey}
                    btnStyle="solid"
                    bgColor={colors.darkerGrey}
                    disabled={form.isSubmitting} />
                </div>

                <FormFeedback name="userSearch" />
              </label>
            </Form>
          )}
      </Formik>
    </div>
  );
}

export default Search;