import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import apiMiddleware from 'middleware/apiMiddleware';
import api from './modules/api/apiDuck';
import app from './modules/app/appDuck';
import streaming from './modules/streaming/streamingDuck';
import charts from './modules/charts/chartsDuck';
import trading from './modules/trading/tradingDuck';
import news from './modules/news/newsDuck';
import marketcap from './modules/marketcap/marketcapDuck';
import portfolio from './modules/portfolio/portfolioDuck';

const modules = combineReducers({
  api,
  app,
  streaming,
  charts,
  trading,
  news,
  marketcap,
  portfolio,
});

const reducer = combineReducers({ modules });

const makeStore = initialState => {
  const middlewares = [thunk, apiMiddleware];
  const withMiddleware = applyMiddleware(...middlewares)(createStore);
  const store = withMiddleware(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  return store;
}

export default makeStore;