import React, { Fragment } from 'react';
import moment from 'moment';
import './Card.scss';

const Card = props => {
  const { title, imageurl, body, url, source, date, site } = props;
  const published = moment.unix(date).fromNow();

  const buildCard = () => {
    return (
      <div className="card">
        <div className="cardImage">
          <a href={url} target="blank">
            <img src={imageurl} alt={title} />
          </a>
        </div>

        <div className="cardTitle">
          <a href={url} target="blank">
            <h4>{title}</h4>
          </a>
        </div>

        <div className="cardPreview">
          <p className="previewText">{body} &hellip;</p>
        </div>

        <div className="cardFooter">
          <div className="source">
            <a href={site} target="blank">
              <img src={source.img} alt={source.name} />
              <span className="sourceName">{source.name}</span>
            </a>
          </div>

          <p className="publishDate">{published}</p>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {buildCard()}
    </Fragment>
  );
}

export default Card;