/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { tradingActions } from 'modules/trading/tradingDuck';
import { countDecimals } from 'helpers/utilityFunctions';
import { Formik, Form, Field } from 'formik';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import * as yup from 'yup';
import { formatWholeNum } from 'helpers/utilityFunctions';

let reset;

const MarketOrder = props => {
  const { mode, product, balances } = props;
  const dispatch = useDispatch();

  // Actions
  const placeOrder = (payload, callback) => dispatch(tradingActions.placeOrder(payload, callback));

  useEffect(() => {
    reset && reset();
  }, [mode]);

  const {
    id: product_id,
    quote_increment,
    quote_currency,
    base_increment ,
    base_currency,
    base_min_size,
    base_max_size,
    min_market_funds,
    max_market_funds,
  } = product;

  const defaults = { amount: '' };
  const assetBalance = parseFloat(balances.find(product => product.currency === base_currency).balance);
  const pairBalance = parseFloat(balances.find(product => product.currency === quote_currency).balance);

  const schema = yup.object().shape({
    amount: yup.number()
      .required('Amount is required.')
      .test('No fractions divided against increment', `Can't exceed ${mode === 'Buy' ?
        countDecimals(parseFloat(quote_increment).toString()) :
        countDecimals(parseFloat(base_increment).toString())} decimals.`,
          val => {
            const limit = mode === 'Buy' ? parseFloat(quote_increment) : parseFloat(base_increment);
            const value = val / parseFloat(limit);
            return countDecimals(value.toString()) === 0;
          })
      .test('More than', `Must be at least ${mode === 'Buy' ?
        formatWholeNum(min_market_funds) + ' ' + quote_currency :
        formatWholeNum(base_min_size) + ' ' + base_currency}`,
          val => {
            const min = mode === 'Buy' ? min_market_funds : base_min_size;
            return val >= min;
          })
      .test('Less than', `Can't exceed ${mode === 'Buy' ?
        formatWholeNum(max_market_funds) + ' ' + quote_currency :
        formatWholeNum(base_max_size) + ' ' + base_currency}`,
          val => {
            const max = mode === 'Buy' ? max_market_funds : base_max_size;
            return val <= max;
          })
      .max(mode === 'Buy' ? pairBalance : assetBalance, 'Insufficient funds.')
  });

  const handleSubmit = (values, resetForm, setSubmitting) => {
    const { amount } = values;

    const payload = {
      type: 'market',
      side: mode.toLowerCase(),
      product_id,
    };

    if (mode === 'Buy') payload.funds = parseFloat(amount);
    if (mode === 'Sell') payload.size = parseFloat(amount);

    placeOrder(payload, () => resetForm());
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={defaults}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={(values, {resetForm, setSubmitting}) => (
        handleSubmit(values, resetForm, setSubmitting)
      )}>
        {form => {
          !reset && (reset = form.resetForm);

          return (
            <Form className="orderForm">
              <label>
                <p>Amount ({mode === 'Buy' ? quote_currency : base_currency})*</p>
                <Field
                  type="number"
                  name="amount"
                  placeholder={mode === 'Buy' ?
                    `Spend this much ${quote_currency} for any ${base_currency}...` :
                    `Sell this much ${base_currency} for any price...`} />

                <FormFeedback name="amount" />
              </label>

              <Button
                type="submit"
                text={`Place ${mode.toLowerCase()} order`}
                textColor={mode === 'Buy' ? colors.darkerGrey2 : colors.darkYellow}
                bgColor={mode === 'Buy' ? colors.offWhite : colors.pastelYellow}
                btnStyle="solid"
                disabled={form.isSubmitting} />
            </Form>
          );
        }}
    </Formik>
  );
};

export default MarketOrder;