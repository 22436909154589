/* eslint-disable no-unused-vars */

import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { streamingSelectors } from 'modules/streaming/streamingDuck';
import { isEmpty } from 'lodash';
import { formatWholeNum } from 'helpers/utilityFunctions';
import colors from 'theme/colors.scss';

const Price = props => {
  const { type, asset, pair, basePrice, useColor, open, setNewVal, useSymbol } = props;

  // Selectors
  const streamingData = useSelector(state => streamingSelectors.streamingData(state));

  const buildPrice = () => {
    if (!isEmpty(streamingData) && type && pair && asset) {
      const workingData = streamingData.find(data => data.asset === asset && data.pair === pair);
      let priceVal = workingData && (basePrice || parseFloat(workingData.price) || null);

      if (workingData) {
        type === 'close' && (priceVal = workingData.close || parseFloat(workingData.price));

        if (type === 'volume') {
          if (workingData.volume) {
            priceVal = workingData.volume;
          } else {
            pair !== 'BTC' ? priceVal = parseFloat(workingData.volume_24h).toFixed(2) : priceVal = parseFloat(workingData.volume_24h);
          }
        }

        if (pair === 'BTC') priceVal = priceVal && priceVal.toFixed(8);

        if (basePrice && (workingData.close !== basePrice || parseFloat(workingData.price) !== basePrice)) {
          if (type === 'high' && (workingData.close > basePrice || parseFloat(workingData.price) > basePrice)) {
            priceVal = workingData.close || parseFloat(workingData.price);
            setNewVal(type, workingData.close || parseFloat(workingData.price));
          };

          if (type === 'low' && (workingData.close < basePrice || parseFloat(workingData.price) < basePrice)) {
            priceVal = workingData.close || parseFloat(workingData.price);
            setNewVal(type, workingData.close || parseFloat(workingData.price));
          };
        };

        const priceStyle = () => {
          if (useColor) {
            const openAsNum = parseFloat(open.replace(/,/g, ''));

            return {
              color: (workingData.close || parseFloat(workingData.price)) >= openAsNum ? colors.white : colors.yellow
            }
          }
        };

        const formattedPrice = priceVal && formatWholeNum(priceVal.toString());

        return (
          <span style={priceStyle()}>
            {useSymbol && (
              <Fragment>
                {pair === 'BTC' ?
                  <i className="fab fa-btc" /> :
                  <i className="fad fa-dollar-sign" />
                }&nbsp;
              </Fragment>
            )}

            {priceVal && formattedPrice}
          </span>
        );
      };
    };
  };

  return (
    <Fragment>
      {(streamingData && buildPrice()) || <i className="fad fa-horizontal-rule" />}
    </Fragment>
  );
};

export default Price;