/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { newsActions, newsSelectors } from 'modules/news/newsDuck';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { trimText, getBaseUrl, deviceFormat } from 'helpers/utilityFunctions';
import { paramsFromURL, paramsToObject, paramsToString } from 'helpers/paramHelpers';
import Pagination from 'components/Pagination/Pagination';
import { makePageable } from 'helpers/utilityFunctions';
import ToTop from 'components/ToTop/ToTop';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import Card from 'components/Card/Card';
import * as colors from 'theme/colors.scss';
import './News.scss';

const itemsPerPage = 100;

const resizeActions = (e, setFormat, mounted) => {
  const element = e.currentTarget;
  const newHeight = element.innerHeight;
  const newWidth = element.innerWidth;

  if (mounted) setFormat(deviceFormat(newWidth, newHeight));
};

const News = props => {
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ format, setFormat ] = useState(deviceFormat(window.innerWidth, window.innerHeight));
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const params = paramsToObject(paramsFromURL());

  // Selectors
  const articles = useSelector(state => newsSelectors.articles(state));
  const settings = useSelector(state => appSelectors.settings(state));

  // Actions
  const getArticles = callback => dispatch(newsActions.getArticles(callback));
  const setSettings = payload => dispatch(appActions.setSettings(payload));
  const getSettings = () => dispatch(appActions.getSettings());
  const logout = () => dispatch(appActions.logout());

  useEffect(() => {
    getSettings();
    const refreshArticles = setInterval(() => getArticles(), 300000);

    getArticles();

    window.addEventListener('resize', e => resizeActions(e, setFormat, mounted.current));

    return () => {
      mounted.current = false;
      clearInterval(refreshArticles);
      window.removeEventListener('resize', e => resizeActions(e, setFormat, mounted.current));
    };
  }, []);

  useEffect(() => {
    if (settings) {
      const { news: newsParams } = settings.pages;

      if (newsParams !== paramsFromURL()) {
        history.push(`/news${newsParams}`);
      };
    };
  }, [settings]);

  useEffect(() => {
    if (settings && format !== 'Desktop' && params.view ===  'grid') {
      history.push('/news?view=list');
      const payload = { ...settings };
      payload.pages.news = paramsFromURL();
      setSettings(payload);
    };
  }, [format]);

  const buildNews = () => {
    if (articles) {
      const pageData = makePageable(articles.Data, itemsPerPage);
      const viewStyle = { width: params.view === 'list' && '100%' };

      const cards = () => {
        if (currentPage > pageData.length && format === 'Desktop') {
          setCurrentPage(pageData.length);
          return null;
        };

        const dataItem = format === 'Desktop' ? pageData[currentPage - 1] : articles.Data;

        return dataItem.map(article => {
          const { id, title, imageurl, body, url, source_info, published_on } = article;
          const width = window.innerWidth;
          const site = getBaseUrl(url);
          const apostropheTrim = body.replace(/&#8217;/g, "'");
          const quoteTrim = apostropheTrim.replace(/&quot;/g, '"');;
          const previewText = () => {
            if (width <= 590) return trimText(quoteTrim, 90);
            if (width <= 640) return trimText(quoteTrim, 100);

            return trimText(quoteTrim, 95);
          };

          const titleCutoff = () => {
            if (width <= 350 && title.length > 50) return `${trimText(title, 50)}...`;
            if (width <= 380 && title.length > 70) return `${trimText(title, 60)}...`;
            if (width <= 414 && title.length > 90) return `${trimText(title, 90)}...`;
            if (title.length > 95) return `${trimText(title, 90)}...`;

            return title;
          };

          return (
            <li key={id} style={viewStyle}>
              <Card
                title={titleCutoff()}
                imageurl={imageurl}
                body={previewText()}
                url={url}
                source={source_info}
                date={published_on}
                site={site} />
            </li>
          );
        });
      };

      return (
        <Fragment>
          {cards()}

          {(pageData.length > 1 && format === 'Desktop') && (
            <Pagination
              totalPages={pageData.length}
              currentPage={currentPage}
              changePage={newPage => setCurrentPage(newPage)} />
          )}
        </Fragment>
      );
    };

    return <Loader text="Loading articles..." />;
  }

  return (
    <Fragment>
      <div id="newsBody">
        <div className="newsTitle">
          <h2>
            News
          </h2>
        </div>

        <div className="list">
          <Button
            icon="list"
            iconColor={search === '?view=list' ? colors.lighterGrey : colors.grey}
            btnStyle="transparent"
            callback={() => {
              history.push('/news?view=list');
              const payload = { ...settings };
              payload.pages.news = paramsFromURL();
              setSettings(payload);
            }} />
        </div>

        <div className="grid">
          <Button
            icon="grid"
            iconColor={search === '?view=grid' ? colors.lighterGrey : colors.grey}
            btnStyle="transparent"
            callback={() => {
              history.push('/news?view=grid');
              const payload = { ...settings };
              payload.pages.news = paramsFromURL();
              setSettings(payload);
            }} />
        </div>

        <div id="newsContainer">
          <ul id="news">
            {buildNews()}
          </ul>
        </div>
      </div>

      <ToTop />
    </Fragment>
  )
}

export default News;