import React, { Fragment, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { apiActions } from 'modules/api/apiDuck';
import { Field } from 'formik';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';

/* Validate with parent yup schema:
*  const schema = yup.object().shape({
*    [fieldName]: yup.string().required().trim()
*  });
*/

const FieldWithAction = props => {
  const {
    form,
    name,
    type,
    validate,
    placeholder,
    activeIcon,
    inactiveIcon,
    notificationMsg,
    highlightField,
    modal,
    callback,
    disabled,
  } = props;
  const dispatch = useDispatch();
  const fieldRef = useRef();

  // Actions
  const setSuccessMessages = payload => dispatch(apiActions.setSuccessMessages(payload));

  const confirmAction = target => {
    const confirmed = 'actionConfirmed';
    target.classList.add(confirmed);
    setTimeout(() => target.classList.remove(confirmed), 200);
  };

  return (
    <Fragment>
      <label>
        <div className="fieldWithAction">
          <Field name={name} validate={validate}>
            {({ field }) => {
              return (
                <input
                  ref={fieldRef}
                  type={type || "text"}
                  placeholder={placeholder}
                  disabled={disabled}
                  {...field} />
              );
            }}
          </Field>

          <Button
            type="button"
            icon={form.values[name] !== '' ? activeIcon : inactiveIcon}
            iconColor={colors.grey}
            bgColor={modal ? colors.darkerGrey2 : colors.darkerGrey}
            btnStyle="solid"
            disabled={disabled}
            callback={e => {
              e.preventDefault();
              const target = fieldRef.current;

              if (form.values[name] !== '') {
                highlightField && confirmAction(target);
                notificationMsg && setSuccessMessages({ msg: notificationMsg });
                callback && callback();
              };
            }} />
          </div>

        <FormFeedback name={name} />
      </label>
    </Fragment>
  );
};

export default FieldWithAction;