/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from 'd3';
import 'd3-selection-multi';
import { SMA } from 'helpers/utilityFunctions';
import './movingAverages.scss';

const generateMovingAverages = (OHLC, visibleRange, maLengths, chart, pathGen, maState, setMaState) => {
  const { active, range } = maState;

  if (active) {
    let minMax;

    maLengths.forEach((ma, index) => {
      let mAverage = new SMA(OHLC.close, ma).calc();
      mAverage = mAverage.slice(mAverage.length - visibleRange);

      const maMinMax = [d3.min(mAverage), d3.max(mAverage)];

      if (index === 0) {
        minMax = maMinMax;
      } else {
        if (maMinMax[0] < minMax[0]) minMax.splice(0, 1, maMinMax[0]);
        if (maMinMax[1] > minMax[1]) minMax.splice(1, 1, maMinMax[1]);
      }

      const maLine = chart.append('path')
        .attrs({
          class: ma >= 100 ? 'maThick' : 'maThin',
          d: pathGen(mAverage)
        })
    });

    if (range[0] !== minMax[0] || range[1] !== minMax[1]) {
      setMaState({ ...maState, range: minMax });
    }
  } else {
    // Reset range if indicator is off
    if (range.length > 0) setMaState({ ...maState, range: [] });
  };
};

export default generateMovingAverages;