import React from 'react';
import colors from 'theme/colors.scss';
import { withRouter } from 'react-router';
import './Button.scss';

const Button = props => {
  const {
    icon,
    iconColor,
    text,
    history,
    disabled,
    url,
    callback,
    newWin,
    type,
    textColor,
    textWeight,
    bgColor,
    btnStyle,
    staticContext,
    ...rest
  } = props;

  const buttonRef = React.createRef();

  const buildIcon = () => {
    const iconStyle = {
      fontSize: '1rem',
      color: iconColor || colors.grey,
      marginRight: text ? '0.5rem' : '0rem',
    }

    textWeight && (iconStyle.fontWeight = textWeight);

    const backIcon = <i className="fas fa-chevron-left" style={iconStyle}></i>;
    const nextIcon = <i className="fas fa-chevron-right" style={iconStyle}></i>;
    const addIcon = <i className="fad fa-plus-circle" style={iconStyle}></i>;
    const removeIcon = <i className="fad fa-minus-circle" style={iconStyle}></i>;
    const deleteIcon = <i className="fad fa-trash-alt" style={iconStyle}></i>;
    const uploadIcon = <i className="fad fa-file-upload" style={iconStyle}></i>;
    const searchIcon = <i className="fad fa-search" style={iconStyle}></i>;
    const archiveIcon = <i className="fad fa-archive" style={iconStyle}></i>;
    const undoIcon = <i className="fad fa-undo" style={iconStyle}></i>;
    const editIcon = <i className="fad fa-pen-square" style={iconStyle}></i>;
    const viewIcon = <i className="fad fa-binoculars" style={iconStyle}></i>;
    const circleIcon = <i className="fas fa-circle" style={iconStyle}></i>;
    const userIcon = <i className="fad fa-user" style={iconStyle} />;
    const robotIcon = <i className="fad fa-robot" style={iconStyle}></i>;
    const expandIcon = <i className="fad fa-arrow-alt-from-bottom" style={iconStyle}></i>;
    const collapseIcon = <i className="fad fa-arrow-alt-to-bottom" style={iconStyle}></i>;
    const refreshIcon = <i className="fal fa-sync-alt" style={iconStyle}></i>;
    const closeIcon = <i className="fad fa-times-circle" style={iconStyle}></i>;
    const eyeDisabled = <i className="fal fa-eye-slash" style={iconStyle}></i>;
    const eyeIcon = <i className="fad fa-eye" style={iconStyle}></i>;
    const eyeClosedIcon = <i className="fad fa-eye-slash" style={iconStyle}></i>;
    const starIcon = <i className="far fa-star" style={iconStyle}></i>;
    const starSolidIcon = <i className="fas fa-star" style={iconStyle}></i>;
    const copyIcon = <i className="fad fa-copy" style={iconStyle} />;
    const copyIcon2 = <i className="fal fa-copy" style={iconStyle} />;
    const saveIcon = <i className="fal fa-cloud-upload" style={iconStyle} />;
    const warningIcon = <i className="fal fa-exclamation-triangle" style={iconStyle} />;
    const listIcon = <i className="fas fa-list-alt" style={iconStyle} />;
    const gridIcon = <i className="fas fa-th" style={iconStyle} />;
    const loginIcon = <i className="fal fa-sign-in" style={iconStyle} />;
    const logoutIcon = <i className="fal fa-sign-out" style={iconStyle} />;
    const movingAveragesIcon = <i className="fal fa-wave-sine" style={iconStyle} />;
    const emaRibbonIcon = <i className="fal fa-water" style={iconStyle} />;
    const ichimokuCloudIcon = <i className="fal fa-wave-square" style={iconStyle} />;
    const bollingerBandsIcon = <i className="fal fa-heat fa-rotate-90" style={iconStyle} />;
    const settingsActiveIcon = <i className="fas fa-cog" style={iconStyle} />;
    const settingsInactiveIcon = <i className="fal fa-cog" style={iconStyle} />;

    const setIcon = () => {
      if (icon === 'back') return backIcon;
      if (icon === 'next') return nextIcon;
      if (icon === 'add') return addIcon;
      if (icon === 'expand') return expandIcon;
      if (icon === 'collapse') return collapseIcon;
      if (icon === 'close') return closeIcon;
      if (icon === 'remove') return removeIcon;
      if (icon === 'delete') return deleteIcon;
      if (icon === 'upload') return uploadIcon;
      if (icon === 'archive') return archiveIcon;
      if (icon === 'edit') return editIcon;
      if (icon === 'search') return searchIcon;
      if (icon === 'undo') return undoIcon;
      if (icon === 'view') return viewIcon;
      if (icon === 'circle') return circleIcon;
      if (icon === 'user') return userIcon;
      if (icon === 'refresh') return refreshIcon;
      if (icon === 'robot') return robotIcon;
      if (icon === 'eye') return eyeIcon;
      if (icon === 'eyeDisabled') return eyeDisabled;
      if (icon === 'eyeClosed') return eyeClosedIcon;
      if (icon === 'star') return starIcon;
      if (icon === 'starSolid') return starSolidIcon;
      if (icon === 'copy') return copyIcon;
      if (icon === 'copy2') return copyIcon2;
      if (icon === 'save') return saveIcon;
      if (icon === 'warning') return warningIcon;
      if (icon === 'list') return listIcon;
      if (icon === 'grid') return gridIcon;
      if (icon === 'login') return loginIcon;
      if (icon === 'logout') return logoutIcon;
      if (icon === 'movingAverages') return movingAveragesIcon;
      if (icon === 'emaRibbon') return emaRibbonIcon;
      if (icon === 'ichimokuCloud') return ichimokuCloudIcon;
      if (icon === 'bollingerBands') return bollingerBandsIcon;
      if (icon === 'settingsActive') return settingsActiveIcon;
      if (icon === 'settingsInactive') return settingsInactiveIcon;
    }

    if (icon && typeof icon === 'object' && icon.type === 'svg') {
      const { children } = icon.props;

      return (
        <svg {...icon.props} fill={iconColor ? iconColor : colors.grey}>
          {children}
        </svg>
      )
    }

    if (icon) return typeof icon == 'string' ? setIcon() : icon;
  }

  const changeHistoryState = () => {
    if (!disabled) { history.push(url) }
  }

  const loadDestination = e => {
    if (!disabled) {
      if (callback) {
        callback(e);
      } else if (url) {
        window.open(url, newWin ? '_blank' : '_self');
      }
    }
  }

  const buildButton = () => {
    const setBGColor = () => {
      if (btnStyle === 'transparent' || btnStyle === 'ghost') {
        return colors.transparent;
      } else {
        if (bgColor) {
          return disabled ? colors.darkerGrey : bgColor;
        } else {
          return disabled ? colors.darkerGrey : colors.grey;
        }
      }
    };

    const setShadowColor = () => {
      if (bgColor) {
        return disabled ? colors.grey : bgColor;
      }

      return colors.grey;
    }

    const reactStyle = {
      color: (disabled && colors.grey) || (textColor || colors.grey),
      fontWeight: textWeight || '300',
      backgroundColor: setBGColor(),
      border: 'none',
      boxShadow: btnStyle === 'ghost' && `inset 0 0 0 0.09375rem ${setShadowColor()}`,
      cursor: disabled ? 'default' : 'pointer',
      opacity: disabled ? '0.5' : '1',
    };

    const pStyle = { margin: 0 }

    return (
      <button
        ref={buttonRef}
        type={type}
        className="button"
        style={reactStyle}
        onClick={url && url.charAt(0) === '/' ? changeHistoryState : loadDestination}
        {...rest}>

          {buildIcon()}
          <p className="btnText" style={pStyle}>{text}</p>

      </button>
    )
  }

  return (
    <React.Fragment>
      {buildButton()}
    </React.Fragment>
  );
}

export default withRouter(Button);