/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useEffect, useState } from 'react';
import { chartsActions } from 'modules/charts/chartsDuck';
import { useDispatch } from 'react-redux';
import { timeLeft } from 'helpers/utilityFunctions';

const TimeToClose = props => {
  const { params } = props;
  const { asset, pair, timeframe, source } = params;
  const [ remainingTime, setRemainingTime ] = useState(timeLeft(timeframe));
  const dispatch = useDispatch();

  // Actions
  const getAssetData = (asset, pair, timeframe, source) => {
    return dispatch(chartsActions.getAssetData(asset, pair, timeframe, source));
  };

  useEffect(() => {
    setRemainingTime(timeLeft(timeframe));

    const timeCheck = setInterval(() => {
      setRemainingTime(timeLeft(timeframe));
    }, 1000);

    return () => clearInterval(timeCheck);
  }, [timeframe]);

  useEffect(() => {
    if (remainingTime.match(/^[0:]+$/g)) {
      setTimeout(() => getAssetData(asset, pair, timeframe, source), 200);
    };
  }, [remainingTime]);

  return (
    <Fragment>
      {remainingTime}
    </Fragment>
  );
};

export default TimeToClose;