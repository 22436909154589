import React from 'react';
import './NotFound.scss';

const NotFound = props => {
  return (
    <div id="notFoundBody">
      <div className="notFoundTitle">
        <h2>
          404: Oops!
        </h2>
      </div>

      <div className="notFoundMain">
        <p>Looks like the page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;