/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, appSelectors } from 'modules/app/appDuck';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { isEmpty } from 'lodash';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import * as yup from 'yup';
import FormFeedback from 'components/FormFeedback/FormFeedback';
import FieldWithAction from 'components/FieldWithAction/FieldWithAction';
import FieldRequirements from 'components/FieldRequirements/FieldRequirements';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import colors from 'theme/colors.scss';
import './CreateAccount.scss';

const defaults = {
  userEmail: '',
  userPassword: '',
  confirmPassword: '',
  inviteCode: '',
  trustedDevice: false,
  agreedTo: false,
};

const schema = yup.object().shape({
  userEmail: yup.string().email('Invalid email.').required('Email is required.').trim(),
  userPassword: yup.string().required('Password is required.').trim(),
  confirmPassword: yup.string().required('Confirm password is required.').trim()
    .oneOf([yup.ref('userPassword'), null], 'Passwords must match.'),
  inviteCode: yup.string().required('Invite code is required.').trim(),
  agreedTo: yup.boolean().oneOf([true], 'You must agree to all terms and policies.'),
});

const CreateAccount = props => {
  const { state } = props;
  const [ passwordVisible, setPasswordVisible ] = useState(false);
  const [ loggedIn, setLoggedIn ] = useState(false);
  const [ modalState, setModalState ] = useState(defaultModal);
  const dispatch = useDispatch();
  const history = useHistory();

  // Selectors
  const token = useSelector(state => appSelectors.token(state));

  // Actions
  const createAccount = useCallback((userInfo, callback) =>
    dispatch(appActions.createAccount(userInfo, callback)), [dispatch]);


  useEffect(() => {
    !!token && setLoggedIn(true);
  });

  useEffect(() => {
    const route = !isEmpty(state) ? state.from.pathname + state.from.search : '/charts';
    !!token && history.push(route);
  }, [loggedIn]);

  const handleSubmit = (values, setSubmitting) => {
    const { userEmail, userPassword, confirmPassword, inviteCode, trustedDevice, agreedTo } = values;

    const userInfo = {
      email: userEmail.toLowerCase(),
      password: userPassword,
      confirmPassword,
      inviteCode,
      trustedDevice,
      agreedTo: agreedTo ? ['Terms of Service', 'Privacy Policy'] : [],
    };

    createAccount(userInfo, () => {
      setSubmitting(false);
    });
  };

  return (
    <Fragment>
      <Formik
        initialValues={defaults}
        validationSchema={schema}
        onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}>
          {form => (
            <Form className="authForm">
              <Field type="email" name="userEmail" placeholder="Email*" />
              <FormFeedback name="userEmail" />

              <FieldWithAction
                form={form}
                name="userPassword"
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Password*"
                activeIcon={passwordVisible ? "eye" : "eyeClosed"}
                inactiveIcon="eyeDisabled"
                highlightField={true}
                callback={() => setPasswordVisible(!passwordVisible)} />

              <FieldWithAction
                form={form}
                name="confirmPassword"
                type={passwordVisible ? 'text' : 'password'}
                placeholder="Confirm password*"
                activeIcon={passwordVisible ? "eye" : "eyeClosed"}
                inactiveIcon="eyeDisabled"
                highlightField={true}
                callback={() => setPasswordVisible(!passwordVisible)} />

              <Field type="text" name="inviteCode" placeholder="Invite code*" />
              <FormFeedback name="inviteCode" />

              <FieldRequirements value={form.values.userPassword} />

              <Checkbox
                name="agreedTo"
                className="legal"
                label={(
                  <Fragment>
                    I agree to the&nbsp;
                    <Link to="#" onClick={() => openModal(
                      setModalState, null, 'Terms of Service', <TermsOfService />)}>
                        terms of service
                    </Link>&nbsp;&amp;&nbsp;

                    <Link to="#" onClick={() => openModal(
                      setModalState, null, 'Privacy Policy', <PrivacyPolicy />)}>
                        privacy policy
                    </Link>.
                  </Fragment>
                )} />

              <Button
                type="submit"
                text="Create account"
                textColor={colors.white}
                btnStyle="solid"
                disabled={form.isSubmitting} />

              {/* <Checkbox
                name="trustedDevice"
                className="trust"
                label="Trust this device for 30 days." /> */}

              <p className="loginOptions">
                <Link to={'/login'}>Log in</Link>
                &nbsp;or <Link to={'/reset-password'}>Reset your password</Link>.
              </p>
            </Form>
          )}
      </Formik>

      {showModal(modalState, setModalState)}
    </Fragment>
  );
};

export default withRouter(CreateAccount);