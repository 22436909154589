import React, { Fragment } from 'react';
import './Loader.scss';

const Loader = props => {
  const { text } = props;

  return (
    <Fragment>
      <div className="loader">
        <i className="fad fa-spinner-third fa-spin" />
        <p className="text">{text}</p>
      </div>
    </Fragment>
  );
};

export default Loader;