/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useState, useEffect } from 'react';
import Pagination from 'components/Pagination/Pagination';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { makePageable } from 'helpers/utilityFunctions';
import { uniqueId, isEmpty } from 'lodash';
import Loader from 'components/Loader/Loader';
import { defaultModal, openModal, showModal } from 'helpers/modals';
import Button from 'components/Button/Button';
import PieChart from 'components/PieChart/PieChart';
import WalletForm from '../WalletForm/WalletForm';
import WalletCard from 'scenes/portfolio/WalletCard/WalletCard';
import WalletsHeader from 'scenes/portfolio/WalletCard/WalletsHeader';
import colors from 'theme/colors.scss';
import './WalletsTable.scss';

const WalletsTable = props => {
  const {
    currentPair,
    wallets,
    filteredData,
    format,
    itemsPerPage,
    privacy,
    reorderPortfolio,
  } = props;

  const [ currentPage, setCurrentPage ] = useState(1);
  const [ modalState, setModalState ] = useState(defaultModal);
  const pageData = makePageable(!isEmpty(filteredData) ? filteredData : wallets, itemsPerPage);

  const buildTableRows = () => {
    if (currentPage > pageData.length && format === 'Desktop') {
      setCurrentPage(pageData.length);
      return null;
    };

    const dataItem = () => {
      if (format === 'Desktop') return pageData[currentPage - 1];
      return !isEmpty(filteredData) ? filteredData : wallets;
    }

    return dataItem().map((wallet, index) => {
      const dragStyle = (draggableProps, isDragging) => ({
        backgroundColor: isDragging && 'rgba(51, 54, 58, 0.45)',
        boxShadow: isDragging && `0 0.1rem 0.2rem ${colors.black}`,
        backdropFilter: isDragging && 'blur(0.5rem)',
        borderRadius: isDragging && '0.25rem',
        ...draggableProps.style,
      });

      return (
        <Draggable key={wallet.id} draggableId={wallet.id} index={index}>
          {(provided, snapshot) => (
            <li ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={dragStyle(provided.draggableProps, snapshot.isDragging)}>
                <WalletCard
                  privacy={privacy}
                  wallets={wallets}
                  wallet={wallet}
                  filteredData={filteredData}
                  btcPairs={currentPair === 'BTC'}
                  pair={currentPair} />
            </li>
          )}
        </Draggable>
      );
    });
  };

  const handleDragEnd = result => {
    if (isEmpty(filteredData) && result.source && result.destination) {
      const { source: { index: fromIndex }, destination: { index: toIndex } } = result;
      const workingWallets = Array.from(wallets);
      const [ reorderedWallets ] = workingWallets.splice(fromIndex, 1);

      if (fromIndex !== toIndex) {
        workingWallets.splice(toIndex, 0, reorderedWallets);
        reorderPortfolio({ payload: workingWallets });
      }
    }
  };

  return (
    <Fragment>
      <PieChart wallets={wallets} currentPair={currentPair} privacy={privacy} />

      <div className="walletsTable">
        {!isEmpty(wallets) && (
          <Fragment>
            <div className="header strong">
              <WalletsHeader />
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="wallets">
                {provided => (
                  <ul id="wallets" {...provided.droppableProps} ref={provided.innerRef}>
                    {buildTableRows()}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </Fragment>
        )}

        {isEmpty(wallets) && (
            <div className="add">
              <p>No wallets</p>
              <Button
                text="Add a wallet"
                textColor={colors.white}
                icon="add"
                iconColor={colors.white}
                btnStyle="transparent"
                callback={() => openModal(
                  setModalState, uniqueId('NewForm_'), 'Add Wallet',
                  <WalletForm setModalState={newState => setModalState(newState)} />
                )} />
            </div>
          )}

        <div className="walletsPagination">
          {(pageData.length > 1 && format === 'Desktop') && (
            <Pagination
              totalPages={pageData.length}
              currentPage={currentPage}
              changePage={newPage => setCurrentPage(newPage)} />
          )}
        </div>
      </div>

      {showModal(modalState, setModalState)}
    </Fragment>
  );
};

export default WalletsTable;