/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as d3 from 'd3';
import 'd3-selection-multi';
import { SMA } from 'helpers/utilityFunctions';
import './volume.scss';

const generateMaLine = (chart, pathGen, volSMA, volumeState) => {
  const { maActive } = volumeState;

  if (maActive) {
    const volMALine = chart.append('path')
      .attrs({
        class: 'volMALine',
        d: pathGen(volSMA)
      })
  };
};

const generateVolume = (
    OHLC,
    rawOHLC,
    visibleRange,
    format,
    volMaActive,
    maVolumeLength,
    containerWidth,
    target,
    chart,
    height,
    width,
    margin,
    xScale,
    timestamps,
    volumeState
  ) => {
  const { active } = volumeState;

  const volHeight = format === 'Desktop' ? 112 : 80;
  const vol_yDomain = [d3.min(OHLC.volume), d3.max(OHLC.volume)];
  const vol_yRange = [volHeight, 0];

  const yScale = d3.scaleLinear()
    .domain(vol_yDomain).nice()
    .range(vol_yRange);

  const pathGen = d3.line()
    .defined((d, i) => d)
    .x((d, i) => xScale(timestamps[i]))
    .y(d => yScale(d))
    .curve(d3.curveMonotoneX); // options are: d3.curveMonotoneX or d3.curveLinear

  const volume = chart.append('g')
    .attrs({
      class: 'volumeSvg',
      transform: `translate(${target.clientWidth - containerWidth}, ${height - volHeight - 2})`
    })
    .append('g')
    .attrs({
      height: volHeight + margin.top + margin.bottom,
      width: width + margin.left + margin.right,
      transform: `translate(${margin.left}, ${margin.top})`,
    });

  if (active) {
    let volSMA = new SMA(rawOHLC.volume, maVolumeLength).calc();

    volSMA = volSMA.slice(volSMA.length - visibleRange);

    const volumeLines = volume.selectAll('line')
      .data(OHLC.volume)
      .enter()
      .append('line')
      .attrs({
        class: (d, i) => OHLC.close[i] > OHLC.open[i] ? 'volumeBull' : 'volumeBear',
        x1: (d, i) => xScale(timestamps[i]),
        y1: (d, i) => yScale(0),
        x2: (d, i) => xScale(timestamps[i]),
        y2: d => yScale(d)
      })

    volMaActive && generateMaLine(volume, pathGen, volSMA, volumeState);

    volume.exit();
  };
};

export default generateVolume;