import React, { Fragment } from 'react';
import colors from 'theme/colors.scss';
import './FieldRequirements.scss';

const min = 8;

const FieldRequirements = props => {
  const { value } = props;

  const loadIcon = type => {
    let valid = false;
    let style;

    if (type === 'num') /^(?=.*[0-9]).*$/.test(value) && (valid = !valid);
    if (type === 'length') value.length >= min && (valid = !valid);
    if (type === 'uppercase') /^(?=.*[A-Z]).*$/.test(value) && (valid = !valid);
    if (type === 'lowercase') /^(?=.*[a-z]).*$/.test(value) && (valid = !valid);
    if (type === 'special') /^(?=.*[@#$%^&+=!]).*$/.test(value) && (valid = !valid);

    style = {
      color: valid ? colors.green : colors.grey,
      opacity: valid ? 1 : 0.25
    };

    return (
      <i className={`fas fa-check-circle`} style={style} />
    );
  };

  const buildRequirements = () => {
    return (
      <Fragment>
        <li>{loadIcon('uppercase')} Uppercase</li>
        <li>{loadIcon('lowercase')} Lowercase</li>
        <li>{loadIcon('num')} Number</li>
        <li>{loadIcon('length')} {min} Minimum</li>
        <li>{loadIcon('special')} Special: @ # $ % ^ {'&'} + = !</li>
      </Fragment>
    );
  };

  return (
    <ul className="reqs">
      {buildRequirements()}
    </ul>
  )
}

export default FieldRequirements;