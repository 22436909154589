import React from 'react';
import { Link } from 'react-router-dom';
import './Brand.scss';

const Brand = props => {
  const buildBrand = () => {
    return (
      <Link to="/">
        <div id="Brand">
          <svg width="100%" height="100%" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Logo" transform="matrix(4.81213,0,0,4.81213,-480.111,-68.2411)">
              <g transform="matrix(1.06843,0,0,1,-3.65229,30.6174)">
                <rect x="136.849" y="5.01" width="19.485" height="63.506" />
              </g>

              <g transform="matrix(1.06843,0,0,0.491059,-36.2956,65.4878)">
                <rect x="136.849" y="5.01" width="19.485" height="63.506" />
              </g>

              <g transform="matrix(1.06843,0,0,0.5,28.991,33.1222)">
                <rect x="136.849" y="5.01" width="19.485" height="63.506" />
              </g>
            </g>
          </svg>

          {/* <h1>Xolio</h1><span id="tm">&trade;</span> */}
        </div>
      </Link>
    )
  }

  return (
    <div className="brandContainer">
      {buildBrand()}
    </div>
  )
}

export default Brand;