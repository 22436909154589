import React from 'react';
import _ from 'lodash';
import Modal from 'components/Modal/Modal';

/* Using modals
*  1. Import helpers and your modal content component
*    import { defaultModal, openModal, showModal } from '../path/to/helpers/modals';
*    import ModalContentComponent from './path/to/ModalContentComponent';
*
*  2. Prepare parent component with modal state
*     const [ modalState, setModalState ] = useState(defaultModal);
*
*  3. Listen for modal state change in Parent render method by passing state methods to helper.
*     {showModal(modalState, setModalState)}
*
*  4. Call modal anywhere with the 'openModal(setModalState, id, title, content)' call.
*/

export const defaultModal = {
  title: null,
  content: null,
  show: false,
  id: null
}

export const showModal = (modalState, setModalState) => {
  const { title, content, show } = modalState;

  if (show) {
    return (
      <Modal
        id={_.uniqueId("modal_")}
        title={title}
        render={content}
        onClose={() => setModalState(defaultModal)} />
    );
  }
}

export const openModal = (setModalState, id, title, content) => {
  const modalObj = {
    title: title,
    content: content,
    show: true,
    id: id
  }

  return setModalState(modalObj);
}