/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { fromJS } from 'immutable';
import * as api from "./marketcapApi";

// Constants
const GET_MARKETCAP = 'modules/marketcap/GET_MARKETCAP';
const STATE_KEY_MARKETCAP = 'marketcap';

export const marketcapConstants = {
  GET_MARKETCAP,
  STATE_KEY_MARKETCAP,
};

// Reducer
export const reducer = (initialState = {}, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case GET_MARKETCAP:
      return state.set(STATE_KEY_MARKETCAP, action.payload).toJS();

    default:
      return initialState;
  }
}

// Actions
export const marketcapActions = {
  getMarketcap: (limit, pair, callback) => (
    api.getMarketcap(limit, pair, GET_MARKETCAP, callback)
  ),
};

// Selectors
export const marketcapSelectors = {
  marketcap: state => state.modules.marketcap[STATE_KEY_MARKETCAP],
};

export default reducer;