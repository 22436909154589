const defaultSettings = {
  pages: {
    charts: '?asset=BTC&pair=USD&timeframe=4H&source=CCCAGG&subChart=MACD',
    trade: '?asset=BTC&pair=USD&timeframe=4H&source=Coinbase&orders=Open',
    portfolio: '?pair=USD&privacy=false',
    news: '?view=grid',
    market: '?pair=USD',
  },
  general: {
    useLog: false,
    colorBlind: false,
  },
  indicators: {
    volActive: true,
    volMaActive: true,
    volMa: 20,
    movingAveragesActive: false,
    movingAverages: [20, 50, 100, 200],
    emaRibbonActive: false,
    emaRibbon: [21, 25, 30, 35, 40, 45, 50, 55, 200],
    bBandsActive: false,
    bBands: [20, 2], // [sma, stdDeviation]
    macd: [12, 26, 9], // [fastLength, slowLength, signalSmoothing]
    rsi: [14, 70, 30], // [length, overbought, oversold]

    // [fastLength (K), slowLength (D), rsiLength, stochRsiLength, overbought, oversold]
    stochRsi: [3, 3, 14, 14, 80, 20],

    // Adjusted for 24/7 market
    // [conversionLine, baseLine, leadingSpan (A) & leadingSpan (B), laggingSpan]
    // [kenkan sen, kijun sen, senkou (A) & senkou (B), chikou]
    ichimokuActive: false,
    ichimoku: [20, 60, 120, 30],
  },
};

module.exports = defaultSettings;