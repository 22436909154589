import createFlags from 'flag';

export const myFlags = {
  features: {
    dev: true,
    maintenance: false,
  }
}

const {
  FlagsProvider,
  Flag,
  useFlag,
  useFlags
} = createFlags(myFlags);

export {
  FlagsProvider,
  Flag,
  useFlag,
  useFlags,
 };