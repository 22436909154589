import React, { Fragment } from 'react';
import Button from 'components/Button/Button';
import colors from 'theme/colors.scss';
import './OrderCard.scss';

const OpenOrdersHeader = props => {
  const { disabled, asset, pair, cancelAllOrders } = props;

  return (
    <Fragment>
      <div className="orderCard openOrderCard">
        <div className="cancel">
          <Button
            text="Cancel all"
            textColor={colors.lightGrey}
            btnStyle="ghost"
            disabled={disabled}
            callback={() => cancelAllOrders(`${asset}-${pair}`)}/>
        </div>

        <div id="type" className="type">
          <h3>Type</h3>
        </div>

        <div className="size">
          <h3>Size ({asset})</h3>
        </div>

        <div className="filled">
          <h3>Filled ({asset})</h3>
        </div>

        <div className="price">
          <h3>Price ({pair})</h3>
        </div>

        <div className="fee">
          <h3>Fee ({pair})</h3>
        </div>

        <div className="status">
          <h3>Status</h3>
        </div>
      </div>
    </Fragment>
  );
};

export default OpenOrdersHeader;