/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { fromJS } from 'immutable';
import * as api from './tradingAPI';
import { apiActions } from 'modules/api/apiDuck';

// Constants
const PLACE_ORDER = 'modules/trading/PLACE_ORDER';
const DEPOSIT = 'modules/trading/DEPOSIT';
const WITHDRAW = 'modules/trading/WITHDRAW';
const GET_COINBASE_ACCOUNTS = 'modules/trading/GET_COINBASE_ACCOUNTS';
const GET_PAYMENT_METHODS = 'modules/trading/GET_PAYMENT_METHODS';
const GET_PRODUCTS = 'modules/trading/GET_PRODUCTS';
const GET_BALANCES = 'modules/trading/GET_BALANCES';
const GET_OPEN_ORDERS = 'modules/trading/GET_OPEN_ORDERS';
const GET_FILLED_ORDERS = 'modules/trading/GET_FILLED_ORDERS';
const GET_INTEGRATION_DATA = 'modules/trading/GET_INTEGRATION_DATA';
const SET_INTEGRATION_DATA = 'modules/trading/SAVE_INTEGRATION_DATA';
const REMOVE_INTEGRATION_DATA = 'modules/trading/REMOVE_INTEGRATION_DATA';
const CANCEL_ORDER_BY_ID = 'modules/trading/CANCEL_ORDER_BY_ID';
const CANCEL_ALL_ORDERS = 'modules/trading/CANCEL_ALL_ORDERS';
const CLEAR_TRADING_DATA = 'modules/trading/CLEAR_TRADING_DATA';
const STATE_KEY_LAST_ORDER = 'lastOrder';
const STATE_KEY_LAST_DEPOSIT = 'lastDeposit';
const STATE_KEY_LAST_WITHDRAW = 'lastWithdraw';
const STATE_KEY_COINBASE_ACCOUNTS = 'coinbaseAccounts';
const STATE_KEY_PAYMENT_METHODS = 'paymentMethods';
const STATE_KEY_PRODUCTS = 'products';
const STATE_KEY_BALANCES = 'balances';
const STATE_KEY_OPEN_ORDERS = 'openOrders';
const STATE_KEY_FILLED_ORDERS = 'filledOrders';
const STATE_KEY_INTEGRATION_DATA = 'integrationData';
const STATE_KEY_CANCELLED_ORDER = 'cancelledOrder';
const STATE_KEY_CANCELLED_ORDERS = 'cancelledOrders'

// Reducer
export const reducer = (initialState = {}, action) => {
  const state = fromJS(initialState);

  switch((action.type)) {
    case PLACE_ORDER:
      return state.set(STATE_KEY_LAST_ORDER, action.payload).toJS();
    case DEPOSIT:
      return state.set(STATE_KEY_LAST_DEPOSIT, action.payload).toJS();
    case WITHDRAW:
      return state.set(STATE_KEY_LAST_WITHDRAW, action.payload).toJS();
    case GET_COINBASE_ACCOUNTS:
      return state.set(STATE_KEY_COINBASE_ACCOUNTS, action.payload).toJS();
    case GET_PAYMENT_METHODS:
      return state.set(STATE_KEY_PAYMENT_METHODS, action.payload).toJS();
    case GET_PRODUCTS:
      return state.set(STATE_KEY_PRODUCTS, action.payload).toJS();
    case GET_BALANCES:
      return state.set(STATE_KEY_BALANCES, action.payload).toJS();
    case GET_OPEN_ORDERS:
      return state.set(STATE_KEY_OPEN_ORDERS, action.payload).toJS();
    case GET_FILLED_ORDERS:
      return state.set(STATE_KEY_FILLED_ORDERS, action.payload).toJS();
    case GET_INTEGRATION_DATA:
      return state.set(STATE_KEY_INTEGRATION_DATA, action.payload).toJS();
    case SET_INTEGRATION_DATA:
      return state.set(STATE_KEY_INTEGRATION_DATA, action.payload).toJS();
    case REMOVE_INTEGRATION_DATA:
      return state.set(STATE_KEY_INTEGRATION_DATA, null).toJS();
    case CANCEL_ORDER_BY_ID:
      return state.set(STATE_KEY_CANCELLED_ORDER, action.payload).toJS();
    case CANCEL_ALL_ORDERS:
      return state.set(STATE_KEY_CANCELLED_ORDERS, action.payload).toJS();
    case CLEAR_TRADING_DATA:
      return state.merge({
        STATE_KEY_LAST_ORDER: action.payload,
        STATE_KEY_LAST_DEPOSIT: action.payload,
        STATE_KEY_LAST_WITHDRAW: action.payload,
        STATE_KEY_COINBASE_ACCOUNTS: apiActions.payload,
        STATE_KEY_PAYMENT_METHODS: apiActions.payload,
        STATE_KEY_BALANCES: action.payload,
        STATE_KEY_OPEN_ORDERS: action.payload,
        STATE_KEY_FILLED_ORDERS: action.payload,
        STATE_KEY_INTEGRATION_DATA: action.payload,
        STATE_KEY_CANCELLED_ORDER: action.payload,
        STATE_KEY_CANCELLED_ORDERS: action.payload,
      }).toJS();

    default:
      return initialState;
  };
};

// Actions
export const tradingActions = {
  placeOrder: (payload, callback) => api.placeOrder(payload, callback, PLACE_ORDER),
  deposit: payload => api.deposit(payload, DEPOSIT),
  withdraw: payload => api.withdraw(payload, WITHDRAW),
  getPaymentMethods: () => api.getPaymentMethods(GET_PAYMENT_METHODS),
  getCoinbaseAccounts: () => api.getCoinbaseAccounts(GET_COINBASE_ACCOUNTS),
  getProducts: () => api.getProducts(GET_PRODUCTS),
  getBalances: () => api.getBalances(GET_BALANCES),
  getOpenOrders: product_id => api.getOpenOrders(product_id, GET_OPEN_ORDERS),
  getFilledOrders: product_id => api.getFilledOrders(product_id, GET_FILLED_ORDERS),
  getIntegrationData: () => api.getIntegrationData(GET_INTEGRATION_DATA),
  setIntegrationData: payload => api.setIntegrationData(payload, SET_INTEGRATION_DATA),
  removeIntegrationData: () => api.removeIntegrationData(REMOVE_INTEGRATION_DATA),
  cancelOrderById: (id, product_id) => api.cancelOrderById(id, product_id, CANCEL_ORDER_BY_ID),
  cancelAllOrders: product_id => api.cancelAllOrders(product_id, CANCEL_ALL_ORDERS),
  clearTradingData: () => ({ payload: null, type: CLEAR_TRADING_DATA }),
};

// Selectors
export const tradingSelectors = {
  lastOrder: state => state.modules.trading[STATE_KEY_LAST_ORDER],
  lastDeposit: state => state.modules.trading[STATE_KEY_LAST_DEPOSIT],
  lastWithdraw: state => state.modules.trading[STATE_KEY_LAST_WITHDRAW],
  coinbaseAccounts: state => state.modules.trading[STATE_KEY_COINBASE_ACCOUNTS],
  paymentMethods: state => state.modules.trading[STATE_KEY_PAYMENT_METHODS],
  products: state => state.modules.trading[STATE_KEY_PRODUCTS],
  balances: state => state.modules.trading[STATE_KEY_BALANCES],
  openOrders: state => state.modules.trading[STATE_KEY_OPEN_ORDERS],
  filledOrders: state => state.modules.trading[STATE_KEY_FILLED_ORDERS],
  integrationData: state => state.modules.trading[STATE_KEY_INTEGRATION_DATA],
};

export default reducer;